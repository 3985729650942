import moment from "moment";
import React, { useState } from "react";
import DatePicker from "../../../../components/shared/DatePicker";
import SelectField from "../../../../components/shared/SelectField";
import Locale from "../../../../translations";

export default function Filter({ filter, setfilter, sumbit, setPayments }) {
  const { translate } = Locale;

  const [type, setType] = useState();
  const searchType = [
    { value: 1, label: translate.returnDate },
    { value: 2, label: translate.DepartureDate },
  ];


  return (
    <>
      <div className=" bg-white w-100 px-3 py-2">
        <div className="col-12 col-md-3 mt-2 mt-md-0 p-0 mx-md-1  ">
          <SelectField
            hasLabel={false}
            onFocus={false}
            placeholder={translate.chooseSearchType}
            options={searchType}
            onChange={(e) => {
              setType(e.value);
              setfilter({
                return_from_date: "",
                return_to_date: "",
                departure_from_date: "",
                departure_to_date: "",
              });
              setPayments([])
            }}
          />
        </div>
      </div>

      <div className="row py-2 px-3 bg-white no-gutters list_header bg-white order-filter ">
        {type === 1 ? (
          <>
            <div className="col-12 col-md-3 mt-2 mt-md-0 mx-md-1 ">
              <DatePicker
                hasLabel={false}
                placeholder={`${translate.returnDate} ( ${translate.from} )`}
                date={filter?.return_from_date}
                name="date"
                onChangeDate={(e) => {
                  setfilter({
                    ...filter,
                    return_from_date: e,
                  });
                }}
              />
            </div>
            <div className="col-12 col-md-3 mt-2 mt-md-0 ">
              <DatePicker
                hasLabel={false}
                disabled={!filter?.return_from_date}
                placeholder={`${translate.returnDate} ( ${translate.to} )`}
                date={filter?.return_to_date}
                minDate={moment(filter?.return_from_date).subtract(1, "days")}
                name="date"
                onChangeDate={(e) => {
                  setfilter({
                    ...filter,
                    return_to_date: e,
                  });
                }}
              />
            </div>
          </>
        ) : type === 2 ? (
          <>
            <div className="col-12 col-md-3 mt-2 mt-md-0 mx-md-1 ">
              <DatePicker
                hasLabel={false}
                placeholder={`${translate.DepartureDate} ( ${translate.from} )`}
                date={filter?.departure_from_date}
                name="date"
                onChangeDate={(e) => {
                  setfilter({
                    ...filter,
                    departure_from_date: e,
                  });
                }}
              />
            </div>

            <div className="col-12 col-md-3 mt-2 mt-md-0 ">
              <DatePicker
                hasLabel={false}
                disabled={!filter?.departure_from_date}
                placeholder={`${translate.DepartureDate} ( ${translate.to} )`}
                date={filter?.departure_to_date}
                minDate={moment(filter?.departure_from_date).subtract(
                  1,
                  "days"
                )}
                name="date"
                onChangeDate={(e) => {
                  setfilter({
                    ...filter,
                    departure_to_date: e,
                  });
                }}
              />
            </div>
          </>
        ) : null}

        {type ? (
          <div className="p-2 cursor">
            <button
              /*    disabled={filter?.return_to_date === false || filter?.departure_to_date === false} */
              className="btn btn-primary font-weight-bold mx-2  px-5 cursor "
              onClick={sumbit}
            >
              {translate.search}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}
