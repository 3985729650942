import { locale } from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import { useCompanyState } from "../../../context/global";
import validate from "../../../helpers/validate";
import { areas, cities } from "../../../services/yarvel";
import Locale from "../../../translations";

export default function AddSpecialSettings({
  setting,
  setSetting,
  data,
  index,
  deleteFn,
}) {
  const { translate } = Locale;
  const { currentLanguage } = useCompanyState();
  console.log("currentLanguage", currentLanguage);

  /** */
  /** */

  const handlyChenageinArray = (name, value) => {
    let serv = setting.capacities;
    serv[index] = { ...serv[index], [name]: value };
    setSetting({ ...setting, capacities: serv });

    console.log("setting", setting);
  };
  const [errors, setErrors] = useState({});

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "city_id",
          value: data.city_id,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "area_ids",
          value: data.area_ids,
        },
        {
          required: true,
        }
      ),

      ...validate(
        {
          name: "capacity",
          value: data.capacity,
        },
        {
          required: true,
        }
      ),
    });
  };

  //cities
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);

  useEffect(() => {
    async function fetchLookups() {
      const res = await cities();
      const format = res?.data?.data.map((t) => {
        return {
          value: t.id,
          label: t.name, //s[currentLanguage]
        };
      });
      setCity(format);
    }
    fetchLookups();
    console.log("area1", area);
  }, []);

  let id = setting?.capacities[index]?.city_id?.value
    ? setting?.capacities[index]?.city_id?.value
    : setting?.capacities[index]?.city_id;

  console.log("idd", id);

  useEffect(() => {
    async function fetchLookups() {
      //let id=setting?.capacities[index]?.city_id.value
      const res = await areas(id);
      const formatted = res?.data?.data.map((area) => {
        return {
          value: area.id,
          label: area.name, //s[currentLanguage]
        };
      });
      setArea(formatted);
      console.log("formatted", formatted);
    }
    fetchLookups();
  }, [id]);

  return (
    <>
      <div
        className=" add-settings container bg-white d-flex justify-content-center"
        style={{ marginTop: "20px" }}
      >
        <div className=" m-auto" style={{ width: "80%" }}>
          <div className="row py-3 " style={{ background: "#EDF2F7" }}>
            <div className="col-lg-3  col-md-12 col-xs-12 my-3 mr-2 special-settings1">
              <SelectField
                onFocus={true}
                hasLabel={true}
                label={translate.governorate}
                name={"city_id"}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999000 }),
                }}
                options={city}
                onChange={(e) => {
                  handlyChenageinArray("city_id", e);
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "city_id", value: e },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
                value={
                  data != null &&
                  data.hasOwnProperty("city_id") &&
                  data.hasOwnProperty("city_names")
                    ? {
                        label: data.city_id.label
                          ? data.city_id.label
                          : data.city_names[currentLanguage],
                        value: data.city_id,
                      }
                    : data.city_id
                } //data.city_id

                //value={data!=null&&data.hasOwnProperty('city_id')&&data.hasOwnProperty('city_names') ? {value :data.city_id, label:data.city_names[currentLanguage]}:data.city_id} //data.city_id
              />
            </div>

            <div className="col-lg-5  col-md-12 col-xs-12 my-3 mx-2 special-settings2">
              <Select
                isMulti
                name="area_ids"
                options={area}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={translate.selectArea}
                onClick={() => alert("Saa")}
                onChange={(e) => {
                  handlyChenageinArray("area_ids", e);
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "area_ids", value: e },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
                value={
                  data != null &&
                  data.hasOwnProperty("area_ids") &&
                  data.area_ids.filter((res) => res.hasOwnProperty("names"))
                    .length > 0
                    ? data.area_ids.map((res) => {
                        return {
                          label: res.names[currentLanguage],
                          value: res.value,
                        };
                      })
                    : data.area_ids
                }
              />
            </div>

            <div className="col-lg-3 col-md-12 col-xs-12 mx-2">
              <TextField
                hasLabel={true}
                name="capacity"
                label={translate.capacityPerHour}
                onChange={(e) => {
                  handlyChenageinArray("capacity", e.target.value);
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "capacity", value: e.target.value },
                      {
                        required: true,
                      }
                    ),
                  });
                }}
                value={data?.capacity}
              ></TextField>
            </div>
          </div>
        </div>

        <button
          className="btn text-danger btn-lg my-3 mx-4"
          onClick={() => deleteFn(index)}
        >
          <i className="fas fa-trash-alt"></i>
        </button>
      </div>
    </>
  );
}
