import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useCompanyState } from "../../../../context/global";
import ShowForPermission from "../../../../helpers/showForPermission";

import {
  createOperationOrder,
  listEmployees,
  listTravellers,
} from "../../../../services/yarvel";
import Locale from "../../../../translations";

function Traveler({
  traveler,
  toggleModalShow,

  toggleEmployee,
  setToggleEmployee,

  modalShow1,
  setModalShow1
}) {
  let history = useHistory();

  const { translate } = Locale;
  console.log("asd",modalShow1)
  return (
    <>
      <tr>
        <td> {traveler?.reservation_no} </td>
        <td>{traveler?.test_type}</td>
        <td>
          {traveler?.traveller_name?.ar} <br />
          {traveler?.traveller_name?.en}
        </td>
        <td>{traveler.reservation_type} </td>
        {/* <td>2</td> */}
        <td>
          <div className="d-flex flex-column">
            <span> {traveler.check_date?.slice(0, 10)} </span>
            <span>{traveler.check_date?.slice(11, 16)} </span>
          </div>
        </td>
        <td>
          <div className="d-flex flex-column">
            <span>{traveler.request_date?.slice(0, 10)} </span>
            <span>{traveler.request_date?.slice(11, 16)}</span>
          </div>
        </td>
        <td className="d-flex">
          <ShowForPermission permission={"company:view-traveller"}>
            <button
              className="text-primary btn-link mr-3 font-weight-bold"
              onClick={() => {
                //history.push(`/CustomersList/view-result/${traveler.id}`, { modalShow: false});
                history.push({pathname:`/CustomersList/view-result/${traveler.id}`, state: { modalShow1: false,setModalShow1:false } });

              }}
              style={{width:"70px"}}
            >
              <i class="fas fa-eye" style={{ fontSize: "16px" }}></i>{" "}
              {translate.view}
            </button>
          </ShowForPermission>




          <ShowForPermission permission={"company:submit-operation-order"}>
            <button
              className="text-primary btn-link font-weight-bold submit-operation-order "
              onClick={() => {
                //setToggleEmployee(true)
                toggleModalShow(traveler.id);
                
              }}
              style={{width:"125px"}}
            >
              <i class="fas fa-print" style={{ fontSize: "16px" }}></i>{" "}
              {translate.SupplyingRunningOrder}
            </button>
          </ShowForPermission>


          
        </td>

        {/* Modal examainaton type */}
        <div></div>
      </tr>
    </>
  );
}

export default Traveler;
