import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { useHistory, useParams } from "react-router";
import { listTravellersById } from "../../../../services/yarvel";
import scan from "../../../../assets/images/barcod.png";
import { useCompanyState } from "../../../../context/global";
export default function ViewOPeration() {
  const { translate } = Locale;
  let history = useHistory();
  const { currentLanguage } = useCompanyState();

  //listTravellersById
  const [employee, setEmployee] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      const Employees = await listTravellersById(id);

      setEmployee(Employees.data);
      console.log("listTravellersById is ", Employees.data);
      setTimeout(() => {
        if (Employees.status === 200) {
          window.print();
        }
      }, 1000);
    }
    fetchData();
  }, []);

  return (
    <div className="container p-0 order-list-view">
      <div className="my-4 d-flex justify-content-between">
        <div>
          <h3 className="font-weight-bold"> {translate.PCR} </h3>
          {/**امر تشغيل فحص PCR   */}
          <h3>
            {" "}
            {translate.OperationOfficer} :{" "}
            {employee?.data?.operationOrder_employee}{" "}
          </h3>
        </div>
        <img src={scan} width="150" height="100" />
      </div>

      <div className="bg-white px-5 py-4 instructions-all">
        {/* *************************************************************************/}

        <div className="instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark ">
            {" "}
            {translate.orderData}
          </h5>
          <div className=" p-2 row">
            <p className="col-2 h5   font-weight-bold">{translate.check}</p>
            <p className="col-4 h5 ">{employee?.data?.test_type}</p>
            <p className="col-2 h5   font-weight-bold">
              {translate.orderNumber}
            </p>
            <p className="col-4 h5 ">{employee?.data?.reservation_no}</p>
            <p className="col-2 h5   font-weight-bold">{translate.typeCheck}</p>
            <p className="col-4 h5 ">{employee?.data?.reservation_type}</p>

            <p className="col-2  h5  font-weight-bold">
              {translate.timeRequest}
            </p>
            <p className="col-4 h5 ">
              {employee?.data?.request_date?.slice(0, 10)} |{" "}
              {employee?.data?.request_date?.slice(11, 16)}
            </p>

            <p className="col-2  h5  font-weight-bold">
              {translate.serviceDegree}
            </p>
            <p className="col-4 h5 ">{employee?.data?.grade}</p>

            <p className="col-2 h5  font-weight-bold">
              {translate.resultWithin}
            </p>
            <p className="col-4 h5 ">
              {employee?.data?.time} {translate.hour}
            </p>
          </div>
        </div>
        {/* <div className="instructions py-3 p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 ">{employee?.data?.reservation_no}</p>
          <p className="col-2 h5  font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-4 h5 ">{employee?.data?.check_date_period} {translate.hour}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 ">{(employee?.data?.request_date)?.slice(0,10)} | {(employee?.data?.request_date)?.slice(11,16)}</p>
        </div> */}

        {/* *************************************************************************/}
        {/* <div className="instructions border my-4">

        <h5 className="font-weight-bold text_blue-dark p-2">
          {" "}
          {translate.TripData}
        </h5>

        <div className=" p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 ">{employee?.data?.trip?.destination[currentLanguage]}</p>
          <p className="col-2 h5  font-weight-bold">
            {translate.arrivalDate}
          </p>
          <p className="col-4 h5 ">
          {employee?.data?.trip?.arrival_date?.slice(0,10)} | {employee?.data?.trip?.arrival_date?.slice(11,16)}

</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 "> {employee?.data?.trip?.transporter[currentLanguage]}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.departureDate} 
          </p>
          <p className="col-4 h5 ">
          {employee?.data?.trip?.departure_date?.slice(0,10)} | {employee?.data?.trip?.departure_date?.slice(11,16)}

          </p>
        </div>
</div>*** */}

        {/* *************************************************************************/}

        {(currentLanguage == "ar" &&
          employee?.data?.test_type !== "تحليل مخدرات") ||
        (currentLanguage == "en" &&
          employee?.data?.test_type !== "Drugs Examination") ? (
          <>
            <div className="instructions border">
              <h5 className="font-weight-bold px-2 py-1 text_blue-dark mt-2">
                {" "}
                {translate.TripData}
              </h5>

              <div className="  p-2 row">
                <p className="col-2 h5   font-weight-bold">
                  {translate.destnation}
                </p>
                <p className="col-4 h5 ">
                  {employee?.data?.trip?.destination?.names[currentLanguage]}
                </p>
                <p className="col-2  h5  font-weight-bold">
                  {translate.departureDate}
                </p>
                <p className="col-4 h5 ">
                  {employee?.data?.trip?.departure_date?.slice(0, 10)} |{" "}
                  {employee?.data?.trip?.departure_date?.slice(11, 16)}
                </p>{" "}
                <p className="col-2  h5  font-weight-bold">
                  {translate.Transporter}
                </p>
                <p className="col-4 h5 ">
                  {" "}
                  {
                    employee?.data?.trip?.transporter.names[currentLanguage]
                  }{" "}
                  {/**[currentLanguage] */}
                </p>
                <p className="col-2 h5  font-weight-bold">
                  {translate.arrivalDate}
                </p>
                <p className="col-4 h5 ">
                  {" "}
                  {employee?.data?.trip?.arrival_date?.slice(0, 10)} |{" "}
                  {employee?.data?.trip?.arrival_date?.slice(11, 16)}
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/* *************************************************************************/}
        {/* *************************************************************************/}
        <div className="instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark ">
            {" "}
            {translate.CustomerData}
          </h5>

          <div className=" p-2 row">
            <p className="col-2 h5   font-weight-bold">
              {translate.nameArabic}
            </p>
            <p className="col-4 h5 ">{employee?.data?.traveller_name_ar}</p>
            <p className="col-2 h5  font-weight-bold">{translate.nationalId}</p>
            <p className="col-4 h5 "> {employee?.data?.national_id} </p>
            <p className="col-2  h5  font-weight-bold">
              {translate.EnglishName}
            </p>
            <p className="col-4 h5 ">{employee?.data?.traveller_name_en}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.passportNumber}
            </p>
            <p className="col-4 h5 ">{employee?.data?.passport_number}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.Nationality}
            </p>
            <p className="col-4 h5 ">{employee?.data?.nationality}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.phoneNumber}
            </p>
            <p className="col-4 h5 ">{employee?.data?.phone}</p>
          </div>
        </div>

        {/* *************************************************************************/}
        {/* <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5> */}

        {/* <div className="instructions py-1 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.examinationStatus}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.test_type}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.grade}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.reservation_type}</p>
        </div> */}

        {/* *************************************************************************/}
        {/* <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.Cost}
        </h5> */}

        {/* <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.TotalCost}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.paid} {translate.pound}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.Paid}
          </p>
          <p className="col-4 h5 py-1">{employee?.data?.paid} {translate.pound}</p>
          
        </div>
       */}
        <div className="instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark ">
            {" "}
            {translate.ExaminationDateAddress}
          </h5>

          <div className="p-2 rounded-lg row">
            <p className="col-2 h5   font-weight-bold">
              {currentLanguage == "en"
                ? `${translate.check} ${translate.date}`
                : `${translate.date} ${translate.check}`}

              {/* {translate.check} {translate.date}  */}
            </p>
            <p className="col-4 h5 ">
              {employee?.data?.check_date?.slice(0, 10)}
            </p>
            <p className="col-2 h5   font-weight-bold">
              {translate.ResultTime}
            </p>
            <p className="col-4 h5 ">
              {employee?.data?.check_date?.slice(11, 16)}{" "}
              {/*- {employee?.data?.check_date_end?.slice(11, 16)}*/}
            </p>

            <p className="col-2 h5   font-weight-bold">
              {translate.governorate}
            </p>
            <p className="col-4 h5 ">{employee?.data?.city_id}</p>
            <p className="col-2 h5   font-weight-bold">{translate.city}</p>
            <p className="col-4 h5 ">{employee?.data?.area_id}</p>

            <p className="col-2 h5   font-weight-bold">{translate.StName}</p>
            <p className="col-4 h5 ">{employee?.data?.street}</p>
            <p className="col-2 h5   font-weight-bold">
              {translate.BuildingNumber}
            </p>
            <p className="col-4 h5 ">{employee?.data?.building}</p>

            <p className="col-2 h5   font-weight-bold">{translate.Floor}</p>
            <p className="col-4 h5 ">{employee?.data?.floor}</p>
            <p className="col-2 h5  font-weight-bold">
              {translate.ApartmentNumber}{" "}
            </p>
            <p className="col-4 h5 ">{employee?.data?.flat}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.phoneNumber}
            </p>
            <p className="col-4 h5 ">{employee?.data?.phone}</p>
            <p className="col-2  h5  font-weight-bold">{translate.email}</p>
            <p className="col-4 h5 ">{employee?.data?.email}</p>

            {/* <p className="col-2  h5 py-2 font-weight-bold">{translate.Cost}</p>
          <p className="col-4 h5 py-3">
            {employee?.data?.amount}
            {translate.pound}
          </p> */}
            {/* <p className="col-2  h5 py-3 font-weight-bold">
            {translate.paymentMethods}
          </p>
          <p className="col-4 h5 py-3">{employee?.data?.comission_type}</p> */}
          </div>
        </div>

        <button
          className=" btn btn-secondary px-5 my-4 font-weight-bold"
          onClick={() => {
            history.push("/CustomersList/orders");
          }}
        >
          {" "}
          {translate.back}
        </button>
      </div>
    </div>
  );
}
