import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import { useHistory, useLocation } from "react-router-dom";

import FilterGroup from "./filterGroup";
import { NavLink } from "react-router-dom";
import Collaps from "./Collapse";
import UserNumber from "./userNumber";
import {
  ListGroups,
  ListGroupsFilter,
  ListUser,
} from "../../../services/WorkTeamTenant";
import ShowForPermission from "../../../helpers/showForPermission";

export default function Groups({props}) {
  //const {groupsDB} =props
  const { translate } = Locale;
  let history = useHistory();
  let location = useLocation();
  const users=location.state?.data

  
  console.log("users****" ,location);
  console.log("users110",location.state);

  const [Groups, setGroups] = useState([]);
  //const [users, setUsers] = useState([]);
  const [filter, setfilter] = useState({
    search: "",
  });

  useEffect(() => {
    async function fetchData() {
      const User = await ListGroupsFilter(filter);
      setGroups(User?.data);
    }
    fetchData();

    async function fetchUserData() {
      const User = await ListUser();
      //setUsers(User?.data.data);  //// becouse not call api users in groups
    }
    //fetchUserData();
  }, [filter]);

  const AllGroups =
    Groups && Groups?.data?.length > 0
      ? Groups?.data?.map((Group, index) => (
          <Collaps Group={Group} index={index} />
        ))
      : "";
console.log("users11",users);
  return (
    <section className="groups">
      <UserNumber users={users} />
      <div className="container p-0">
        <div className="d-flex justify-content-between mt-5 mb-2 head">
          <h3 className="text-muted font-weight-bold">
            {translate.teamManagement}
          </h3>

          <ShowForPermission permission={"company:create-groups"}>
            <NavLink
              exact={true}
              to={`/team-management/groups/add`}
              activeClassName="is-active"
            >
              <button
                type="button"
                className="btn btn-success px-4 py-2 font-weight-bold "
                onClick={() => {
                  history.push("/Company/Add-Traveler");
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>
                {translate.addGroup}
              </button>
            </NavLink>
          </ShowForPermission>
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <FilterGroup filter={filter} setfilter={setfilter} />
          <div className="d-flex navLink">
            <ShowForPermission permission={"company:list-users"}>
              <NavLink
                exact={true}
                to={`/team-management/users`}
                activeClassName="is-active"
                className="btn-nav  mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.users}
              </NavLink>
            </ShowForPermission>

            <ShowForPermission permission={"company:list-groups"}>
              <NavLink
                exact={true}
                to={`/team-management/groups`}
                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.groups}
              </NavLink>
            </ShowForPermission>
          </div>
        </header>

        <div className="Collapse">{AllGroups}</div>
      </div>
    </section>
  );
}
