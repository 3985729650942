import React, { useEffect, useState } from "react";
//import { Modal, Table } from "reactstrap";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import Locale from "../../../../translations";
import PaymentFilter from "./paymentFilter";
import PaymentList from "./paymentList";
import PaymentCost from "./paymentCost";
import { useHistory, useParams } from "react-router";
import { addPayment, paymentList, paymentNumber } from "../../../../services/admin";
import TextField from "../../../../components/shared/textField";
import SelectField from "../../../../components/shared/SelectField";
import { store } from "react-notifications-component";
import validate,{ isFormValid } from "../../../../helpers/validate";
import moment from "moment";
import DatePicker from "../../../../components/shared/DatePicker";
import Pagination from "../../../../components/shared/Pagination";
import ShowForPermission from "../../../../helpers/showForPermission";
//import Button from "@restart/ui/esm/Button";

export default function Payment() {
  const { translate } = Locale;
  let history = useHistory();
  let { id } = useParams();
  /*****model******* */
  const [modalShow, setModalShow] = useState(false);

  const toggleModalShow = (id) => {
   // setSeletedId(id)
    setModalShow(!modalShow);
  };

  const [paymentModel, setPaymentModel] = useState({});
  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setPaymentModel({ ...paymentModel, [name]: value });
    }else{
		const value = e.value;
		const name = e.name;
		setPaymentModel({ ...paymentModel, [name]: value });
	}
  };
  const format =[
    {
    value: 1,
    title: translate.cash, //كاش
    label: 'cash'
    },{
    value: 2,
    title: translate.bank_check, // شيك
    label: 'bank_check'
  },
  {
    value: 3,
    title: translate.bank_transaction, // تحويل بنكي
    label: 'bank_transaction' 
  }
];

const format_translate =[
  {
  value: 1,
  label: `${translate.cash}`, 
  title: 'cash' 
  },{
  value: 2,
  //label: translate.bank_check, // شيك
  label: `${translate.bank_check}`,
  title: 'bank_check' 
},
{
  value: 3,
  //label: translate.bank_transaction, // تحويل بنكي
  label: `${translate.bank_transaction}`,
  title: 'bank_transaction' 
}
];

//error
const [errors, setErrors] = useState({});

const checkFormErrors = () => {
  setErrors({
    ...errors,
    ...validate(
      {
        name: "amount",
        value: paymentModel.amount,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "payment_type",
        value: paymentModel.payment_type,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "notes",
        value: paymentModel.notes,
      },
      {
        required: true,
      }
    ),

/******************************************************************** */
    ...paymentModel.payment_type?.title !== "cash"?{

    ...validate(
      {
        name: "bank_name",
        value: paymentModel.bank_name,
      },
      {
        required: true,
      }
    ),
    

    ...validate(
      {
        name: "account_owner_name",
        value: paymentModel.account_owner_name,
      },
      {
        required: true,
      }
    ),
    ...validate(
      {
        name: "check_or_account_number",
        value: paymentModel.check_or_account_number,
      },
      {
          required: true,
      }
    ),



    // ...validate(
    //   {
    //     name: "check_date",
    //     value: paymentModel.check_date,
    //   },
    //   {
    //       required: true,
    //   }
    // ),
    
    ...validate(
      {
        name: "account_owner_name",
        value: paymentModel.account_owner_name,
      },
      {
          required: true,
      }
    ),
  }:"",



  /**** */
  ...paymentModel.payment_type?.title === "bank_check"?{
    ...validate(
      {
        name: "check_date",
        value: paymentModel.check_date,
      },
      {
          required: true,
      }
    ),
   
  }:"",
  ...paymentModel.payment_type?.title === "bank_transaction"?{
    ...validate(
      {
        name: "transaction_number",
        value: paymentModel.transaction_number,
      },
      {
          required: true,
      }
    ),
   
  }:""
/************************************************************** */




    // ...validate(
    //   {
    //     name: "transaction_number",
    //     value: paymentModel.transaction_number,
    //   },
    //   {
    //       required: true,
    //   }
    // )

  });
};
const [isErrorLoaded, setIsErrorLoaded] = useState(false);
const handleForm = async () => {
  checkFormErrors();
  if (!isErrorLoaded) {
    setIsErrorLoaded(true);
  } else {
    setIsErrorLoaded(false);
  }
};

useEffect(() => {
  const add = async () => {
    //debugger
   if (isFormValid(errors)) {
    //debugger
      const res = await addPayment({
        ...paymentModel,
        company_id:id,
        payment_type:paymentModel.payment_type?.title,
        ...paymentModel.payment_type?.title !="cash"?{
        payment_details:
        {
          "bank_name":paymentModel.bank_name,
          "account_owner_name":paymentModel.account_owner_name,
          "check_or_account_number":paymentModel.check_or_account_number,
          "transaction_number":paymentModel.transaction_number,
          "check_date": paymentModel.check_date
          ? moment(paymentModel.check_date).format("YYYY-MM-DD")
          : "2000-01-01",
        }
      }:""
      });

      if (res.status == 201) {
        store.addNotification({
          title: "info!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        //history.push("/admin/ComanyMedical")
        setModalShow(!modalShow);
        window.location.reload();
        
      }
    }
  };

  add();
}, [ isErrorLoaded ]);



  /** */
  const [filter, setfilter] = useState({
    search: "",
    payment_type:"",
    page:1
    
  });
  const [searching, setSearching] = useState(false);
  const [pageing, setPageing] = useState(false);
  const submit = () => {
    setSearching(!searching);
    //setfilter({search: ""})
  };
  const emptyInputs = () => {
    setSearching(!searching);
    setfilter({search: "",payment_type:"",})
  };
  const goTo = async (page) => {
    setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    setPageing(!pageing)
    //alert(pageing)
  };
  const [paymentNumbers, setPaymentNumbers] = useState()
  const [paymentLists, setPaymentLists] = useState()
  const [meta, setMeta] = useState([]);
      useEffect(() => {
        async function fetchDataNaumber() {
          const res = await paymentNumber(id);
          setPaymentNumbers(res?.data?.data);
        }
        async function fetchDataList() {
          const res = await paymentList(id,filter.page,{...filter , payment_type : filter.payment_type?.title});
          setPaymentLists(res?.data?.data );
          setMeta(res?.data?.meta)
        }
        fetchDataNaumber();
        fetchDataList();
        
      }, [searching,pageing]);

      useEffect(() => {
        async function fetchDataNaumber() {
          const res = await paymentNumber(id);
          setPaymentNumbers(res?.data?.data);
        }
        async function fetchDataList() {
          const res = await paymentList(id,filter.page,{...filter , payment_type : filter.payment_type?.title,page:1});
          setPaymentLists(res?.data?.data );
          setMeta(res?.data?.meta)
        }
        fetchDataNaumber();
        fetchDataList();
      }, [searching]);
      

      const dataList =
      paymentLists && paymentLists.length > 0
        ? paymentLists.map((list, index) => <PaymentList paymentLists={list} index={index} />)
        : "";
    
  /** */

  return (
    <div className="container p-0">

      <PaymentCost paymentNumbers={paymentNumbers} />
      <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.Payments}</h3>
         
          <ShowForPermission permission={"create-settlement"}> {/**/}
            <button
              type="button"
              className="btn btn-success px-5 py-2  font-weight-bold btn-model-payment" 
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              {translate.Settlement}
            </button>
          </ShowForPermission>
        </div>
      <PaymentFilter submit={submit} emptyInputs={emptyInputs} filter={filter} setfilter={setfilter} />
      <div className="  p-0">
        <Table>
          <thead>
            <tr className="text-grey">
            <th>{translate.transactionCode}</th>
              <th> {translate.amount}</th>
              <th>{translate.date}</th>
              <th>{translate.transactionType}</th>
              <th>{translate.employeeName}</th>
            </tr>
          </thead>
          <tbody className="table-stripe">{dataList}</tbody>
          {dataList?.length > 0 ? (
												""
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
        </Table>
        <Pagination info={meta} goTo={goTo} items={10} />  
        <div></div>
      </div>

      {/**********************Model**************************** */}
      <Modal isOpen={modalShow } toggle={()=>{toggleModalShow(null)}} >
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">{translate.Settlement}</p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={()=>{
              toggleModalShow(null)
            }}
          ></i>
        </div>
        <ModalBody className="lan  mx-5 my-4 p-0 " >
          <div  className="mt-4" >
            
{/****************** */}
          <div className="container" >
          <div className="row ">
            <div className="col">
              <TextField
                type="text"
                hasLabel={true}
                label={translate.amount}
                placeholder={translate.amount}
                name="amount"
                onChange={(e) => {
                  setPaymentModel({
                    ...paymentModel,
                    amount: e.target.value,
                  });
                }}
                color={errors?.amount?.required ? "danger" : ""}
                errors={errors?.amount}
              />
            </div>

            <div className="col mx-3">
              <SelectField
                type="text"
                name="payment_type"
                label={translate.PaymentType}
                placeholder={translate.PaymentType}
                errors={errors.payment_type}
                color={errors.payment_type?.required ? "danger" : ""}
                options={format_translate}
                onChange={(e) => {
                handleChange({ name: "payment_type", value: e });
                }}
              />
            </div>
            <div className="col">
		        	<TextField
                type="text"
                hasLabel={true}
                label={translate.notes}
                placeholder={translate.writeNotes}
                name="notes"
                onChange={(e) => {
                  setPaymentModel({
                    ...paymentModel,
                    notes: e.target.value,
                  });
                }}
                color={errors?.notes?.required ? "danger" : ""}
                errors={errors?.notes}
              />
            </div>
          </div>

            { paymentModel?.payment_type?.title== "bank_check"?(
            <div className=" p-3 my-4" style={{background:"#C5E1D4"}}>
             <div className="row">
             <div className="col-6 my-3" style={{marginTop:"50px !important"}}>
		        	<TextField
                type="text"
                hasLabel={true}
                label={translate.bankName}
                placeholder={translate.bankName}
                name="bank_name"
                onChange={(e) => {
                  setPaymentModel({
                    ...paymentModel,
                    bank_name: e.target.value,
                  });
                }}
                
                   color={errors?.bank_name?.required ? "danger" : ""}
                   errors={errors?.bank_name}
              />
            </div>

            <div className="col-6  my-3">
		        	<TextField
                type="text"
                hasLabel={true}
                label={translate.accountName}
                placeholder={translate.accountName}
                name="account_owner_name"
                onChange={(e) => {
                  setPaymentModel({
                    ...paymentModel,
                    account_owner_name: e.target.value,
                  });
                }}
                   color={errors?.account_owner_name?.required ? "danger" : ""}
                  errors={errors?.account_owner_name}
              />
            </div>

            <div className="col-6  my-2">
		        	<TextField
                type="text"
                hasLabel={true}
                label={translate.accountNumber}
                placeholder={translate.accountNumber}
                name="check_or_account_number"
                onChange={(e) => {
                  setPaymentModel({
                    ...paymentModel,
                    check_or_account_number: e.target.value,
                  });
                }}
                   color={errors?.check_or_account_number?.required ? "danger" : ""}
                 errors={errors?.check_or_account_number}
              />
            </div>

            <div className="col-6  my-2">
		        	
               <DatePicker
                    hasLabel={true} 
                    label={translate.DateOfTravel}
                    placeholder={translate.DateOfTravel}
                    date={paymentModel?.check_date}
                    name="check_date"
                    
                    onChangeDate={(e) =>{
                      setPaymentModel({
                        ...paymentModel,
                        check_date: e,
                      })
                    }
                  }
                  color={errors?.check_date?.required ? "danger" : ""}
                  errors={errors?.check_date}

                 />
            </div>
             </div>
            </div>
              
              ):(paymentModel?.payment_type?.title== "bank_transaction"?(
                <div className=" p-3 my-4" style={{background:"#C5E1D4"}}>
                 <div className="row">
                 <div className="col-6 my-3">
                  <TextField
                    type="text"
                    hasLabel={true}
                    label={translate.bankName}
                    placeholder={translate.bankName}
                    name="bank_name"
                    onChange={(e) => {
                      setPaymentModel({
                        ...paymentModel,
                        bank_name: e.target.value,
                      });
                    }}
                     color={errors?.bank_name?.required ? "danger" : ""}
                     errors={errors?.bank_name}
                  />
                </div>
    
                <div className="col-6  my-3">
                  <TextField
                    type="text"
                    hasLabel={true}
                    label={translate.accountName}
                    placeholder={translate.accountName}
                    name="account_owner_name"
                    onChange={(e) => {
                      setPaymentModel({
                        ...paymentModel,
                        account_owner_name: e.target.value,
                      });
                    }}
                      color={errors?.account_owner_name?.required ? "danger" : ""}
                       errors={errors?.account_owner_name}
                  />
                </div>
    
                <div className="col-6  my-3">
                  <TextField
                    type="text"
                    hasLabel={true}
                    label={translate.accountNumber}
                    placeholder={translate.accountNumber}
                    name="check_or_account_number"
                    onChange={(e) => {
                      setPaymentModel({
                        ...paymentModel,
                        check_or_account_number: e.target.value,
                        
                      });
                    }}
                       color={errors?.check_or_account_number?.required ? "danger" : ""}
                      errors={errors?.check_or_account_number}
                  />
                </div>
    
                <div className="col-6  my-3">
                  <TextField
                    type="text"
                    hasLabel={true}
                    label={translate.operationNumber}
                    placeholder={translate.operationNumber}
                    name="transaction_number"
                    onChange={(e) => {
                      setPaymentModel({
                        ...paymentModel,
                        transaction_number: e.target.value,
                      });
                    }}
                    color={errors?.transaction_number?.required ? "danger" : ""}
                    errors={errors?.transaction_number}

                    
                    
                  />
                </div>
                 </div>
                </div>):"")
              
            }
     </div>      

{/******************************* */}
          
{/******************************* */}
          </div>
        </ModalBody>
        <ModalFooter className="lan pb-4 border-top-0  mx-5 p-0 ">
          <button
            className="rounded btn-success w-100  text-bold py-2  m-auto font-weight-bolder"
            onClick={
              handleForm
             }
          >
           {translate.confirmation}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
