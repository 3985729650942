import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logoImg from "../../../assets/images/Image1.png";
import Locale from "../../../translations";
import NotificationContent from "./notificationContent";
import { allNotifications, readNotification } from "../../../services/admin";
import Pagination from "../../../components/shared/Pagination";

export default function Notification() {
  let location = useHistory();
  const { translate } = Locale;

  //************************api******************************************* */
  //allNotifications
  const [meta, setMeta] = useState([]);

  const [notifyData, setNotifyData] = useState();
  useEffect(() => {
    async function fetchData() {
      const res = await allNotifications();
      setNotifyData(res?.data?.data);
      setMeta(res?.data?.meta)
    }
    fetchData(); 
    
  }, []);

  


  const AllNotification =
    notifyData?.length > 0
      ? notifyData.map((data, index) => (
          <NotificationContent notifyData={data} index={index}   meta={meta} setMeta={setMeta} />
        ))
      : [];
  console.log("notifyData", notifyData);


  const goTo = async (page) => {
      async function fetchData() {
      const res = await allNotifications(page);
      setNotifyData(res?.data?.data);
      setMeta(res?.data?.meta)
    }
    fetchData(); 
  };

  //************************api******************************************* */
  return (
    <>
    <div className="d-flex justify-content-center " style={{ height: "100vh" }}>
      <div className="d-flex flex-column  w-50 mx-3">
        <h4
          className="w-75 mx-auto mt-3 py-3 px-2 mb-0 font-weight-bold"
          style={{ color: "#656565", background: "#E4E4E4" }}
        >
          {translate.allNotification}
        </h4>

        
        {AllNotification.length > 0 ? (
          AllNotification
        ) : (
          <div>
            <div  className="text-center my-3">
              <div className="product-build__product-no-data">
                <i className="fas fa-info-circle fa-lg"></i>{" "}
                <h4>{translate.noResult}</h4>
              </div>
              
            </div>
          </div>
        )}
<div className=" w-50 m-auto" >
<Pagination info={meta} goTo={goTo} items={10} /> 
</div>
      </div>

    </div>


    
    </>
  );
}
