import React,{useEffect, useState} from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";
import UserNumber from "./userNumber"
import Filter from "./filter";
import { Link, NavLink } from "react-router-dom";
import Users from "./users"
import ShowForPermission from "../../../helpers/showForPermission";
import { ListUser } from "../../../services/WorkTeamAdmin";

function WorkTeamAdmin() {
  const { translate } = Locale;
  let history = useHistory();
  const [users, setUsers] = useState([]);
  const [filter, setfilter] = useState({
    name:"",
    status:"",
    page:1
    
  });

  const countOfUsers = (val) => {
    setUsers(val);
  };
  /************************************ */
const [data, setData] = useState()

useEffect(() => {
  
  async function fetchUserData() {
    const User = await ListUser();
    setData(User?.data.data);
  }

  fetchUserData();  //for permissions


}, []);
//setData(users)
console.log("data",data);
  return (
    <section>
      <UserNumber users={users}/>
      <div className="container p-0">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.teamManagement}</h3>
          <ShowForPermission permission={["create-admin","list-admins"]} type={"and"}> {/**/}
          <NavLink
            exact={true}
            to={`/admin/team-management/users/add`}
            activeClassName="is-active"
          >
            <button
              type="button"
              className="btn btn-success px-4 py-2 font-weight-bold "
              onClick={() => {
                history.push("/admin/team-management/users");
              }}
            >
              <i className="fas fa-plus-circle mr-2"></i>
              {translate.addUser}
            </button>
          </NavLink>
          </ShowForPermission>
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <Filter filter={filter} setfilter={setfilter} />
          <div>
          
          <ShowForPermission permission={"list-admins"}>
            <NavLink
              exact={true}
              to={`/admin/team-management/users`}
              activeClassName="is-active"
              className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
            >
              {translate.users}
            </NavLink>
            </ShowForPermission> 
          
            <ShowForPermission permission={"list-groups"}>
            <NavLink
              exact={true}
              to={{pathname:'/admin/team-management/groups', state: {data:data}  }}
              activeClassName="is-active" 
              className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
             
            >
              {translate.groups}
            </NavLink>
            </ShowForPermission>
          </div>
        </header>
        <Users countUsers = {(val)=>countOfUsers(val)} filter={filter} setfilter={setfilter} />
      </div>
    </section>
  );
}

export default WorkTeamAdmin;
