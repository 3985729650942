import React, { useEffect, useState } from "react";
import { serviceList } from "../../../services/yarvel";
import Locale from "../../../translations";
import ServicesList from "./servicesList";

function Services() {
  const { translate } = Locale;
 /******Start Api*******/
//servicesList

const [servicesList, setservicesList] = useState([])
const [toggle, setToggle] = useState(false)
useEffect(() => {
    async function fetchData() {
        const res = await serviceList();
        setservicesList(res?.data?.data)
    }
fetchData();
},[toggle]);

const AllServices =
  
servicesList?.length > 0
    ? servicesList.map((service, index) => (
        <ServicesList
          service={service}
          index={index}
          setToggle={setToggle}
        />
      ))
    : [];
/******End Api********/
  return (
    <section className="services">
      <div className="container p-0 my-4 services-container">
        <header className="list_header border py-2 px-2">
          <h3 className="text-muted font-weight-bold "> {translate.services} </h3>
        </header>

        {AllServices.length > 0 ? (
												AllServices
											) : (

														<div className="text-center my-5 product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>

											)}
      </div>
    </section>
  );
}

export default Services;
