import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { NavLink } from "react-router-dom";
import Filter from "./filter";
import ListTravelers from "./listTravelers";
import { useParams } from "react-router-dom";

import moment from "moment";
import { listIcomingOrder } from "../../../../services/admin";
function CustomersList() {
  const { translate } = Locale;

  /**Filter  here */

  const [filter, setfilter] = useState({
    name: "",
    departure_date: "",
    check_date: "",
    page:1
  });
  const { id } = useParams();
  const [searching, setSearching] = useState(false);
  const [allData, setAllData] = useState([]);
/** */
const [pageing, setPageing] = useState(false);
  const goTo = async (page) => {
    setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    setPageing(!pageing)
  };
/** */
  const [meta, setMeta] = useState([]);
  const submit = () => {
    setSearching(!searching);
  };

  useEffect(() => {
    async function fetchListClients() {
      const res = await listIcomingOrder( id,{
        ...filter,
        departure_date: filter.departure_date
          ? moment(filter.departure_date).format("YYYY-MM-DD")
          : "",
        check_date: filter.check_date
          ? moment(filter.check_date).format("YYYY-MM-DD")
          : "",
      });
      setAllData(res?.data?.data);
      setMeta(res?.data?.meta)
    }
    fetchListClients();
  }, [pageing]);

  useEffect(() => {
    async function fetchListClients() {
      const res = await listIcomingOrder( id,{
        //...filter,
        departure_date: filter.departure_date
          ? moment(filter.departure_date).format("YYYY-MM-DD")
          : "",
        check_date: filter.check_date
          ? moment(filter.check_date).format("YYYY-MM-DD")
          : "",
          name:filter.name

      });
      setAllData(res?.data?.data);
      setMeta(res?.data?.meta)
    }
    fetchListClients();
  }, [searching]);

  console.log("meta***************************",meta)
  console.log("filter***************************",filter)

  /**Filter here */

  // const allData=[
  //   {
  //     id: 1,
  //     test_type: "PCR",
  //     grade: "VIP",
  //     reservation_type: "Home Visit",
  //     traveller_name: {
  //         ar: "Nicole Addison Walls Otto Roach Castillo",
  //         en: "Cooper Knox Merritt Frances Vang Morrow"
  //     },
  //     company_name: "Safa Visa",
  //     check_date: "2021-11-19T07:00:00.000000Z",
  //     check_date_end: "2021-11-19T08:00:00.000000Z",
  //     departure_date: "2021-11-20 12:00:00",
  //     total_cost: 200,
  //     payment_status: "partial_paid",
  //     request_date: "2021-11-15T15:47:22.000000Z"
  // },
  // {
  //   id: 2,
  //   test_type: "PCR",
  //   grade: "VIP",
  //   reservation_type: "Home Visit",
  //   traveller_name: {
  //       ar: "Nicole Addison Walls Otto Roach Castillo",
  //       en: "Cooper Knox Merritt Frances Vang Morrow"
  //   },
  //   company_name: "Safa Visa",
  //   check_date: "2021-11-19T07:00:00.000000Z",
  //   check_date_end: "2021-11-19T08:00:00.000000Z",
  //   departure_date: "2021-11-20 12:00:00",
  //   total_cost: 200,
  //   payment_status: "partial_paid",
  //   request_date: "2021-11-15T15:47:22.000000Z"
  // },
  // {
  //   id: 3,
  //   test_type: "PCR",
  //   grade: "VIP",
  //   reservation_type: "Home Visit",
  //   traveller_name: {
  //       ar: "Nicole Addison Walls Otto Roach Castillo",
  //       en: "Cooper Knox Merritt Frances Vang Morrow"
  //   },
  //   company_name: "Safa Visa",
  //   check_date: "2021-11-19T07:00:00.000000Z",
  //   check_date_end: "2021-11-19T08:00:00.000000Z",
  //   departure_date: "2021-11-20 12:00:00",
  //   total_cost: 200,
  //   payment_status: "partial_paid",
  //   request_date: "2021-11-15T15:47:22.000000Z"
  // },
  // ]
  

  return (
    <section>
      <div className="container p-0 my-4">
        <header className=" list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">{translate.Customers}</h3>
          <div>
            <NavLink
              exact={true}
              //to="/admin/ordersList/incoming/"
              to={`/admin/ordersList/incoming/${id}`}
              activeClassName="is-active"
              className="tab-link  py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
            >
              {translate.IncomingRequests}
            </NavLink>
            <NavLink
              exact={true}
              //to="/admin/ordersList/progress"
              to={`/admin/ordersList/progress/${id}`}
              activeClassName="is-active"
              className=" tab-link py-2 px-4 text-bold bg-white mr-3  bg-brwon rounded text-muted"
            >
              {translate.InProgress}
            </NavLink>

            <NavLink
              exact={true}
              //to="/admin/ordersList/checked"
              to={`/admin/ordersList/checked/${id}`}
              activeClassName="is-active"
              className=" tab-link py-2 px-4 text-bold bg-white  bg-brwon rounded text-muted"
            >
              {translate.AreChecked}
            </NavLink>
          </div>
        </header>
        <Filter  submit={submit} filter={filter} setfilter={setfilter}/>
        <ListTravelers allData={allData} filter={filter} setfilter={setfilter} setAllData={setAllData} meta={meta} setMeta={setMeta} goTo={goTo} /> {/* */}
      </div>
    </section>
  );
}

export default CustomersList;
