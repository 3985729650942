import React from "react";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import Locale from "../../../translations";

export default function Filter({ submit,emptyInputs, filter, setfilter }) {
  const { translate } = Locale;
  const active = [
    { value: 1, title: "active" ,label:`${translate.active}`},
    { value: 0, title: "inactive",label:`${translate.inactive}` },
  ];

  return (
    <div className="row p-3 bg-white no-gutters list_header bg-white comany-medical-filter ">
      <div className="col-3 mr-2">
        <TextField
          hasLabel={false}
          label={translate.search}
          type={"text"}
          value={filter?.search}
          placeholder={translate.search}
          onChange={(e) => {
            setfilter({
              ...filter,
              search: e.target.value,
            });
          }}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
     
      
      <div className="col-3 mr-2  ">
          <SelectField
            hasLabel={false}
            // onFocus={true}
            options={active}
            placeholder={translate.status}
            value={filter?.status}
            onChange={(e) => {
              setfilter({
                ...filter,
                status: e,
              });
            }}
           
          />
        </div>


      <div className="">
        <button
          className="btn btn-primary  text_filed px-4  font-weight-bold"
          onClick={submit}
        >
          {translate.search}
        </button>
        <button
          className="btn text-muted font-weight-bold "
          // onClick={() => emptyInputs()}
          onClick={() =>
            setfilter({
              name: "",
              status: "",
            })
          }
        >
          {" "}
          <i className="fas fa-retweet"></i>
          {translate.reset}
        </button>
      </div>
    </div>
  );
}
