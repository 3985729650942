import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import DatePicker from "../../../components/shared/DatePicker";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import {
  areasListAdmin,
  exportExeclAdmin,
  exportExeclAdminOrders,
  fetchCities,
  fetchHotelsSaudiAdmin,
} from "../../../services/admin";
import {
  exportExecl,
  fetchAreaSaudi,
  fetchCitiesSaudi,
  fetchHotelsSaudi,
} from "../../../services/UmrahRequests";
import Locale from "../../../translations";

export default function Filter({ filter, setfilter, submit }) {
  const { translate } = Locale;
  const [cities, setCities] = useState();
  const [areas, setAreas] = useState();
  const [hotels, setHotels] = useState();
  const { id, status } = useParams();
  const token = localStorage.getItem("token");

  const openExcel = async () => {
    const res = await exportExeclAdmin(
      id,
      {
        city_id: filter?.city_id?.value,
        area_id: filter?.area_id?.value,
        hotel_id: filter?.hotel_id?.value,
        date_from: filter?.date_from
          ? moment(filter.date_from).format("DD-MM-YYYY")
          : "",
        date_to: filter?.date_to
          ? moment(filter.date_to).format("DD-MM-YYYY")
          : "",
        company_name: filter.company_name,
        page: filter?.page ? filter?.page : null,
        Authorization: token,
      },
      status === "orders" ? null : status
    );
    if (res) {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const openExcelOrders = async () => {
    const res = await exportExeclAdminOrders(
      id,
      {
        city_id: filter?.city_id?.value,
        area_id: filter?.area_id?.value,
        hotel_id: filter?.hotel_id?.value,
        date_from: filter?.date_from
          ? moment(filter.date_from).format("DD-MM-YYYY")
          : "",
        date_to: filter?.date_to
          ? moment(filter.date_to).format("DD-MM-YYYY")
          : "",
        company_name: filter.company_name,
        page: filter?.page ? filter?.page : null,
        Authorization: token,
      },
      status === "orders" ? null : status
    );
    if (res) {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(async () => {
    const res = await fetchCities("966");
    const formatted = res?.map((city) => {
      return { value: city.id, label: city.name };
    });
    setCities(formatted);
  }, []);

  useEffect(async () => {
    if (filter.city_id?.value) {
      const res = await areasListAdmin(filter.city_id?.value);

      const formatted = res?.data.map((area) => {
        return { value: area.id, label: area.name };
      });
      setAreas(formatted);
    }
  }, [filter.city_id]);

  useEffect(async () => {
    if (filter.area_id?.value) {
      const res = await fetchHotelsSaudiAdmin(filter.area_id?.value);

      const formatted = res.map((hotel) => {
        return { value: hotel.id, label: hotel.name };
      });
      setHotels(formatted);
    }
  }, [filter.area_id]);

  return (
    <div className="row p-3 bg-white no-gutters list_header bg-white order-filter ">
      <div className="col-12 col-md-3 col-lg  ">
        <TextField
          hasLabel={false}
          placeholder={translate.companyNamePlaceholder}
          onChange={(e) => {
            setfilter({
              ...filter,
              page: null,
              company_name: e.target.value,
            });
          }}
          value={filter.company_name}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>

      {status === "pending" || status === "checked" ? (
        <div className="col-12 col-md-3 col-lg  mx-2 ">
          <TextField
            hasLabel={false}
            placeholder={translate.passportNumber}
            onChange={(e) => {
              setfilter({
                ...filter,
                page: null,
                passport_number: e.target.value,
              });
            }}
            value={filter.passport_number}
          >
            <i className="fas fa-search"></i>
          </TextField>
        </div>
      ) : null}

      <div className="col-12 col-md-3 col-lg  mx-2 selectUmrah ">
        <SelectField
          hasLabel={false}
          onFocus={false}
          label={translate.city}
          value={filter.city_id}
          placeholder={translate.cityPlaceholder}
          options={cities}
          onChange={(e) => {
            setfilter({
              ...filter,
              page: null,

              city_id: e,
            });
          }}
        />
      </div>

      <div className="col-12 col-md-3 col-lg mt-2 mt-md-0  mx-md-1 selectUmrah">
        <SelectField
          hasLabel={false}
          onFocus={false}
          options={areas}
          label={translate.area}
          value={filter.area_id}
          placeholder={translate.areaPlaceholder}
          onChange={(e) => {
            setfilter({
              ...filter,
              page: null,
              area_id: e,
            });
          }}
        />
      </div>

      <div className="col-12 col-md-3 col-lg mt-2 mt-lg-0  mx-1 selectUmrah">
        <SelectField
          hasLabel={false}
          value={filter.hotel_id}
          placeholder={translate.hotelName}
          options={hotels}
          onChange={(e) => {
            setfilter({
              ...filter,
              page: null,
              hotel_id: e,
            });
          }}
        >
          <i className="fas fa-search"></i>
        </SelectField>
      </div>

      <div className="col-12 col-md col-lg   mt-2 mt-lg-0 mx-lg-1 ">
        <DatePicker
          hasLabel={false}
          placeholder={translate.from}
          date={filter?.date_from}
          name="date_from"
          onChangeDate={(e) => {
            setfilter({
              ...filter,
              page: null,
              date_from: e,
            });
          }}
        />
      </div>
      <div className="col-12 col-md col-lg  mt-2 mt-lg-0 mx-lg-1 ">
        <DatePicker
          hasLabel={false}
          placeholder={translate.to}
          date={filter?.date_to}
          name="date_to"
          onChangeDate={(e) => {
            setfilter({
              ...filter,
              page: null,
              date_to: e,
            });
          }}
          minDate={moment(filter?.date_from).subtract(1, "days")}
          disabled={!filter?.date_from}
        />
      </div>
      <div className="d-flex   flex-column justify-content-start align-items-start mt-2 mt-lg-0">
        <button
          className="btn btn-primary font-weight-bold mx-2  px-4 "
          onClick={submit}
          disabled={filter.date_from && !filter.date_to}
        >
          {translate.search}
        </button>

        <button
          className=" text-muted font-weight-bold f-12 mt-1  "
          onClick={() => {
            setfilter({
              city_id: null,
              area_id: null,
              hotel_id: "",
              date_from: "",
              date_to: "",
              company_name: "",
              passport_number: "",
            });
            submit();
          }}
        >
          {" "}
          <i className="fas fa-retweet"></i>
          {translate.reset}
        </button>
      </div>

      <div className="d-flex flex-column justify-content-start align-items-start">
        <button
          className="text-muted font-weight-bold f-12"
          onClick={openExcel}
        >
          <i class="fas fa-file-excel fa-2x text-success  mx-1"></i>
          {translate.tripsExcel}
        </button>

        {status != "checked"  ? (
          <button
            className="text-muted font-weight-bold f-12"
            onClick={openExcelOrders}
          >
            <i class="fas fa-file-excel fa-2x text-success  mx-1"></i>
            {translate.travelersExcel}
          </button>
        ) : null}
      </div>
    </div>
  );
}
