import moment from "moment";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCompanyState } from "../../../../context/global";
import Locale from "../../../../translations";

export default function Invoice({ invoice, changeStatus }) {
  const { currentLanguage } = useCompanyState();
  const { translate } = Locale;
  let history = useHistory();
  const { id } = useParams();

  return (
    <tr>
      <td>{invoice.sn}</td>
      <td>{invoice.amount}</td>
      <td>{invoice.count}</td>
      <td>{invoice.created_at}</td>
      <td
        className={`
          font-weight-bold  ${
            invoice?.status === "paid" || invoice?.status === "مدفوع"
              ? "text-success"
              : invoice?.status === null
              ? "text-danger"
              : "text-warning"
          } `}
      >
        {invoice?.status}
      </td>

      <td className="d-flex">
        {invoice.status != "paid"  ? (
          <button
            type="button"
            class="btn btn-success mx-1"
            onClick={() => {
              changeStatus(invoice.id);
            }}
          >
            {translate.PaymentMade}
          </button>
        ) : null}
        <button
          type="button"
          class="btn btn-primary mx-1"
          onClick={() => {
            history.push(`/admin/${id}/print/${invoice.id}`);
          }}
        >
          {translate.print}
        </button>
      </td>
    </tr>
  );
}
