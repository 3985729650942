import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import { useCompanyDispatch, useCompanyState } from "../context/global";
import HeaderCheckMe from "./layout/HeaderCheckMe";

export default function CheckRouter({ component: Component, ...props }) {
  let { isAuth } = useCompanyState();

 

  if (isAuth) {
    return (
    	<Route
    		{...props}
    		render={(matchProps) => (
      <>
        <HeaderCheckMe />
    				<Component {...matchProps} />
      </>
    		)}
    	/>
    );
  } else {
    return <Redirect to="/login" />;
  }
}
