import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ShowForPermission from "../../../helpers/showForPermission";
import { permissionsReload } from "../../../services/yarvel";
import Locale from "../../../translations";

export default function User(props) {
  const { user } = props;
  //const { Labs } = Locale;
  const { translate } = Locale;

  console.log(props);


  const [currentUser, setCurrentUser] = useState()//"testuser@ff.com"

  useEffect(() => {
    async function fetchLookups() {
      const res = await permissionsReload();
      setCurrentUser(res?.data?.email);
    }
    fetchLookups();

  }, []);
  return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.phone_number}</td>
        <td>{user.group}</td>
        <td
          className={
            user.status === "active"
              ? "text-green font-weight-bold"
              : "text-danger font-weight-bold"
          }
        >
          {" "}
          {user.status=='inactive'?`${translate.deactivate1}`:`${translate.active1}`}
        </td>
        <td>
          <div className="actions">
            <span className="text-primary d-flex ">
              <ShowForPermission permission={"company:edit-user"}>
                <NavLink
                  exact={true}
                  to={`/team-management/users/edit/` + user.id}
                  activeClassName="is-active"
                  className="p-2 text-primary mx-2 btn btn-link  font-weight-bold"
                >
                  <i className="fas fa-edit"></i> {translate.edit}
                </NavLink>
              </ShowForPermission>

{console.log("user.email",user.email, "currentUser",currentUser==user.email)}

              {user.email != currentUser?(
  <>
{user.status === "active" ? (
                <ShowForPermission permission={"company:activate-user"}>
                  <button
                    type="button"
                    onClick={() =>
                      props.changeStatusUser(user.id, "deactivate")
                    }
                    className="btn btn-link text-danger stopping mx-2 font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    
                    <i
                      className="fas fa-stop-circle mx-1"
                      style={{ fontSize: "16px" }}
                    ></i>{" "}
                    {translate.deactive}
                  </button>
                </ShowForPermission>
              ) : (
                <ShowForPermission permission={"company:activate-user"}>
                  <button
                    type="button"
                    onClick={() => props.changeStatusUser(user.id, "activate")}
                    className="btn btn-link  mx-2 d-flex text-success font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    <i
                      class="far fa-play-circle mx-1"
                      style={{ fontSize: "16px" }}
                    ></i>{" "}
                    {translate.activate}
                  </button>
                </ShowForPermission>
              )}

              

              <ShowForPermission permission={"company:delete-user"}>
              <button className="btn btn-danger mx-3 delete-user" onClick={() => props.deleteUserHandler(user.id)}>
                {translate.remove}
              </button>
              </ShowForPermission> 

</>
):""}





              {/* {user.status === "active" ? (
                <ShowForPermission permission={"company:activate-user"}>
                  <button
                    type="button"
                    onClick={() =>
                      props.changeStatusUser(user.id, "deactivate")
                    }
                    className="btn btn-link text-danger stopping mx-2 font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    
                    <i
                      className="fas fa-stop-circle mx-1"
                      style={{ fontSize: "16px" }}
                    ></i>{" "}
                    {translate.deactive}
                  </button>
                </ShowForPermission>
              ) : (
                <ShowForPermission permission={"company:activate-user"}>
                  <button
                    type="button"
                    onClick={() => props.changeStatusUser(user.id, "activate")}
                    className="btn btn-link  mx-2 d-flex text-success font-weight-bold"
                    style={{ width: "80px" }}
                  >
                    <i
                      class="far fa-play-circle mx-1"
                      style={{ fontSize: "16px" }}
                    ></i>{" "}
                    {translate.active}
                  </button>
                </ShowForPermission>
              )}

              

              <ShowForPermission permission={"company:delete-user"}>
              <button className="btn btn-danger mx-3" onClick={() => props.deleteUserHandler(user.id)}>
                {translate.remove}
              </button>
              </ShowForPermission> */}


            </span>

            
          </div>
        </td>
      </tr>
     
    </>
  );
}
