import { store } from "react-notifications-component";
import ShowForPermission from "../../../helpers/showForPermission";
import { editServices } from "../../../services/yarvel";
import Locale from "../../../translations";
export default function ServicesList({ service, setToggle }) {
  let serviceBtn;
  const { translate } = Locale;
  const organizationCountry = localStorage.getItem("organizationCountry");

  /***Api edit */
  //editServices
  const edit = async () => {
    const res = await editServices(service.id);
    if (res.status === 200) {
      setToggle((current) => !current);
      store.addNotification({
        title: "info!",
        message: translate.serviceApproved,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };
  /***Api edit */

  if (service.status === 0) {
    serviceBtn = (
      <button className="btn btn-success font-weight-bold py-2 " onClick={edit}>
        {translate.servicesEnsure}
      </button>
    );
  } else {
    serviceBtn = (
      <button className="btn text-success font-weight-bold py-2 ">
        <i class="fas fa-check "></i> {translate.serviceAdded}
      </button>
    );
  }

  return (
    <>
      <ShowForPermission permission={"company:list-services"}>
        <div className="bg-white  px-5 py-3 services-items">
          <div
            className="d-flex  m-auto p-3 instructions"
            style={{ width: "100%" }}
          >
            {" "}
            {/**80% */}
            <div className="w-100 row">
              <p className="col-xs-6  col-sm-9 col-md-9 col-lg-6 h5  py-2 font-weight-bold">
                {/**col-6 */}
                {translate.service}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2">
                {service.check}
              </p>{" "}
              {/**col-6 */}
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2 font-weight-bold">
                {translate.serviceType}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2">
                {service.type}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2 font-weight-bold">
                {translate.serviceDegree}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2">
                {service.grade}
              </p>
            </div>
            <div className="w-100 row mx-5">
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5  py-2 font-weight-bold">
                {translate.ResultTime}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2">
                {service.time} {translate.hour}{" "}
              </p>

              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2 font-weight-bold">
                {translate.sevicePrice}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2">
                {service.price}{" "}
                {organizationCountry === "20"
                  ? translate.pound
                  : translate.riyal}
              </p>

              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2 font-weight-bold">
                {translate.commission}
              </p>
              <p className="col-xs-6 col-sm-9 col-md-9 col-lg-6 h5 py-2">
                {service.safa_comission}{" "}
                {organizationCountry === "20"
                  ? translate.pound
                  : translate.riyal}
              </p>
            </div>
            <div className=" w-50 d-flex align-items-center services-btn">
              <ShowForPermission permission={"company:approve-services"}>
                {serviceBtn}
              </ShowForPermission>
            </div>
          </div>
        </div>
      </ShowForPermission>
    </>
  );
}
