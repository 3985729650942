import Locale from "../../../translations";
import { NavLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import ListRequests from "./ListRequests";
import {
  listTravelers,
  listTravelersURl,
} from "../../../services/UmrahRequests";
import moment from "moment";
import Pagination from "../../../components/shared/Pagination";

export default function UmrahRequests() {
  const { status } = useParams();
  const { translate } = Locale;
  const [listRequests, setListRequests] = useState();

  const [searching, setSearching] = useState(false);
  const [meta, setMeta] = useState();

  const [filter, setfilter] = useState({
    city_id: null,
    area_id: null,
    hotel_id: null,
    date: "",
    company_name: "",
    passport_number: "",
    isSearchOrder: false,
  });

  useEffect(async () => {
    let res;

    res = await listTravelers(
      {
        city_id: filter?.city_id?.value,
        area_id: filter?.area_id?.value,
        hotel_id: filter?.hotel_id,
        date: filter?.date ? moment(filter.date).format("DD-MM-YYYY") : "",
        company_name: filter.company_name,
        passport_number: filter.passport_number,
        page: filter?.page ? filter?.page : null,
      },
      status === "orders" ? null : status
    );

    setListRequests(res?.data);
    setMeta(res?.meta);
  }, [searching, status]);

  const goTo = async (page) => {
    setfilter({ ...filter, page: page });
     setSearching(!searching);

  };

  const submit = () => {
    setSearching(!searching);
  };

  return (
    <div className="container p-0 my-4 orders-list lan">
      <header className="list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
        <h3 className="text-muted font-weight-bold">
          {translate.ComanyMedical}
        </h3>
        <div className="navLink ">
          <NavLink
            exact={true}
            to="/Umrah/Medical/Requests/orders"
            activeClassName="is-active"
            className="tab-link  py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
            onClick={() => {
              if (status != "orders") {
                setListRequests({});
                setfilter({
                  city_id: null,
                  area_id: null,
                  hotel_id: null,
                  date: "",
                  company_name: "",
                  page: null,
                });
              }
            }}
          >
            {translate.IncomingRequests}
          </NavLink>
          <NavLink
            exact={true}
            to="/Umrah/Medical/Requests/pending"
            activeClassName="is-active"
            className=" tab-link py-2 px-4 text-bold bg-white mr-3  bg-brwon rounded text-muted"
            onClick={() => {
              if (status != "pending") {
                setListRequests({});
                setfilter({
                  city_id: null,
                  area_id: null,
                  hotel_id: null,
                  date: "",
                  company_name: "",
                  page: null,
                });
              }
            }}
          >
            {translate.InProgress}
          </NavLink>

          <NavLink
            exact={true}
            to="/Umrah/Medical/Requests/checked"
            activeClassName="is-active"
            className=" tab-link py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
            onClick={() => {
              if (status != "checked") {
                setListRequests({});
                setfilter({
                  city_id: null,
                  area_id: null,
                  hotel_id: null,
                  date: "",
                  company_name: "",
                  page: null,
                });
              }
            }}
          >
            {translate.AreChecked}
          </NavLink>
        </div>
      </header>

      <Filter submit={submit} filter={filter} setfilter={setfilter} />

      {listRequests && (
        <ListRequests listRequests={listRequests} setSearching={setSearching} />
      )}

      <Pagination info={meta} goTo={goTo} items={10} />
    </div>
  );
}
