import React, { useEffect } from "react";
import Activity from "./Activity";
import Counts from "./Counts";
import TravelersNumber from "./TravelersNumber";
import ApexChart from "./chart";
import { useState } from "react";
import { dashboardList, permissionsReload } from "../../../services/yarvel";
import Locale from "../../../translations";
import { useCompanyState } from "../../../context/global";
export default function DashboardCompanies() {
  const { translate } = Locale;
  const { currentLanguage } = useCompanyState();

  /************Api */
  //dashboardList
  const [dashboardData, setDashboardData] = useState();


  const [toggle, setToggle] = useState(false);

  // if(localStorage.getItem("isAuth")!=='true'){
  //   //setToggle(!toggle);
  //   console.log("taha !!*")
  // }
  useEffect(() => {
    async function fetchData() {
      const res = await dashboardList();
      setDashboardData(res?.data);
    }
    fetchData();
    
  }, []);


  const all =
    dashboardData &&
    dashboardData.logs &&
    Object.entries(dashboardData?.logs).length > 0
      ? Object.entries(dashboardData?.logs).map((data) => (
          <Activity data={data} />
        ))
      : "";


  const apexChart =
    dashboardData &&
    dashboardData.logs &&
    Object.entries(dashboardData?.logs).length > 0
      ? 
          <ApexChart num={dashboardData} currentLanguage={currentLanguage} />

      : "";

  /***********Api  */





  return (
    <>
      <section className="dashboard-companies">
        <div className="container-fluid">
          <div className="row px-5 pt-4">
            <div className="col-md-6 col-lg-3 col-sm-6">
              <Counts number={dashboardData} />
            </div>
            <div className="col-md-12  col-lg-6 col-sm-12">
              <div className="bg-white shadow-sm h-100  p-2 font-weight-bold orders">
                <p className="text">
                  <TravelersNumber />
                </p>
                <div className="row my-5">
                  <div className="col-md-7 col-sm-12">
                    {" "}
                    {/* <ApexChart num={dashboardData} /> */}

                    {apexChart }
                  </div>
                  <div className="col-md-4 col-sm-12  d-flex flex-column  justify-content-center order-dots">
                    <div className="mt-3">
                      <h4>
                        <i
                          className="fas fa-circle mx-3"
                          style={{ color: "#24AEFB", fontSize: "15px " }}
                        ></i>
                        {translate.IncomingRequests}
                      </h4>
                      <h4 className="mx-5">
                        {dashboardData?.reservations?.new}
                      </h4>
                    </div>
                    <div className="mt-3">
                      <h4>
                        <i
                          className="fas fa-circle mx-3"
                          style={{ color: "#F28F31", fontSize: "15px " }}
                        ></i>
                        {translate.InProgress}
                      </h4>
                      <h4 className="mx-5">
                        {dashboardData?.reservations?.pending}
                      </h4>
                    </div>
                    <div className="mt-3">
                      <h4>
                        <i
                          className="fas fa-circle mx-3"
                          style={{ color: "#00B536", fontSize: "15px " }}
                        ></i>{" "}
                        {translate.AreChecked}{" "}
                      </h4>
                      <h4 className="mx-5">
                        {dashboardData?.reservations?.checked}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12  col-lg-3 col-sm-12">
              <div className="p-2 activity bg-white shadow-sm rounded">
                <div className="title">
                  <h2 className="font-weight-bold text-subtitle">
                    {translate.myActivity}
                  </h2>
                  <p className="text">{translate.activityText}</p>
                </div>
                  {/**login **/}
                  {all}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
