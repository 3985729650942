import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  createResultProgress,
  listTravellersById,
} from "../../../../services/yarvel";
import SelectField from "../../../../components/shared/SelectField";
import Locale from "../../../../translations";
import { useCompanyState } from "../../../../context/global";

export default function ViewProgress() {
  const { translate } = Locale;
  let history = useHistory();
  const { currentLanguage } = useCompanyState();

  /********* */
  //listTravellersById
  const [employee, setEmployee] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      const Employees = await listTravellersById(id);
      setEmployee(Employees.data?.data);
    }
    fetchData();
  }, []);
  const [fileSelected, setFileSelected] = useState({});
  const inputFile = useRef(null);
  const onFileChange = async (e) => {
    setFileSelected(e.target.files[0]);
  };

  const handleClick = (e) => {
    inputFile.current.click();
    //setFileSelected(e.target.files);
    //const formData = new FormData();
    //formData.append("file", fileSelected);
    //console.log("file!,",fileSelected)
  };

  /********* */

  /********************** */
  const [selectedValue, setSelectedValue] = useState(" ");

  const optionsTest = [
    { value: 1, label: "negative" },
    { value: 2, label: "positive" },
  ];

  const handleChange = (e) => {
    setSelectedValue(e.label);
    //console.log("e.value*****",e.label)
  };

  /**************** */

  /********Api */

  //createResultProgress

  const [resultProgress, setResultProgress] = useState(" ");

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("file", fileSelected);
    formData.append("status", selectedValue);
    const res = await createResultProgress(id, formData);
    setResultProgress(res.data);
    if (res?.status === 200) {
      history.push(`/CustomersList/progress`);
      //fetchData();
    }
  };

  /********** */
  return (
    <div className="container p-0 order-list-view">
      {/* <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold">
       {translate.PCR}
        </h3>
      </header> */}

      <div className="bg-white px-5 py-4 instructions-all">
        {/* *************************************************************************/}
        <div className="instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark">
            {" "}
            {translate.orderData}
          </h5>
          <div className=" p-2 row">
            <p className="col-2 h5   font-weight-bold">{translate.check}</p>
            <p className="col-4 h5 ">{employee?.test_type}</p>
            <p className="col-2 h5   font-weight-bold">
              {translate.orderNumber}
            </p>
            <p className="col-4 h5 ">{employee?.reservation_no}</p>
            <p className="col-2 h5   font-weight-bold">{translate.typeCheck}</p>
            <p className="col-4 h5 ">{employee?.reservation_type}</p>

            <p className="col-2  h5  font-weight-bold">
              {translate.timeRequest}
            </p>
            <p className="col-4 h5 ">
              {employee?.request_date?.slice(0, 10)} |{" "}
              {employee?.request_date?.slice(11, 16)}
            </p>

            <p className="col-2  h5  font-weight-bold">
              {translate.serviceDegree}
            </p>
            <p className="col-4 h5 ">{employee?.grade}</p>

            <p className="col-2  h5  font-weight-bold">
              {translate.DataOperation}
            </p>
            <p className="col-4 h5 ">
              {employee?.operationOrder_created_at?.slice(0, 10)} |{" "}
              {employee?.operationOrder_created_at?.slice(11, 16)}
            </p>

            <p className="col-2 h5  font-weight-bold">
              {translate.resultWithin}
            </p>
            <p className="col-4 h5 ">
              {employee?.time} {translate.hour}
            </p>

            <p className="col-2 h5  font-weight-bold">
              {translate.nameOperation}
            </p>
            <p className="col-4 h5 ">{employee?.operationOrder_employee} </p>
          </div>
        </div>

        {/* <div className="instructions py-3 p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 py-1"> {employee?.reservation_no}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.ResultTimeResult}
          </p>
          <p className="col-4 h5 py-1">{employee?.check_date_period} {translate.hour}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 py-1">{(employee?.request_date)?.slice(0,10)} | {(employee?.request_date)?.slice(11,16)}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.OperationOfficer}
          </p>
          <p className="col-4 h5 py-1">{(employee?.operationOrder_employee)} </p>
          
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.DataOperation}
          </p>
          <p className="col-4 h5 py-1">{(employee?.operationOrder_created_at)?.slice(0,10)} | {(employee?.operationOrder_created_at)?.slice(11,16)}</p>
        </div> */}
        {/* *************************************************************************/}
        {/* <div className="instructions border my-4">
 <h5 className="font-weight-bold p-2 text_blue-dark ">
          {" "}
          {translate.TripData}
        </h5>

        <div className=" p-2 row">
          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 py-1">{employee?.trip?.departure_port[currentLanguage]}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.arrivalDate}
          </p>
          <p className="col-4 h5 py-1">
          {employee?.trip?.arrival_date?.slice(0,10)} | {employee?.trip?.arrival_date.slice(11,16)}

          </p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1">{employee?.trip?.transporter[currentLanguage]}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
           {translate.departureDate} 
          </p>
          <p className="col-4 h5 py-1">
          {employee?.trip?.departure_date?.slice(0,10)} | {employee?.trip?.departure_date.slice(11,16)}
</p>
          
        </div>
        </div> */}

        {/* *************************************************************************/}

        {(currentLanguage == "ar" && employee?.test_type !== "تحليل مخدرات") ||
        (currentLanguage == "en" &&
          employee?.test_type !== "Drugs Examination") ? (
          <>
            <div className="instructions border">
              <h5 className="font-weight-bold px-2 py-1 text_blue-dark mt-2">
                {" "}
                {translate.TripData}
              </h5>

              <div className="  p-2 row">
                <p className="col-2 h5   font-weight-bold">
                  {translate.destnation}
                </p>
                <p className="col-4 h5 ">
                  {employee?.trip?.departure_port[currentLanguage]}{" "}
                  {/**destination?.names */}
                </p>
                <p className="col-2  h5  font-weight-bold">
                  {translate.departureDate}
                </p>
                <p className="col-4 h5 ">
                  {employee?.trip?.departure_date?.slice(0, 10)}{" "}
                  {/*| {employee?.trip?.departure_date?.slice(11, 16)}*/}
                </p>
                <p className="col-2  h5  font-weight-bold">
                  {translate.Transporter}
                </p>
                <p className="col-4 h5 ">
                  {" "}
                  {employee?.trip?.transporter[currentLanguage]}{" "}
                  {/**[currentLanguage] */}
                </p>

                <p className="col-2 h5  font-weight-bold">
                  {translate.arrivalDate}
                </p>
                <p className="col-4 h5 ">
                  {" "}
                  {employee?.trip?.arrival_date?.slice(0, 10)}{" "}
                  {/*| {employee?.trip?.arrival_date?.slice(11, 16)}*/}
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/* *************************************************************************/}

        {/* *************************************************************************/}
        <div className="instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark ">
            {" "}
            {translate.CustomerData}
          </h5>

          <div className="  p-2 row">
            <p className="col-2 h5   font-weight-bold">
              {translate.nameArabic}
            </p>
            <p className="col-4 h5 ">{employee?.traveller_name_ar}</p>
            <p className="col-2 h5  font-weight-bold">{translate.nationalId}</p>
            <p className="col-4 h5 ">{employee?.national_id}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.EnglishName}
            </p>
            <p className="col-4 h5 ">{employee?.traveller_name_en}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.passportNumber}
            </p>
            <p className="col-4 h5 ">{employee?.passport_number}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.Nationality}
            </p>
            <p className="col-4 h5 ">{employee?.nationality}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.phoneNumber}
            </p>
            <p className="col-4 h5 ">{employee?.phone}</p>
          </div>
        </div>

        {/* *************************************************************************/}
        <div className="instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark ">
            {" "}
            {translate.ExaminationDateAddress}
          </h5>

          <div className=" p-2 rounded-lg row">
            <p className="col-2 h5   font-weight-bold">
              {currentLanguage == "en"
                ? `${translate.check} ${translate.date}`
                : `${translate.date} ${translate.check}`}
              {/* {translate.check} {translate.date}  */}
            </p>
            <p className="col-4 h5 ">
              {employee?.check_date?.slice(11, 16)} |{" "}
              {employee?.check_date?.slice(0, 10)}
            </p>
            <p className="col-2 h5   font-weight-bold">
              {translate.ResultTime}
            </p>
            <p className="col-4 h5 ">
              {employee?.check_date?.slice(11, 16)}
              {/* - {employee?.check_date_end?.slice(11, 16)}*/}
            </p>

            <p className="col-2 h5   font-weight-bold">
              {translate.governorate}
            </p>
            <p className="col-4 h5 ">{employee?.city_id}</p>
            <p className="col-2 h5   font-weight-bold">{translate.city}</p>
            <p className="col-4 h5 ">{employee?.area_id}</p>

            <p className="col-2 h5   font-weight-bold">{translate.StName}</p>
            <p className="col-4 h5 ">{employee?.street}</p>
            <p className="col-2 h5   font-weight-bold">
              {translate.BuildingNumber}
            </p>
            <p className="col-4 h5 ">{employee?.building}</p>

            <p className="col-2 h5   font-weight-bold">{translate.Floor}</p>
            <p className="col-4 h5 ">{employee?.floor}</p>
            <p className="col-2 h5  font-weight-bold">
              {translate.ApartmentNumber}
            </p>
            <p className="col-4 h5 ">{employee?.flat}</p>
            <p className="col-2  h5  font-weight-bold">
              {translate.phoneNumber}
            </p>
            <p className="col-4 h5 ">{employee?.phone}</p>
            <p className="col-2  h5  font-weight-bold">{translate.email}</p>
            <p className="col-4 h5 ">{employee?.email}</p>

            {/* <p className="col-2  h5 py-3 font-weight-bold">{translate.Cost}</p>
          <p className="col-4 h5 py-3">
            {employee?.data?.amount}
            {translate.pound}
          </p> */}
            {/* <p className="col-2  h5 py-3 font-weight-bold">
            {translate.paymentMethods}
          </p>
          <p className="col-4 h5 py-3">{employee?.data?.comission_type}</p> */}
          </div>
        </div>
        {/* *************************************************************************/}

        {/* *************************************************************************/}
        <div className=" view-result-progress instructions border my-4">
          <h5 className="font-weight-bold p-2 text_blue-dark ">
            {" "}
            {translate.analysisResults}
          </h5>

          <div className=" p-2 ">
            <div className=" m-auto " style={{ width: "40%" }}>
              <div className=" py-3 ">
                <div className="view">
                  <SelectField
                    onFocus={true}
                    hasLabel={true}
                    label={translate.analysisResults}
                    placeholder={translate.analysisResults}
                    options={optionsTest}
                    value={optionsTest.find(
                      (obj) => obj.value === selectedValue
                    )}
                    onChange={handleChange}
                  />
                </div>

                <div className="box0 d-flex justify-content-between align-items-center bg-white w-100 my-3 p-3">
                  <div className="text-muted">
                    <p>
                      {translate.UploadResultExamination} <br /> *
                      {fileSelected?.name}*{" "}
                    </p>
                  </div>

                  <button
                    className="btn btn-primary px-3"
                    onClick={handleClick.bind(this)}
                  >
                    <input
                      type="file"
                      id="file"
                      onChange={onFileChange}
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                    {translate.selectFile}
                  </button>
                </div>

                <button
                  className="btn btn-success btn-lg my-3 w-100"
                  onClick={() => {
                    fetchData();
                  }}
                >
                  {translate.sendResult}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className=" btn btn-secondary px-5 my-4 font-weight-bold"
        onClick={() => {
          history.push("/CustomersList/progress");
        }}
      >
        {" "}
        {translate.back}
      </button>
    </div>
  );
}
