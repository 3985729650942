import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";

//******************Start Check Me********************************

import CustomersList from "./modules/checkMe/travelers/CustomersList";
import Progress from "./modules/checkMe/travelers/CustomersList/progress";
import Checked from "./modules/checkMe/travelers/CustomersList/checked";
import View from "./modules/checkMe/travelers/CustomersList/view";
//import Payments from "./modules/checkMe/Payments"
import Services from "./modules/checkMe/Services";
import Setting from "./modules/checkMe/setting";
import ViewProgress from "./modules/checkMe/travelers/CustomersList/viewProgress";
import ViewCheched from "./modules/checkMe/travelers/CustomersList/viewChecked";
import DashboardCompanies from "./modules/checkMe/dashboard/DashboardCompanies";
import ViewResultProgress from "./modules/checkMe/travelers/CustomersList/viewResultProgress";
import ViewOPeration from "./modules/checkMe/travelers/CustomersList/viewOperation";
import Login from "./modules/checkMe/auth/auth";
import { AuthRoute } from "./components/AuthRoute";
import CheckRouter from "./components/CheckRouter";

// *************************Import Admin************************
import AdminRouter from "./components/AdminRouter";
//AddCompany
import LoginAdmin from "./modules/admin/auth/auth";
import DashboardCompaniesAdmin from "./modules/admin/dashboard/DashboardCompanies";
import ComanyMedicalList from "./modules/admin/ComanyMedicalList";
import ViewComanyMedical from "./modules/admin/ComanyMedicalList/ViewComanyMedical";
import EditCompany from "./modules/admin/ComanyMedicalList/editCompany";
import AddCompany from "./modules/admin/ComanyMedicalList/addCompany";
import UsersAdmin from "./modules/admin/ComanyMedicalList/user/users";
import OrdersList from "./modules/admin/ComanyMedicalList/OrdersList";
import OrdersProgress from "./modules/admin/ComanyMedicalList/OrdersList/progress";
import OrdersChecked from "./modules/admin/ComanyMedicalList/OrdersList/checked";
import ViewIncoming from "./modules/admin/ComanyMedicalList/OrdersList/view";
import ViewProgressOrders from "./modules/admin/ComanyMedicalList/OrdersList/viewProgress";
import ViewCheckedOrders from "./modules/admin/ComanyMedicalList/OrdersList/viewChecked";
import payment from "./modules/admin/ComanyMedicalList/payment/payment";
// import AddUser from "./modules/admin/ComanyMedicalList/user/addUser";
// import EditUser from "./modules/admin/ComanyMedicalList/user/editUser";

import Users from "./modules/checkMe/WorkTeam/index";
import AddUser from "./modules/checkMe/WorkTeam/add";
import EditUser from "./modules/checkMe/WorkTeam/edit";
import Groups from "./modules/checkMe/WorkTeam/groups";
import AddGroup from "./modules/checkMe/WorkTeam/addGroup";
import EditGroup from "./modules/checkMe/WorkTeam/editGroup";
//import PaymentCheck from "./modules/checkMe/payment/payment";
import PaymentCheck from "./modules/checkMe/payment/payment";

import WorkTeamAdmin from "./modules/admin/WorkTeam/index";
import AddAdminUser from "./modules/admin/WorkTeam/add";
import EditAdminUser from "./modules/admin/WorkTeam/edit";
import AdminGroups from "./modules/admin/WorkTeam/groups";
import AddAdminGroup from "./modules/admin/WorkTeam/addGroup";
import EditAdminGroup from "./modules/admin/WorkTeam/editGroup";
import ResetPassword from "./modules/admin/auth/reset-password";
import ForgetPassword from "./modules/admin/auth/forget-password";
import ResetPasswordAdmin from "./modules/checkMe/auth/reset-password";
import ForgetPasswordAdmin from "./modules/checkMe/auth/forget-password";
import ChangePassword from "./modules/checkMe/auth/changePassord";
import Notification from "./modules/admin/Notification";
import NotificationCheck from "./modules/checkMe/Notification";
import UmrahRequests from "./modules/checkMe/Umrah-Medical-Requests";
import { viewTravelers } from "./services/UmrahRequests";
import ViewTravellers from "./modules/checkMe/Umrah-Medical-Requests/ViewTravellers";
import SaudiPayment from "./modules/admin/ComanyMedicalList/SaudiPayment";
import Invoices from "./modules/admin/ComanyMedicalList/Invoices";
import PrintInvoice from "./modules/admin/ComanyMedicalList/Invoices/PrintInvoice";
import PackagesRequests from "./modules/admin/Umrah-Packages-Requests";
import AdminUmrahView from "./modules/admin/Umrah-View-Requests";
import ViewTravellersAdmin from "./modules/admin/Umrah-View-Requests/ViewTravellersAdmin";
import PilgrimsLated from "./modules/checkMe/Umarah-pilgrims-lated";
import ViewTravellersPackages from "./modules/admin/Umrah-Packages-Requests/ViewTravellersPackages";
import LatedAdmin from "./modules/admin/Umarah-pilgrims-lated";
import ViewTravellersLated from "./modules/admin/Umarah-pilgrims-lated/ViewTravellersLated";

// import NotConfirmed from "./modules/checkMe/auth/pages/NotConfirmed";
// import Activation from "./modules/checkMe/auth/pages/Activation";
// import ForgotPassword from "./modules/checkMe/auth/pages/ForgotPassword";
// import ForgotPasswordSubmitted from "./modules/checkMe/auth/pages/ForgotPasswordSubmitted";
// import Register from "./modules/checkMe/auth/pages/Register";
// import RegistrationSucceed from "./modules/checkMe/auth/pages/NotConfirmed";
//import ResetPassword from "./modules/checkMe/auth/ResetPassword";

//import ResetPassword from "modules/Yarvel/auth/pages/ResetPassword";

const Routes = (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      {/*****************************Start Check Me***************************************************** */}
      <AuthRoute exact path="/login" component={Login} />
      <AuthRoute path="/reset-password" exact component={ResetPasswordAdmin} />
      <AuthRoute
        path="/forget-password"
        exact
        component={ForgetPasswordAdmin}
      />
      <CheckRouter exact path="/changePassord" component={ChangePassword} />
      <CheckRouter
        exact
        path="/CustomersList/dashboard/DashboardCompanies"
        component={DashboardCompanies}
      />
      {/******************Start Travelers******************* */}
      <CheckRouter
        exact
        path="/Umrah/Medical/Requests/:status"
        component={UmrahRequests}
      />
      <CheckRouter
        exact
        path="/Umrah/Medical/Requests/view/:id"
        component={ViewTravellers}
      />
      <CheckRouter
        exact
        path="/Umrah/Medical/Requests/view/:id/:status"
        component={ViewTravellers}
      />
      <CheckRouter exact path="/pilgrims/lated" component={PilgrimsLated} />
      <CheckRouter
        exact
        path="/CustomersList/orders"
        component={CustomersList}
      />
      <CheckRouter exact path="/CustomersList/progress" component={Progress} />
      <CheckRouter exact path="/CustomersList/checked" component={Checked} />
      <CheckRouter
        exact
        path="/CustomersList/view-result/:id"
        component={View}
      />
      <CheckRouter
        exact
        path="/CustomersList/view-operation/:id"
        component={ViewOPeration}
      />
      <CheckRouter
        exact
        path="/CustomersList/view-progress/:id"
        component={ViewProgress}
      />
      <CheckRouter
        exact
        path="/CustomersList/view-progress/result/:id"
        component={ViewResultProgress}
      />
      <CheckRouter
        exact
        path="/CustomersList/view-Cheched/:id"
        component={ViewCheched}
      />
      {/******************Start WorkTeam******************* */}
      {/* workTeam */}
      <CheckRouter exact path="/team-management/users" component={Users} />
      <CheckRouter
        exact
        path="/team-management/users/add"
        component={AddUser}
      />
      <CheckRouter
        exact
        path="/team-management/users/edit/:user_id"
        component={EditUser}
      />
      <CheckRouter exact path="/team-management/groups" component={Groups} />
      <CheckRouter
        exact
        path="/team-management/groups/add"
        component={AddGroup}
      />
      <CheckRouter
        exact
        path="/team-management/groups/edit/:group_id"
        component={EditGroup}
      />
      <CheckRouter exact path="/Seting" component={Setting} />
      {/* <CheckRouter
        exact
        path="/Payments"
        component={Payments}
      /> */}
      <CheckRouter exact path="/PaymentCheck" component={PaymentCheck} />
      <CheckRouter exact path="/servics" component={Services} />
      <CheckRouter exact path="/Company/view-result" component={View} />
      <CheckRouter exact path="/notification" component={NotificationCheck} />
      {/* ***********************End Check Me**************************************** */}
      {/* ***********************Start Admin***************************************** */}
      <AuthRoute exact path="/admin/login" component={LoginAdmin} />{" "}
      {/* AuthRoute */}
      {/********************** */}
      <AdminRouter
        exact
        path="/admin/PackagesRequests"
        component={PackagesRequests}
      />
      <AuthRoute path="/reset-password1" exact component={ResetPassword} />
      <AuthRoute
        path="/admin/forget-password"
        exact
        component={ForgetPassword}
      />
      {/************************ */}
      <AdminRouter exact path="/admin/notification" component={Notification} />
      <AdminRouter
        exact
        path="/admin/dashboard/DashboardCompanies"
        component={DashboardCompaniesAdmin}
      />
      <AdminRouter
        exact
        path="/admin/ComanyMedical"
        component={ComanyMedicalList}
      />
      <AdminRouter
        exact
        path="/admin/ComanyMedical-/:id"
        component={ViewComanyMedical}
      />
      {/* workTeam */}
      <AdminRouter
        exact
        path="/admin/team-management/users"
        component={WorkTeamAdmin}
      />
      <AdminRouter
        exact
        path="/admin/team-management/users/add"
        component={AddAdminUser}
      />
      <AdminRouter
        exact
        path="/admin/team-management/users/edit/:user_id"
        component={EditAdminUser}
      />
      <AdminRouter
        exact
        path="/admin/team-management/groups"
        component={AdminGroups}
      />
      <AdminRouter
        exact
        path="/admin/team-management/groups/add"
        component={AddAdminGroup}
      />
      <AdminRouter
        exact
        path="/admin/team-management/groups/edit/:group_id"
        component={EditAdminGroup}
      />
      {/* *********Start Admin Orders */}
      <AdminRouter
        exact
        path="/admin/ordersList/incoming/:id"
        component={OrdersList}
      />
      <AdminRouter
        exact
        path="/admin/ordersList/progress/:id"
        component={OrdersProgress}
      />
      <AdminRouter
        exact
        path="/admin/ordersList/checked/:id"
        component={OrdersChecked}
      />
      <AdminRouter
        exact
        path="/admin/ordersList/incoming/view/:id/order/:index"
        component={ViewIncoming}
      />
      <AdminRouter
        exact
        path="/admin/ordersList/progress/view/:id/order/:index"
        component={ViewProgressOrders}
      />
      <AdminRouter
        exact
        path="/admin/ordersList/checked/view/:id/order/:index"
        component={ViewCheckedOrders}
      />
      {/* *********End Admin Orders */}
      <AdminRouter exact path="/admin/payment/:id" component={payment} />
      <AdminRouter
        exact
        path="/admin/saudi/payment/:id"
        component={SaudiPayment}
      />
      <AdminRouter
        exact
        path="/admin/Umrah/Medical/Requests/:status/:id"
        component={AdminUmrahView}
      />
      <AdminRouter
        exact
        path="/admin/Umrah/Medical/packages/view/:id"
        component={ViewTravellersPackages}
      />
      <AdminRouter
        exact
        path="/admin/Umrah/Medical/Lated/view/:id"
        component={ViewTravellersLated}
      />
      <AdminRouter
        exact
        path="/admin/Umrah/Medical/Requests/view/:id/:traveler_id"
        component={ViewTravellersAdmin}
      />
      <AdminRouter
        exact
        path="/admin/Umrah/Medical/Requests/view/:id/:status/:traveler_id"
        component={ViewTravellersAdmin}
      />
      <AdminRouter
        exact
        path="/admin/saudi/Invoices/:id"
        component={Invoices}
      />
      <AdminRouter exact path="/admin/pilgrims/lated" component={LatedAdmin} />
      <AdminRouter
        exact
        path="/admin/:id/print/:package_id"
        component={PrintInvoice}
      />
      <AdminRouter exact path="/admin/users/:id" component={UsersAdmin} />
      {/* <AdminRouter
        exact
        path="/admin/ComanyMedical/add-user"
        component={AddUser}
      /> */}
      {/* <AdminRouter
        exact
        path="/admin/ComanyMedical/edit-user/:id"
        component={EditUser}
      /> */}
      <AdminRouter
        exact
        path="/admin/ComanyMedical-edit/:id"
        component={EditCompany}
      />
      <AdminRouter
        exact
        path="/admin/ComanyMedical/AddCompany"
        component={AddCompany}
      />
      <AuthRoute exact path="/" component={Login} />
    </Switch>
  </Suspense>
);
export default Routes;
