import React, { useEffect, useState } from "react";
import logoImg from "../../assets/images/Image1.png";
import Settings from "../../assets/images/settings.svg";
import Locale from "../../translations";
import UserSetting from "../UsetSetting/UserSetting";
import { NavLink, useHistory } from "react-router-dom";
import arLogo from "../../assets/images/ar-flag.svg";
import enLogo from "../../assets/images/en-flag.svg";
import { useLocation } from "react-router-dom";
import { useCompanyDispatch, useCompanyState } from "../../context/global";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ShowForPermission from "../../helpers/showForPermission";
import {
  allNotifications,
  permissionsReload,
  readNotification,
} from "../../services/admin";
function HeaderAdmin() {
  const { translate } = Locale;
  let history = useHistory();
  /** */
  let CompanyState = useCompanyState();
  const { pathname } = useLocation();
  const userType =
    localStorage.getItem("userType"); /*localStorage.getItem("userType") */
  const dispatch = useCompanyDispatch();
  const { currentLanguage } = useCompanyState();
  /*** */
  /******************************************/

  // const [perms, setPerms] = useState({});
  // useEffect(() => {
  //   async function fetchLookups() {
  //     const res = await permissionsReload();
  //     setPerms(res?.data);
  //   }
  //   fetchLookups();
  //   //localStorage.setItem("permissions",JSON.stringify(perms) );
  // }, []);

  // useEffect(() => {
  //   if (perms) {
  //     localStorage.setItem("permissions", JSON.stringify(perms.permissions));
  //     console.log("t10", perms.permissions);
  //     localStorage.setItem("userType", perms.user_type);
  //   }
  // }, [perms]);

  useEffect(() => {
    async function fetchLookups() {
      const res = await permissionsReload();
      dispatch({ type: "getPermissions", payload: res.data });
    }
    fetchLookups();
  }, []);

  /******************************************/

  /************Notification************ */
  const [notifyData, setNotifyData] = useState();
  useEffect(() => {
    async function fetchData() {
      const res = await allNotifications();
      setNotifyData(res?.data?.data);
    }
    fetchData();
  }, []);

  /****** */

  let notificationLength = notifyData?.filter((data) =>
    Object.keys(data).find((key) => data["read_at"] === null)
  );

  const readNotify = async (id1) => {
    const res = await readNotification(id1);
  };

  const [noty, setNoty] = useState(0);

  useEffect(() => {
    async function fetchLength() {
      const notificationLength = await notifyData?.filter((data) =>
        Object.keys(data).find((key) => data["read_at"] === null)
      );
      //setNoty(notificationLength);
    }
    fetchLength();
  }, []);

  console.log("noty", noty);
  /******* */
  let arr = [];
  let notification =
    notifyData?.length > 3
      ? (arr = notifyData.slice(0, 3))
      : (arr = notifyData);

  const AllNotification =
    arr?.length > 0 ? (
      arr.map((data, index) => (
        <DropdownItem
          tag="button"
          className={`d-flex justify-content-start flex-wrap py-2 border-top ${
            data.read_at == null ? "not-read text-primary" : "read"
          }`}
          onClick={() => {
            //setNoty(noty?.splice(index, 1));
            readNotify(data.id);
            setTimeout(() => {
              window.location.reload();
            }, 750);

            //history.push("/admin/notification");
            history.push(`${data.url}`);
          }}
        >
          {console.log("data--", data)}
          {currentLanguage == "en" ? (
            <h5>{data.title.slice(0, 25)}...</h5>
          ) : (
            <h5>{data.title.slice(0, 25)}...</h5>
          )}
          <br />
          <p>{data.body.slice(0, 29)}...</p>
        </DropdownItem>
      ))
    ) : (
      <DropdownItem tag="button" className="d-flex justify-content-center py-2">
        <p>No Notification</p>
      </DropdownItem>
    );

  return (
    <>
      <div className="header-container ">
        <header className="header  text-white">
          <p className=" container h4 mb-0 ">Admin</p>
        </header>
        <div className="w-100 bg-white pt-2">
          <div className="container p-0 ">
            <div className="d-flex justify-content-between align-items-center">
              <figure className="logo">
                <img className="w-100" src={logoImg} alt="logo" />
              </figure>
              <div className="header-icons d-flex  justify-content-end">
                {/* <UserSetting />  */}

                {/***************************  Start Notification   **************************************************** */}
                <UncontrolledDropdown
                  setActiveFromChild
                  className="notification "
                >
                  <DropdownToggle
                    tag="button"
                    className="h5 text-primary d-flex align-items-center"
                    //caret
                  >
                    <i class="fas fa-bell notify-icon">
                      <span className="notify-number">
                        {" "}
                        {/* {noty?.length} */}
                        {notificationLength?.length}
                      </span>
                    </i>
                    {translate.notification}
                  </DropdownToggle>

                  <DropdownMenu className="bg-notify no-padding ">
                    {AllNotification}
                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        history.push("/admin/notification");
                      }}
                      className="d-flex justify-content-center w-100 notify-show-more py-2"
                    >
                      <h6> {translate.showMore} </h6>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/***************************  End Notification   **************************************************** */}

                <UncontrolledDropdown setActiveFromChild>
                  <DropdownToggle
                    tag="button"
                    className="h5 text-primary d-flex align-items-center"
                    caret
                  >
                    {/* <img src={Settings} className="Header-Setting-img" alt="Settings" /> */}
                    <i className="fas fa-cog fa-lg  mx-2 text-primary "></i>
                    {"  "}
                    <span className="Header-Setting-title">
                      {translate.setting}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="bg-notify  no-padding">
                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        dispatch({ type: "setLocale", payload: "ar" });
                      }}
                      className="d-flex justify-content-start py-2"
                    >
                      <img
                        className="mx-1 px-2"
                        src={arLogo}
                        width="40"
                        alt="lang"
                      />
                      <span style={{ dispaly: "inline-block", width: "25px" }}>
                        العربية
                      </span>
                    </DropdownItem>

                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        dispatch({ type: "setLocale", payload: "en" });
                      }}
                      className="d-flex justify-content-start  py-2"
                    >
                      <img
                        className="mx-1 px-2"
                        src={enLogo}
                        width="40"
                        alt="lang"
                      />
                      <span style={{ dispaly: "inline-block", width: "25px" }}>
                        English
                      </span>
                    </DropdownItem>

                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        dispatch({ type: "logout", payload: "logout" });
                      }}
                      className="text-right d-flex justify-content-center  py-2"
                    >
                      {translate.logout}
                      <i
                        class="fas fa-sign-out-alt mx-2"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>

        <nav className="header ">
          <div className="d-flex justify-content-between align-items-stretch  container p-0 ">
            <div className="d-flex">
              <NavLink
                exact={true}
                to="/admin/dashboard/DashboardCompanies"
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.MainMenu}
              </NavLink>

              <ShowForPermission permission={"list-companies"}>
                <NavLink
                  exact={true}
                  to="/admin/ComanyMedical"
                  activeClassName="is-active"
                  className="mr-4 py-2  text-bold"
                >
                  {translate.ComanyMedical}
                </NavLink>
              </ShowForPermission>
              <ShowForPermission permission={"list-companies"}>
                <NavLink
                  exact={true}
                  to="/admin/PackagesRequests"
                  activeClassName="is-active"
                  className="mr-4 py-2  text-bold"
                >
                  توزيع الطلبات الوارده
                </NavLink>
              </ShowForPermission>

              <NavLink
                exact={true}
                to="/admin/pilgrims/lated"
                activeClassName="is-active"
                isActive={() => pathname.includes("admin/pilgrims/lated")}
                className="mr-4 py-2  text-bold"
              >
                {translate.pilgrimsLated}
              </NavLink>

              <ShowForPermission permission={["list-groups", "list-admins"]}>
                {/*CompanyState.isAdmin==true || */}

                <NavLink
                  exact={true}
                  to="/admin/team-management/groups"
                  activeClassName="is-active"
                  className="mr-4 py-2 text-bold"
                >
                  {translate.teamManagement}
                </NavLink>
              </ShowForPermission>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
export default HeaderAdmin;
