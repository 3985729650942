import React from "react";
//import SelectField from "../../components/shared/SelectField";
import TextField from "../../../../components/shared/textField";
import Locale from "../../../../translations";
import SelectField from "../../../../components/shared/SelectField";


export default function UsersFilter() {
  const { translate } = Locale;
  return (
    <>
    <div className="row no-gutters list_header py-3 px-2 comany-medical-filter" >
      <div className="col-2 mr-2 ">
        <TextField
          hasLabel={false}
          style={{height: "36px !important"}}
          label={translate.search}
          type={"text"}
          placeholder={translate.search}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      
      <div className="col-2 mr-2  ">
        <SelectField
          hasLabel={false}
          onFocus={true}
          placeholder={translate.status}
        />
      </div>

      <div className="col  pt-1" >
        <button className="btn btn-primary px-5 py-2 font-weight-bold">
          {translate.search}
        </button>
        <button className="btn text-muted font-weight-bold ">
          {" "}
           <i className="fas fa-retweet"></i>{translate.reset}
        </button>
      </div>
      
    </div>


   
</>
);
}
