import React, { useState } from "react";
import Locale from "../../../translations";
//import { paymentListShow } from "../../../../services/admin";
import {
	Modal,
	ModalBody,
} from "reactstrap";
import { paymentListShow } from "../../../services/yarvel";
import ShowForPermission from "../../../helpers/showForPermission";

export default function PaymentList({ paymentLists }) {
	//const { Labs } = Locale;
	const { translate } = Locale;

	const [modalShow, setModalShow] = useState(false);

	const [paymentDetails, setPaymentDetails] = useState();
	async function fetchDataDetails() {
		const res = await paymentListShow(paymentLists.id);
		console.log(res);
		setModalShow(!modalShow);
		setPaymentDetails(res?.data?.data.payment_details);
	}

	const toggleModalShow = () => {
		setModalShow(!modalShow);
	};

	const getPaymentType = (type) => {
		let paymentTypeReturned = '';
		switch (type) {
			case 'CASH':
				paymentTypeReturned = translate.cash;
				break;
			case 'BANK_CHECK':
				paymentTypeReturned = translate.bank_check;
				break;
			case 'BANK_TRANSACTION':
				paymentTypeReturned = translate.bank_transaction;
				break;
			default:
				paymentTypeReturned = '';
				break;
		}
		return paymentTypeReturned;
	}

	
	return (
		<>
			<tr>
				<td>{paymentLists?.code}</td>
				<td>{paymentLists?.amount} {translate.Pound}</td>
				<td>{paymentLists?.created_at}</td>

				<td> {getPaymentType(paymentLists.payment_type)}</td>
				
				<td className="PaymentList-info">
					{paymentLists?.created_by_name}
					
					<ShowForPermission permission={"company:view-settlement"} >
					{paymentLists.payment_type !== 'CASH'
						? <i className="fa fa-info-circle PaymentList-infoIcon"
							aria-hidden="true"
							onClick={() => {
								fetchDataDetails();
							}}></i>
						: null}
						</ShowForPermission>
				</td>
				
			</tr>

			{/**********************Model**************************** */}
			<Modal className="model-order1" isOpen={modalShow} toggle={() => { toggleModalShow(null) }}>
				<div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
					<p className="h3 text-secondary ">{translate.transactionCode} <span>{paymentLists?.code}</span></p>
					<i
						className="far fa-times-circle fa-lg text-secondary "
						onClick={() => {
							toggleModalShow(null);
						}}
					></i>
				</div>
				<ModalBody className="lan  mx-5 my-1 p-0  ">
					<div className="mt-2" >
						<div className=" p-3">
							<div className="row  text-right">
								{paymentLists.payment_type === "BANK_CHECK"
									? <>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.bankName}</p>
											<p className="paymentList-Title">{paymentDetails?.bank_name}</p>
										</div>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.accountName}</p>
											<p className="paymentList-Title">{paymentDetails?.account_owner_name}</p>
										</div>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.accountNumber}</p>
											<p className="paymentList-Title">{paymentDetails?.bank_check_number}</p>
										</div>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.DateOfTravel}</p>
											<p className="paymentList-Title">{paymentDetails?.check_date}</p>
										</div>
									</>
									:
									<>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.bankName}</p>
											<p className="paymentList-Title">{paymentDetails?.bank_name}</p>
										</div>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.accountName}</p>
											<p className="paymentList-Title">{paymentDetails?.account_owner_name}</p>
										</div>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.accountNumber}</p>
											<p className="paymentList-Title">{paymentDetails?.account_number}</p>
										</div>
										<div className="col-6 my-2">
											<p className="paymentList-headTitle">{translate.operationNumber}</p>
											<p className="paymentList-Title">{paymentDetails?.transaction_number}</p>
										</div>
									</>
								}

							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		
		</>
	);
}
