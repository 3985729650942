import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";

import Locale from "../../../translations";
import PaymentFilter from "./paymentFilter";
import PaymentList from "./paymentList";
import PaymentCost from "./paymentCost";
import {  useParams } from "react-router";

import Pagination from "../../../components/shared/Pagination";
import { paymentList, paymentNumber } from "../../../services/yarvel";

export default function Payment() {
  const { translate } = Locale;
  let { id } = useParams();
  /*****model******* */

  /** */
  const [filter, setfilter] = useState({
    search: "",
    payment_type:"",
    page:1
    
  });
  const [searching, setSearching] = useState(false);
  const [pageing, setPageing] = useState(false);
  const submit = () => {
    setSearching(!searching);
  };
  const emptyInputs = () => {
    setSearching(!searching);
    setfilter({search: "",payment_type:"",})
  };
  const goTo = async (page) => {
    setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    setPageing(!pageing)
  };
  const [paymentNumbers, setPaymentNumbers] = useState()
  const [paymentLists, setPaymentLists] = useState()
  const [meta, setMeta] = useState([]);
      useEffect(() => {
        async function fetchDataNaumber() {
          const res = await paymentNumber(id);
          setPaymentNumbers(res?.data?.data);
        }

        async function fetchDataList() {
          const res = await paymentList(id,filter.page,{...filter , payment_type : filter.payment_type?.title});
          setPaymentLists(res?.data?.data );
          setMeta(res?.data?.meta)
        }
        fetchDataNaumber();
        fetchDataList();
      }, [searching,pageing]);//filter
      
      useEffect(() => {
        async function fetchDataNaumber() {
          const res = await paymentNumber(id);
          setPaymentNumbers(res?.data?.data);
        }
        async function fetchDataList() {
          const res = await paymentList(id,filter.page,{...filter , payment_type : filter.payment_type?.title});
          setPaymentLists(res?.data?.data );
          setMeta(res?.data?.meta)
        }
        fetchDataNaumber();
        fetchDataList();
      }, [searching]);

      const dataList =
      paymentLists && paymentLists.length > 0
        ? paymentLists.map((list, index) => <PaymentList paymentLists={list} index={index} />)
        : "";
    
  /** */

  return (
    <div className="container p-0 payments">

      <PaymentCost paymentNumbers={paymentNumbers} />
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.Payments}</h3>
        </div>
      <PaymentFilter submit={submit} emptyInputs={emptyInputs} filter={filter} setfilter={setfilter} />
      <div className=" p-0">
        <Table className="table-responsive-sm ">
          <thead>
            <tr className="text-grey">
              <th>{translate.transactionCode}</th>
              <th> {translate.amount}</th>
              <th>{translate.date}</th>
              <th>{translate.transactionType}</th>
              <th>{translate.employeeName}</th>
            </tr>
          </thead>
          <tbody className="table-stripe ">{dataList}</tbody>

          {dataList?.length > 0 ? (
												""
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
        </Table>
        <Pagination info={meta} goTo={goTo} items={10} />  
        <div></div>
      </div>

      {/**********************Model**************************** */}
     
    </div>
  );
}
