import userImage from "../../../assets/images/dashboard/user-img.svg";
import React from "react";
import Locale from "../../../translations";


export default function Counts({number}) {
	const { translate } = Locale;
	return (
		<>
			
			<div className="count-box bg-white mb-2 p-3 rounded shadow-sm">
				<h6 className="title">{translate.totalTeamWork}</h6>
				<div className="d-flex justify-content-between align-items-center">
					<div className="img-box">
						<img src={userImage} alt="branchImage" />
					</div>
					<div className="number">{number?.users}</div>
				</div>
			</div>
		</>
	);
}
