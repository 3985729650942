import React, { useEffect,useState } from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";
import UserNumber from "./userNumber";
import Filter from "./filter";
import { Link, NavLink } from "react-router-dom";
import Users from "./users";
import ShowForPermission from "../../../helpers/showForPermission";
import {
  listGroupsDp,
  ListUser,
} from "../../../services/WorkTeamTenant";
function WorkTeam() {
  const { translate } = Locale;
  let history = useHistory();
  const [users, setUsers] = useState([]);
  const [searching, setSearching] = useState(false);

  const submit = () => {
    setSearching(!searching);
  };
  console.log("searchingsearchingsearchingsearching", searching);
  const countOfUsers = (val) => {
    setUsers(val);
    submit();
  };

  /**api */
  const [filter, setfilter] = useState({
    name: "",
    status: "",
  });

  //ListUser
/************************************** */
const [data, setData] = useState()

useEffect(() => {
  
  async function fetchUserData() {
    const User = await ListUser();
    setData(User?.data.data);
  }

  fetchUserData();  //for permissions


}, []);
//setData(users)


console.log("data",data);
  return (
    <section className="teams">
      <UserNumber users={users} />
      <div className="container p-0">
        <div className="d-flex justify-content-between mt-5 mb-2 head">
          <h3 className="text-muted font-weight-bold">
            {translate.teamManagement}
          </h3>

          <ShowForPermission permission={"company:create-user"}>
            <NavLink
              exact={true}
              to={`/team-management/users/add`}
              activeClassName="is-active"
            >
              <button
                type="button"
                className="btn btn-success px-4 py-2 font-weight-bold "
                onClick={() => {
                  history.push("/team-management/users/add");
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>
                {translate.addUser}
              </button>
            </NavLink>
          </ShowForPermission>
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <Filter
            filter={filter}
            setfilter={setfilter}
            users={users}
            setUsers={setUsers}
            searching={searching}
            setSearching={setSearching}
          />
          <div className="d-flex navLink">
            <ShowForPermission permission={"company:list-users"}>
              <NavLink
                exact={true}
                //to={`/team-management/users`}
                to={{pathname:'/team-management/users',  state: {data:data } }}

                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.users}
              </NavLink>
            </ShowForPermission>

            <ShowForPermission permission={"company:list-groups"}>
              <NavLink
                exact={true}
                //to='/team-management/groups'
                to={{pathname:'/team-management/groups',  state: {data:data} }}
                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.groups}
              </NavLink>
            </ShowForPermission>
          </div>
        </header>
        <Users
          countUsers={(val) => countOfUsers(val)}
          searching={searching}
          setSearching={setSearching}
          filter={filter}
          setfilter={setfilter}
          submit={submit}
        />
      </div>
    </section>
  );
}

export default WorkTeam;
