import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useCompanyState } from "../context/global";

// React Component
export function AuthRoute({ component: Component, ...props }) {
	let CompanyState  = useCompanyState();

	if (!CompanyState.isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<Component {...matchProps} />
				)}
			/>
		);
	}else{
		if (!CompanyState.isAdmin) {
			return <Redirect to="/CustomersList/dashboard/DashboardCompanies" />;
		}else{
			return <Redirect to="/admin/dashboard/DashboardCompanies" />;
		}
	}

}
