import moment from "moment";
import React from "react";
import { useCompanyState } from "../../../../context/global";
import Locale from "../../../../translations";

export default function Payment({ payment }) {
  const { currentLanguage } = useCompanyState();
  const { translate } = Locale;

  return (
    <tr>
      <td>
        {" "}
        {payment?.company_name[currentLanguage] != undefined
          ? payment?.company_name[currentLanguage]
          : payment?.company_name.ar}
      </td>
      <td>{payment?.company_code}</td>
      <td>{payment?.travellers}</td>
      <td>{moment(payment?.return_date).format("YYYY-MM-DD")}</td>
      <td>{moment(payment?.departure_date).format("YYYY-MM-DD")}</td>
      <td>{payment?.hotel?.name}</td>
      <td
        className={
          payment?.payment_status === "paid" ||
          payment?.payment_status === "مدفوع"
            ? "text-success"
            : payment?.payment_status === null
            ? "text-danger"
            : "text-warning"
        }
      >
        {payment?.payment_status ? payment.payment_status : translate.unpaid}
      </td>
    </tr>
  );
}
