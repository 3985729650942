import React from "react";
import SelectField from "../../../../components/shared/SelectField";
import TextField from "../../../../components/shared/textField";
//import TextField from "../../../../components/shared/textField";
import Locale from "../../../../translations";


export default function PaymentFilter({ submit,emptyInputs, filter, setfilter }) {
  const { translate } = Locale;
  const status = [
    { value: 0,  label: translate.cash,             title: 'cash'            },
    { value: 1,  label: translate.bank_check ,      title: 'bank_check'      },
    { value: 2,  label: translate.bank_transaction, title: 'bank_transaction'}
  ];
  return (
    <>
    <div className="row no-gutters list_header py-3 px-2 comany-medical-filter" >
      <div className="col-2 mr-2 ">
        <TextField
          hasLabel={false}
         
          label={translate.search}
          type={"text"}
          placeholder={translate.search}
          onChange={(e) => {
            setfilter({
              ...filter,
              search: e.target.value,
            });
          }}
          value={filter?.search}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      
      <div className="col-2 mr-2  ">
        <SelectField
          hasLabel={false}
          onFocus={true}
          placeholder={translate.transactionType}
          options={status}
          value={filter?.payment_type}
          onChange={(e) => {
            setfilter({
              ...filter,
              payment_type: e,
            });
          }}
        />
      </div>

      <div className="col  pt-1" >
        <button className="btn btn-primary px-5 py-2 font-weight-bold"
        onClick={submit}>
          {translate.search}
        </button>
        <button className="btn text-muted font-weight-bold "
        onClick={() =>
          // setfilter({
          //   search: "",
          //   payment_type: "",
          // })
          emptyInputs()
        }>
          {" "}
           <i className="fas fa-retweet"></i>{translate.reset}
        </button>
      </div>
      
    </div>
   
</>
);
}
