import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import { useHistory, useLocation } from "react-router-dom";

import FilterGroup from "./filterGroup";
import { NavLink } from "react-router-dom";
import Collaps from "./Collapse";
import UserNumber from "./userNumber";
import { ListGroups, ListUser } from "../../../services/WorkTeamAdmin";
import { ListGroupsFilter } from "../../../services/WorkTeamAdmin";
import Pagination from "../../../components/shared/Pagination";
import ShowForPermission from "../../../helpers/showForPermission";
import { useCompanyState } from "../../../context/global";

export default function Groups() {
  let location = useLocation();
  const users=location.state?.data
  console.log("data1",users);

  const { translate } = Locale;
  let history = useHistory();
  const [Groups, setGroups] = useState([]);
  //const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState([]);
 
  const [filter, setfilter] = useState({
    search: "",
    page: 1,
  });

  useEffect(() => {
    async function fetchData() {
      const User = await ListGroupsFilter(filter.page, {
        ...filter,
      });
      setGroups(User?.data);
      setMeta(User?.data?.meta);
    }
    fetchData();
    async function fetchUserData() {
      const User = await ListUser();
      //setUsers(User?.data.data);   // becouse not call api users in groups
    }
  
    //fetchUserData();  //for permissions


  }, [filter]);
  console.log("meta*94  *", meta);
  const goTo = async (page) => {
    setfilter({ ...filter, page: page }); //page.length < 3 ? Number(page):page
  };
  const AllGroups =
    Groups && Groups?.data?.length > 0
      ? Groups?.data?.map((Group, index) => (
          <Collaps Group={Group} index={index} />
        ))
      : "";
      //console.log("state={{ from: 'occupation' }}::",users)
  return (
    <section>
      <UserNumber users={users} />  {/*users={users} */}
      <div className="container p-0">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">
            {translate.teamManagement}
          </h3>

          <ShowForPermission permission={"create-groups"}>
            <NavLink
              exact={true}
              to={`/admin/team-management/groups/add`}
              activeClassName="is-active"
            >
              <button
                type="button"
                className="btn btn-success px-4 py-2 font-weight-bold "
                onClick={() => {
                  history.push("/admin/team-management/groups");//Company/Add-Traveler
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>
                {translate.addGroup}
              </button>
            </NavLink>
          </ShowForPermission>
        </div>
        <header className="d-flex justify-content-between align-items-center list_header border py-3 px-2  ">
          <FilterGroup filter={filter} setfilter={setfilter} />
          <div>

            <ShowForPermission permission={"list-admins"}>
              <NavLink
                exact={true}
                to={`/admin/team-management/users`}
                activeClassName="is-active"
                className="btn-nav  mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.users}
              </NavLink>
            </ShowForPermission>


            <ShowForPermission permission={"list-groups"}>
              <NavLink
                exact={true}
                to={`/admin/team-management/groups`}
                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.groups}
              </NavLink>
            </ShowForPermission>
          </div>
        </header>

        <div className="Collapse">
          {/* {AllGroups} */}

          {AllGroups.length > 0 ? (
            AllGroups
          ) : (
            <>
              <div className="text-center my-5">
                <div className="product-build__product-no-data">
                  <i className="fas fa-info-circle fa-lg"></i>{" "}
                  <h4>{translate.noResult}</h4>
                </div>
              </div>
            </>
          )}
        </div>

        <Pagination info={meta} goTo={goTo} items={10} />
      </div>
    </section>
  );
}
