import React from "react";
import Locale from "../../../translations";

export default function UserNumber(props) {
  const { translate } = Locale;
  console.log(" props.users?", props.users);
  const userCount = props.users? props.users?.length : 0;

  const activationUsers = props.users?.filter((user) => {
    return user.status === "active";
  }).length;

  const inactivateUsers = props.users?.filter((user) => {
    return user.status === "inactive";
  }).length;

  return (
    <div className="container mt-5 p-0">
      <div className="d-flex">
        <div className="box">
          {userCount} <i class="fas fa-circle"></i> {translate.userCount}
        </div>

        <div className="box mx-5">
          <span className="text-success">
            {" "}
            {activationUsers} <i class="fas fa-circle"></i>
          </span>{" "}
          {translate.activationUsers}
        </div>

        <div className="box">
          <span className="text-danger">
            {" "}
            {inactivateUsers} <i class="fas fa-circle"></i>{" "}
          </span>{" "}
          {translate.inactivateUsers}
        </div>
      </div>
    </div>
  );
}
