import React from "react";
import Locale from "../../../translations";

export default function TravelersNumber() {
	const { translate } = Locale;
	
	return <>
	<div> {translate.Customers}</div>
	
	
	</>;
}
