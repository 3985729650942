import "@fortawesome/fontawesome-free/css/all.min.css";
import "./scss/index.scss";
import { useCompanyState } from "./context/global";
import Locale from "./translations";
import React from "react";
//import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/layout";
import Routes from "./routes";
import ReactNotification from "react-notifications-component";
import "animate.css/animate.min.css";
import "react-notifications-component/dist/theme.css";
import AxiosConfig from "./services/axios-config";
import Loader from "./components/Loader";

function App() {
  // Add a request interceptor
  const token = localStorage.getItem("token");
  const { currentLanguage,loading } = useCompanyState();
  AxiosConfig();


  // return context and Destructuring  currentLanguage from it

  Locale.setLanguage(currentLanguage);

  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", currentLanguage === "ar" ? "rtl" : "ltr");

  return (
    <div
      style={{
        textAlign: currentLanguage === "ar" ? "right" : "left",
        fontFamily: currentLanguage === "ar" ? "Cairo" : "Roboto",
      }}
    >
      			{loading && <Loader />}

      <ReactNotification />
     {Routes}
    </div>
  );
}

export default App;
