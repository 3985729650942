//import userImage from "../../assets/images/dashboard/user-img.svg";
import React from "react";
import Locale from "../../../translations";


export default function Counts({users,companies}) {
	const { translate } = Locale;
	const style={
		color:'#24AEFB',
		fontSize:'50px'

	}
	return (
		<>
			
			

			<div className="count-box bg-white mb-2 p-3 rounded shadow-sm">
				<h6 className="title">{translate.totalComanyMedical}</h6>
				<div className="d-flex justify-content-between align-items-center my-4">
					<div className="img-box">
					<i class="fas fa-laptop-medical" style={style}></i>
					</div>
					<div className="number">{companies}</div>
				</div>
			</div>
		</>
	);
}
