import React from "react";
import { useHistory } from "react-router";
import ShowForPermission from "../../../../helpers/showForPermission";
import Locale from "../../../../translations";

function TravelerProgress({ employee }) {
  let history = useHistory();
  const { translate } = Locale;

  //console.log("traveler",employee)

  return (
    <>
      <tr>
        <td>{employee.reservation_no}</td>
        <td>{employee.test_type}</td>
        <td>
          {employee?.traveller_name?.ar} <br />
          {employee?.traveller_name?.en}
        </td>
        <td> {employee.reservation_type}</td>
        <td>
          <div className="d-flex flex-column">
            <span>{employee.check_date?.slice(0, 10)} </span>
            <span>{employee.check_date?.slice(11, 16)}</span>
          </div>
        </td>
        {/* <td>2</td>
        <td>950 ج.م</td> */}
        <td>{employee.operationOrder_employee} </td>
        {/* <td>{employee.remain_cost}</td> */}
        <td>
          <div className="d-flex flex-column">
            <span>{employee.request_date.slice(0, 10)} </span>
            <span>{employee.request_date.slice(11, 16)}</span>
          </div>
        </td>

        <td>
          <div className="d-flex flex-column">
            <span>{employee.operationOrder_created_at.slice(0, 10)} </span>
            <span>{employee.operationOrder_created_at.slice(11, 16)}</span>
          </div>
        </td>
        <td className="d-flex">
          <ShowForPermission permission={"company:view-traveller"}>
            <button
              className="text-primary btn-link font-weight-bold mr-3"
              onClick={() => {
                history.push(
                  `/CustomersList/view-progress/result/${employee.id}`
                );
              }}
              style={{width:"70px"}}
            >
              <i class="fas fa-eye" style={{ fontSize: "16px" }}></i>{" "}
              {translate.view}
            </button>
          </ShowForPermission>
          <ShowForPermission permission={"company:submit-traveller-result"}>
            <button
              className="text-primary btn-link font-weight-bold submit-traveller-result"
              onClick={() => {
                history.push(`/CustomersList/view-progress/${employee.id}`);
              }}
              style={{width:"105px"}}

            >
              <i class="fas fa-share" style={{ fontSize: "16px" }}></i>{" "}
              {translate.enterResult}
            </button>
          </ShowForPermission>
        </td>
      </tr>
    </>
  );
}

export default TravelerProgress;
