import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logoImg from "../../../assets/images/Image1.png";
import Checkbox from "../../../components/shared/checkbox";
import TextField from "../../../components/shared/textField";
import { useCompanyDispatch } from "../../../context/global";
import { singin } from "../../../services/admin";
import Locale from "../../../translations";

export default function LoginAdmin() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  let location = useHistory();

  const { translate } = Locale;
  const dispatch = useCompanyDispatch();

 
  const login = async () => {
   
    const res = await singin(user);

    console.log(res);
    if (res?.status === 200) {
      
      dispatch({ type: "login", payload: {...res?.data?.data,isAdmin:true} });
      location.push("/admin/dashboard/DashboardCompanies");
    }
 
    console.log("user",user);
  };

  return (
    <div className="d-flex justify-content-center align-items-center auth-bg" style={{height:"100vh"}}>
      <figure className="logo w-25">
        <img className="w-100" src={logoImg} alt="logo" />
      </figure>

      <div className="d-flex flex-column ml-5 w-25">
        <h2 className="text-center" style={{fontFamily:"Cairo",color:"#6F709F",fontSize:"33px"}}>{translate.MedicalCompanyLogin}</h2>
        <div className="mt-4">
          <TextField
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          >
            <i className="fas fa-user text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <TextField
            value={user.password}
            type="password"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          >
            <i className="fas fa-lock text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <Checkbox
            hasLabel={true}
            className="radio_bnt"
            label={"Remember me"}
          />
        </div>
        <button className="btn btn-primary py-3 mt-4" onClick={()=>login()}>
          {translate.signIn}
        </button>
        <Link to="/reset-password1" className="btn-link text-center mt-3">
          {translate.ForgotPassword}
        </Link>
      </div>
    </div>
  );
}
