import axios from "axios";
import { cleanEmpty } from "./general";

const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");
const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

//export const SafarnaURL = `https://${domain}/api`;
const CheckURL = `https://${subdomain}.${domain}/api/tenant`;
/* export const CheckURL = `http://${domain}/api/tenant`;
 */

const organizationCountry = localStorage.getItem("organizationCountry");




export const singin = async (data) => {
	
  return await axios
    .post(`${CheckURL}/auth/login`, data)
    .then((res) => res)
    .catch((err) => err.response);
};



///******************Traveller************************* */
export const listTravellers = async (filter) => {
		return await axios
		.get(`${CheckURL}/labs/travellers`)
		.then((res) => res)
		.catch((err) => err.response);

};


export const listTravellersFilter = async (filter) => {
	return await axios
	.get(`${CheckURL}/labs/travellers`,{
		params: cleanEmpty(filter)
	  })
	.then((res) => res)
	.catch((err) => err.response);

};




export const listEmployees = async () => {
	return await axios
		.get(`${CheckURL}/labs/travellers/employees`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listTravellersById = async (id) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPendingTravellers= async () => {
	return await axios
		.get(`${CheckURL}/labs/travellers/pending?name=&departure_date&check_date`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPendingTravellersFilter= async (filter) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/pending`,{
			params: cleanEmpty(filter)
		  })
		.then((res) => res)
		.catch((err) => err.response);
};

export const listDoneTravellers= async (page=1) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/checked?name=&departure_date&check_date&result&result_date?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listDoneTravellersFilter= async (filter) => {
	return await axios
		.get(`${CheckURL}/labs/travellers/checked`,{
			params: cleanEmpty(filter)
		  })
		.then((res) => res)
		.catch((err) => err.response);
};


export const createOperationOrder= async (data) => {
	return await axios
		.post(`${CheckURL}/labs/travellers/order`,data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const createResultProgress= async (id,data) => {
	return await axios
		.post(`${CheckURL}/labs/travellers/${id}`,data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const showDataSettings= async () => {
	return await axios
		.get(`${CheckURL}/labs/me`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editSettings = async (data) => {
	return await axios
		.put(`${CheckURL}/labs/me`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const serviceList= async () => {
	return await axios
		.get(`${CheckURL}/companies/services`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const editServices = async (id) => {
	return await axios
		.put(`${CheckURL}/companies/services/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

///{{tenant_domain}}/api/tenant/labs/dashboard
export const dashboardList= async () => {
	return await axios
		.get(`${CheckURL}/labs/dashboard`)
		.then((res) => res)
		.catch((err) => err.response);
};
///lookups/cities
export const cities= async () => {
	return await axios
    .get(`${CheckURL}/lookups/cities?country_id=${organizationCountry}`)
    .then((res) => res)
    .catch((err) => err.response);
};
///lookups/areas
export const areas= async (id) => {
	return await axios
		.get(`${CheckURL}/lookups/areas?city_id=`+id)
		.then((res) => res)
		.catch((err) => err.response);
};

//api/tenant/auth/me
export const permissionsReload= async () => {
	return await axios
		.get(`${CheckURL}/auth/me`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const paymentNumber = async (id) => {
	
	return await axios
		.get(`${CheckURL}/companies/settlements-numbers`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const paymentList = async (id,page=1,filter) => {
	return await axios
		.get(`${CheckURL}/companies/settlements-logs`,{
			params: cleanEmpty(filter)
		  })
		.then((res) => res)
		.catch((err) => err.response);
};
//companies/settlements/show/5
export const paymentListShow = async (id) => {
	return await axios
		.get(`${CheckURL}/companies/settlements/show/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
//forgetPassword
export const forgetPassword = async (data) => {
	return await axios
		.post(`${CheckURL}/auth/forget-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
//resetPassword

/*** */
export const resetPassword = async (data) => {
	return await axios
		.post(`${CheckURL}/auth/reset-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changePassword = async (data) => {
	return await axios
		.post(`${CheckURL}/auth/password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const allNotifications= async (page) => {
	return await axios
		.get(`${CheckURL}/notifications?page= ${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const readNotification= async (id) => {
	return await axios
		.get(`${CheckURL}/read-notification/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};