import React, { useEffect, useState } from "react";
import logoImg from "../../assets/images/Image1.png";

import { useCompanyDispatch, useCompanyState } from "../../context/global";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ShowForPermission from "../../helpers/showForPermission";

import {
  allNotifications,
  permissionsReload,
  readNotification,
} from "../../services/yarvel";

import arLogo from "../../assets/images/ar-flag.svg";
import enLogo from "../../assets/images/en-flag.svg";
import { Box, Button, Drawer, List } from "@material-ui/core";
import Locale from "../../translations";

export default function MobileHeader() {
  const { translate } = Locale;
  let history = useHistory();
  const dispatch = useCompanyDispatch();
  const { currentLanguage, enabledModules } = useCompanyState();
  const { pathname } = useLocation();

  let CompanyState = useCompanyState();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [notifyData, setNotifyData] = useState();
  useEffect(() => {
    async function fetchData() {
      const res = await allNotifications();
      setNotifyData(res?.data?.data);
    }
    fetchData();
  }, []);

  const readNotify = async (id1) => {
    const res = await readNotification(id1);
  };
  let notificationLength = notifyData?.filter((data) =>
    Object.keys(data).find((key) => data["read_at"] === null)
  );
  let arr = [];
  let notification =
    notifyData?.length > 3
      ? (arr = notifyData.slice(0, 3))
      : (arr = notifyData);
  const AllNotification =
    arr?.length > 0 ? (
      arr.map((data, index) => (
        <DropdownItem
          tag="button"
          className={`d-flex justify-content-start flex-wrap py-2 border-top DropdownItem ${
            data.read_at == null ? "not-read text-primary" : "read"
          }`}
          onClick={() => {
            readNotify(data.id);
            setTimeout(() => {
              window.location.reload();
            }, 750);
            //history.push("/notification");
            history.push(`${data.url}`);
          }}
        >
          {currentLanguage == "en" ? (
            <h5>{data?.title.slice(0, 25)}...</h5>
          ) : (
            <h5>{data?.title.slice(0, 25)}...</h5>
          )}

          <br />
          <p>{data.body.slice(0, 29)}....</p>
        </DropdownItem>
      ))
    ) : (
      <DropdownItem tag="button" className="d-flex justify-content-center py-2">
        <p>No Notification</p>
      </DropdownItem>
    );
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="header border-0 MobileHeader h-100"
    >
      <List className="lan text-center">
        {" "}
        <NavLink
          exact={true}
          to="/CustomersList/dashboard/DashboardCompanies"
          activeClassName="is-active"
          className="mr-4 py-2 text-bold"
        >
          {translate.MainMenu}
        </NavLink>
      </List>
      <List className="lan text-center">
        {" "}
        {enabledModules?.includes("saudi-pcr") ? (
          <ShowForPermission permission={"company:list-travellers"}>
            <NavLink
              exact={true}
              to="/Umrah/Medical/Requests/orders"
              activeClassName="is-active"
              isActive={() => pathname.includes("/Umrah/Medical/Requests/")}
              className="mr-4 py-2  text-bold"
            >
              {translate.ComanyMedicalRequest}
            </NavLink>
          </ShowForPermission>
        ) : (
          <ShowForPermission permission={"company:list-travellers"}>
            <NavLink
              exact={true}
              to="/CustomersList/orders"
              activeClassName="is-active"
              className="mr-4 py-2  text-bold"
            >
              {translate.ComanyMedicalRequest}
            </NavLink>
          </ShowForPermission>
        )}
      </List>
      <List className="lan text-center">
        <ShowForPermission permission={"company:settings"}>
          <NavLink
            exact={true}
            to="/seting"
            activeClassName="is-active"
            className="mr-4 py-2 text-bold"
          >
            {translate.setting}
          </NavLink>
        </ShowForPermission>
      </List>
      <List className="lan text-center">
        {" "}
        <ShowForPermission permission={"company:list-settlements"}>
          <NavLink
            exact={true}
            to="/PaymentCheck"
            activeClassName="is-active"
            className="mr-4 py-2 text-bold"
          >
            {translate.Payments}
          </NavLink>
        </ShowForPermission>
      </List>
      <List className="lan text-center">
        {" "}
        <ShowForPermission permission={"company:list-settlements"}>
          <NavLink
            exact={true}
            to="/Servics"
            activeClassName="is-active"
            className="mr-4 py-2 text-bold"
          >
            {translate.services}
          </NavLink>
        </ShowForPermission>
      </List>

      <List className="lan text-center">
        {" "}
        <ShowForPermission
          permission={["company:list-groups", "company:list-users"]}
        >
          {(CompanyState.permissions?.includes("company:list-groups") &&
            CompanyState.permissions?.includes("company:list-users")) ||
          CompanyState.permissions?.includes("company:list-users") ||
          CompanyState.isAuth ? (
            <NavLink
              exact={true}
              to="/team-management/users"
              activeClassName="is-active"
              className="mr-4 py-2 text-bold"
            >
              {translate.teamManagement}
            </NavLink>
          ) : (
            <NavLink
              exact={true}
              to="/team-management/groups"
              activeClassName="is-active"
              className="mr-4 py-2 text-bold"
            >
              {translate.teamManagement}
            </NavLink>
          )}
        </ShowForPermission>
      </List>

      <List className="lan text-center"></List>
      <List className="lan text-center"></List>
    </Box>
  );

  return (
    <>
      <header className="header"></header>

      <div className="w-100 bg-white">
        <div
          className="container  py-2 "
          style={{ boxShadow: "0 1px 10px rgb(0 0 0 / 0.2)" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <figure
              className="logo"
              onClick={() => {
                history.push("/");
              }}
            >
              <img className="w-75" src={logoImg} alt="logo" />
            </figure>

            <div className="header-icons d-flex align-items-center">
              <div className="header-icons d-flex  justify-content-end">
                {/***************************  Start Notification   **************************************************** */}
                <UncontrolledDropdown
                  setActiveFromChild
                  className="notification "
                >
                  <DropdownToggle
                    tag="button"
                    className="h5 text-primary d-flex align-items-center"
                    //caret
                  >
                    <i class="fas fa-bell notify-icon">
                      <span className="notify-number">
                        {" "}
                        {/* {notifyData?.length}{" "} */}
                        {notificationLength?.length}
                      </span>
                    </i>
                    {/* {translate.notification} */}
                  </DropdownToggle>
                  <DropdownMenu className="bg-notify no-padding notify-items ">
                    {AllNotification}
                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        history.push("/notification");
                      }}
                      className="d-flex justify-content-center w-100 notify-show-more py-2"
                    >
                      <h6> {translate.showMore} </h6>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/***************************  End Notification   **************************************************** */}

                <UncontrolledDropdown setActiveFromChild>
                  <DropdownToggle
                    tag="button"
                    className="h5 text-primary d-inline settings-head"
                    caret
                  >
                    <i className="fas fa-cog fa-lg  mx-2 text-primary "></i>
                    {"  "}
                    <span className="Header-Setting-title">
                      {/* {translate.setting} */}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="settings">
                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        dispatch({ type: "setLocale", payload: "ar" });
                      }}
                      className="d-flex justify-content-start"
                    >
                      <img
                        className="mx-1 px-2"
                        src={arLogo}
                        width="40"
                        alt="lang"
                      />
                      <span style={{ dispaly: "inline-block", width: "25px" }}>
                        العربية
                      </span>
                    </DropdownItem>

                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        dispatch({ type: "setLocale", payload: "en" });
                      }}
                      className="d-flex justify-content-start"
                    >
                      <img
                        className="mx-1 px-2"
                        src={enLogo}
                        width="40"
                        alt="lang"
                      />
                      <span style={{ dispaly: "inline-block", width: "25px" }}>
                        English
                      </span>
                    </DropdownItem>

                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        history.push(`/changePassord`);
                      }}
                    >
                      {translate.changePassword}
                      <i className="mx-2"></i>
                    </DropdownItem>
                    {/****** */}
                    <DropdownItem
                      tag="button"
                      onClick={() => {
                        dispatch({ type: "logout", payload: "logout" });
                      }}
                    >
                      {translate.logout}
                      <i
                        class="fas fa-sign-out-alt mx-2"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div>
                {(currentLanguage == "en" ? ["right"] : ["left"]).map(
                  (anchor) => (
                    <React.Fragment key={anchor}>
                      {console.log("anchor", anchor)}
                      <Button onClick={toggleDrawer(anchor, true)}>
                        <i class="fas fa-list fa-2x"></i>
                        {/* {anchor} */}
                      </Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        PaperProps={{
                          sx: {
                            backgroundColor: "#324356",
                          },
                        }}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
