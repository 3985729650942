import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ShowForPermission from "../../../helpers/showForPermission";
import Locale from "../../../translations";

function ComanyMedical({ changeStatusUser, allData }) {
  let history = useHistory();

  const { translate } = Locale;
  return (
    <>
      <tr className="comany-medical ">
        <td>{allData?.name}</td>
        <td>{allData?.country}</td>
        <td>{allData?.city} </td>
        <td>{allData?.user_name}</td>

        <td>{allData.phone_number} </td>

        <td>
          <i class="fas fa-eye" style={{ fontSize: "14px" }}></i>{" "}
          <span className="font-weight-bold">{allData.total_reservations}</span>
        </td>
        {allData.status == "active" ? (
          <td className="text-success">{translate.Active}</td>
        ) : (
          <td className="text-danger">{translate.Deactive}</td>
        )}

        <td className="actions d-flex justify-content-around">
          <ShowForPermission permission={"view-company"}>
            <button
              className="text-primary  btn-link"
              onClick={() => {
                history.push(`/admin/ComanyMedical-/${allData.id}`);
              }}
            >
              <i class="fas fa-eye"></i> {translate.view}
            </button>
          </ShowForPermission>
          {/* ************************edit******************* */}
          <ShowForPermission
            permission={["edit-company", "view-company"]}
            type={"and"}
          >
            <button
              className="text-primary  btn-link"
              onClick={() => {
                history.push(`/admin/ComanyMedical-edit/${allData.id}`);
              }}
            >
              <i className="fas fa-edit"></i> {translate.edit}{" "}
            </button>
          </ShowForPermission>

          {/*********************tswia**************************/}
          {allData.country === "Saudi Arabia" ||
          allData.country === "السعودية" ? (
            <ShowForPermission permission={"list-settlements"}>
              <button
                className="text-primary  btn-link"
                onClick={() => {
                  history.push(`/admin/saudi/payment/${allData.id}`);
                }}
              >
                <i class="fas fa-money-bill-wave"></i> {translate.Settlement}{" "}
              </button>
            </ShowForPermission>
          ) : (
            <ShowForPermission permission={"list-settlements"}>
              <button
                className="text-primary  btn-link"
                onClick={() => {
                  history.push(`/admin/payment/${allData.id}`);
                }}
              >
                <i class="fas fa-money-bill-wave"></i> {translate.Settlement}{" "}
              </button>
            </ShowForPermission>
          )}

          {allData.country === "Saudi Arabia" ||
          allData.country === "السعودية" ? (
            <ShowForPermission permission={"list-settlements"}>
              <button
                className="text-primary  btn-link"
                onClick={() => {
                  history.push(`/admin/saudi/Invoices/${allData.id}`);
                }}
              >
                <i class="fas fa-file-invoice-dollar mx-1"></i>
                {translate.Invoices}{" "}
              </button>
            </ShowForPermission>
          ) : (
            <span style={{ width: "15%" }}></span>
          )}

          {/* ************************Orders******************* */}
          {allData.country === "Saudi Arabia" ||
          allData.country === "السعودية" ? (
            <ShowForPermission permission={"list-reservations"}>
            <button
              className="text-primary  btn-link"
              onClick={() => {
                history.push(`/admin/Umrah/Medical/Requests/orders/${allData.id}`);
              }}
            >
              <i class="fas fa-th-list"></i> {translate.orders}
            </button>
          </ShowForPermission>
          ) : (
            <ShowForPermission permission={"list-reservations"}>
            <button
              className="text-primary  btn-link"
              onClick={() => {
                history.push(`/admin/ordersList/incoming/${allData.id}`);
              }}
            >
              <i class="fas fa-th-list"></i> {translate.orders}
            </button>
          </ShowForPermission>
          )}
         
          {/* ************************Users******************* */}
          {/* <button
            className="text-primary mr-3  btn-link"
            onClick={() => {
              history.push(`/admin/users/${allData.id}`);
            }}
          >
            <i class="fas fa-user-friends "></i> {translate.users}
          </button> */}

          {/* ************************changeStatusUser******************* */}

          {allData.status === "active" ? (
            //activate-company
            <ShowForPermission permission={"activate-company"}>
              <button
                type="button"
                onClick={() => changeStatusUser(allData.id, "deactivate")}
                className="btn btn-link text-danger"
              >
                <i class="fas fa-stop-circle"></i> {translate.deactive}
              </button>
            </ShowForPermission>
          ) : (
            <ShowForPermission permission={"activate-company"}>
              <button
                type="button"
                onClick={() => changeStatusUser(allData.id, "activate")}
                className="btn btn-link text-success"
              >
                <i class="fas fa-play-circle"></i> {translate.activate}
              </button>
            </ShowForPermission>
          )}
          {/*  */}
        </td>
        {/* Modal examainaton type */}
        <div></div>
      </tr>
    </>
  );
}

export default ComanyMedical;
