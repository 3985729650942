import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { NavLink } from "react-router-dom";
import Filter from "./filter";
import ListTravelersProgress from "./listTravelersProgress";
import FilterProgress from "./filterProgress";
import moment from "moment";
import { listPendingTravellersFilter, listTravellersFilter } from "../../../../services/yarvel";
import ShowForPermission from "../../../../helpers/showForPermission";

function Progress() {
 
  const { translate } = Locale;
 
 /** */
 const [filter, setfilter] = useState({
  name:"",
  departure_date: "",
  check_date: "",
  page:1
});
const [meta, setMeta] = useState([]);

 const [searching, setSearching] = useState(false)
  const [allData,setAllData]=useState([])
 const submit = () => {

  setSearching(!searching);
}
const [pageing, setPageing] = useState(false);
  const goTo = async (page) => {
    setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    setPageing(!pageing)
  };
useEffect(() => {
  async function fetchListClients() {
    
    const list = await listPendingTravellersFilter({
      ...filter,
      departure_date: filter.departure_date
        ? moment(filter.departure_date).format("YYYY-MM-DD")
        : "",
        check_date: filter.check_date
        ? moment(filter.check_date).format("YYYY-MM-DD")
        : "",
    });

setAllData(list?.data);
setMeta(list?.data?.meta)

  }
  fetchListClients();
}, [pageing]);


useEffect(() => {
  async function fetchListClients() {
    
    const list = await listPendingTravellersFilter({
      //...filter,
      departure_date: filter.departure_date
        ? moment(filter.departure_date).format("YYYY-MM-DD")
        : "",
        check_date: filter.check_date
        ? moment(filter.check_date).format("YYYY-MM-DD")
        : "",
        name:filter.name
    });

setAllData(list?.data);
setMeta(list?.data?.meta)

  }
  fetchListClients();
}, [searching]);

// useEffect(()=>{
// async function fetchListClients() {
//   await setAllData(allData)
  
//    //console.log("*AllData*--",allData)
// }
// fetchListClients();
// })
  /** */
  return (
    <section>
      <div className="container p-0 my-4  orders-list ">
        

        <header className=" list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">{translate.ComanyMedical}</h3>
          <div className="navLink">
            
              <NavLink
                exact={true}
                to="/CustomersList/orders"
                activeClassName="is-active"
                className="tab-link py-2 px-4 text-bold bg-white mr-3 bg-brwon rounded text-muted"
              >
                {translate.IncomingRequests}
              </NavLink>
              <NavLink
                exact={true}
                to="/CustomersList/progress"
                activeClassName="is-active"
                className="tab-link py-2 px-4 text-bold bg-white mr-3  bg-brwon rounded text-muted"
              >
                {translate.InProgress}
              </NavLink>


              <NavLink
                exact={true}
                to="/CustomersList/checked"
                activeClassName="is-active"
                className="tab-link py-2 px-4 text-bold bg-white mr-3 rounded text-muted"
              >
                {translate.AreChecked}
              </NavLink>
          </div>
        </header>
        <FilterProgress submit={submit} filter={filter} setfilter={setfilter} />
        <ListTravelersProgress allData={allData} filter={filter} setfilter={setfilter} setAllData={setAllData} meta={meta} setMeta={setMeta} goTo={goTo} />
      
      </div>
      
    </section>
  );
}

export default Progress;
