import { useEffect, useState } from "react";
import { useHistory } from "react-router";

function Reducers(state, action) {
  switch (action.type) {
    case "setLoading":
      return { ...state, loading: action.payload };

    case "setLocale":
      localStorage.setItem("currentLanguage", action.payload);
      window.location.reload();
      return { ...state, currentLanguage: action.payload };
    case "logout":
      localStorage.removeItem("isAuth");
      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("permissions");
      localStorage.removeItem("organizationCountry");
      localStorage.removeItem("enabledModules");
      window.location.reload();

      return { ...state };

    case "getPermissions":
      localStorage.setItem("company_name", action?.payload?.organization?.name);

      localStorage.setItem("userType", action?.payload?.user_type);
      localStorage.setItem(
        "organizationCountry",
        action?.payload?.organization?.country_id
      );

      localStorage.setItem(
        "permissions",
        JSON.stringify(action?.payload?.permissions)
      );
      return {
        ...state,
        permissions: action?.payload?.permissions,
        userType: action?.payload?.user_type,
        is_admin: action?.payload?.is_admin,
        organizationCountry: action?.payload?.organization?.country_id,
      };

    case "login":
      localStorage.setItem("isAuth", true);
      localStorage.setItem("token", action.payload?.access_token);
      localStorage.setItem("userType", action.payload?.user_type);
      localStorage.setItem("isAdmin", action.payload?.isAdmin);
      localStorage.setItem(
        "permissions",
        JSON.stringify(action.payload.permissions)
      );
      localStorage.setItem(
        "enabledModules",
        JSON.stringify(action.payload.enabledModules)
      );
      return {
        ...state,
        isAuth: true,
        token: action.payload.access_token,
        userType: action.payload.user_type,
        isAdmin: action.payload.isAdmin,
        permissions: action.payload.permissions,
        enabledModules: action.payload.enabledModules,
      };
    /******** */
    case "RedirectToHome": {
      return { ...state, havePermission: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default Reducers;
