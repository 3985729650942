
import React from "react";
import Pagination from "../../../components/shared/Pagination";
import Locale from "../../../translations";
import ComanyMedical from "./ComanyMedical";
export default function   ListComanyMedical({allData,setAllData,changeStatusUser,filter,setfilter,meta,setMeta}) {
  const { translate } = Locale;
  // call api for progress
 
  const AllCompanies =
  allData?.length != 0
    ? allData?.map((data, index) => (
        <ComanyMedical
         allData={data}
         index={index}
         changeStatusUser={changeStatusUser}
        />
      ))
    : [];


    const goTo = async (page) => {
      setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    };
   
  // call api for checked
   
  return (
    <>
       <table className="table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col">{translate.company}</th>
            <th scope="col">{translate.country}</th>
            <th scope="col">{translate.governorate}</th>
            <th scope="col">{translate.nameOperation}</th>
            <th scope="col">{translate.phoneNumber}</th>
            <th scope="col">{translate.totalOrders}</th>
            <th scope="col">{translate.status}</th>
           
            <th scope="col" >{translate.tools}</th>
          </tr>
        </thead>
        <tbody>
        {AllCompanies?.length > 0 ? (
												AllCompanies
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
        </tbody>
      </table>
      <Pagination info={meta} goTo={goTo} items={10} />    
     
    </>
  );
}
