import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import Locale from "../../../translations";

export default function ViewTraveller({ data, index, toggle, toggleDate }) {
  const { translate } = Locale;
  const { id, status } = useParams();

  const traveller = status ? data?.traveller : data;

  return (
    <div>
      <div className="py-2 p-3  border instructions rounded mt-3">
        <div className="d-flex justify-content-between">
          <h5 className="font-weight-bold text_blue-dark mt-2">
            {translate.CustomerData} {index + 1}
          </h5>

          {status && data?.attachment === null ? (
            <div>
              <button
                type="button"
                class="btn btn-success"
                onClick={() => {
                  toggle(data.id);
                }}
              >
                {translate.addResult}
              </button>
              <button
                type="button"
                class="btn btn-secondary mx-2"
                onClick={() => {
                  toggleDate(data.id);
                }}
              >
                {translate.ChangeExaminationDate}
              </button>
            </div>
          ) : status && data?.attachment != null ? (
            <a type="button" class="btn btn-primary" href={data.attachment}>
              {translate.reportResult}
            </a>
          ) : null}
        </div>

        <div className="row lan">
          {status ? (
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.name}
            </p>
          ) : (
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.nameArabic}
            </p>
          )}
          {status ? (
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {" "}
              {traveller?.traveller_name}
            </p>
          ) : (
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {traveller?.first_name?.ar} {traveller?.father_name?.ar}{" "}
              {traveller?.grandpa_name?.ar} {traveller?.last_name?.ar}
            </p>
          )}
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.nationalId}
          </p>
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.nin}
          </p>
          {status ? null : (
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.EnglishName}
            </p>
          )}
          {status ? null : (
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {traveller?.first_name?.en} {traveller?.father_name?.en}{" "}
              {traveller?.grandpa_name?.en} {traveller?.last_name?.en}
            </p>
          )}
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.passport_number}
          </p>
          {/*         /**************************** */}
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.visa_number}
          </p>
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.visa_number}
          </p>
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.border_no}
          </p>
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.border_no}
          </p>

          {status ? (
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.DateRequest}
            </p>
          ) : null}

     { status ?    <p className="col-6  col-md-4  font-mobile  h5   ">
            {data?.check_date
              ? moment(data?.check_date, "YYYY-MM-DD HH:mm aa").format(
                  "DD-MM-YYYY   HH:mm "
                )
              : null}
          </p> : null}

          {/*    /**************************** */}
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.Nationality}
          </p>
          {status ? (
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {traveller?.nationality.ar}
            </p>
          ) : (
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {traveller?.country}
            </p>
          )}
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.birthDay}
          </p>
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.date_of_birth}
          </p>
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.gender}
          </p>
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.gender}
          </p>
          {status === "checked" || data?.result != null ? (
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.Result}
            </p>
          ) : null}
          <p className="col-6  col-md-4  font-mobile  h5    ">{data?.result}</p>
        </div>
      </div>

      {/*  <div className="row lan list_header py-2 m-0 rounded border">
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.ServiceGrade}
        </p>

        <p className="col-6  col-md-4  font-mobile  h5    ">{data?.grade}</p>

        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.serviceType}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {data?.reservation_type}
        </p>
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.ExaminationType}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {data?.test_type}
        </p>

        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.resultWithin}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {data?.resultWithin} {translate.hours}
        </p>
      </div> */}
    </div>
  );
}
