
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Locale from "../../../translations";
const { translate } = Locale;


export default class ApexChart extends React.Component {
   
    constructor(props) {
     
      //const num2=props.num?.reservations
      super(props);
      //${translate.TotalNumberOrders}
       const title=this.props.currentLanguage =="en"?"Total number of orders" :"اجمالى عدد الطلبات"
      console.log("props=", props.currentLanguage );
      this.state = {
        
        num:props?.num?.reservations,
       series:[+`${(props?.num?.reservations?.new)}`,+`${(props?.num?.reservations?.pending)}`,+`${(props?.num?.reservations?.checked)}`],
     
            
                  options: {
              chart: {
                type: 'donut',
                height: 200,
                width: '100%',
              },
              
        
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],

              

               plotOptions: {
                pie: {
                  startAngle: 10,
                  donut: {
                    size: '77%',
                    dataLabels: {
                      enabled: false
                    },
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        offsetY: 18,
                        formatter: () => ` ${title} ${ (this.state?.num?.new + this.state?.num?.pending + this.state?.num?.checked)} ` //
                      },
                      value: {
                       // show: true,
                        fontSize: '12px',
                        fontFamily: 'Open Sans',
                        fontWeight: 500,
                        color: '#fff',
                        offsetY: -10
                      },
                      total: {
                        show: true,
                        showAlways: true,
                        color: '#333',
                        fontFamily: 'Cairo',
                        fontWeight: 700,
                        
                      }
                    }
                  }
                },
              },
              //
              dataLabels: { enabled: true, formatter: function (val, opt) { return   " " } },
              
              stroke: {
                width: 0
              },
              fill: { colors: ['#24AEFB', '#F28F31', '#00B536'] },
              colors: ['#24AEFB', '#F28F31', '#00B536'],
              labels: ['طلبات واردة', ' قيد التشغيل', ' تم فحصهم'],
      
            }
          
          };
    }

    render() {
      return (
        
    <div id="chart">
    <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={350}  />
    </div>
    );
}
}
