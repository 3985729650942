import React, { useEffect, useState } from "react";
import DatePicker from "../../../components/shared/DatePicker";
import SelectField from "../../../components/shared/SelectField";
import TextField from "../../../components/shared/textField";
import { areasListAdmin, fetchCities, fetchHotelsSaudiAdmin } from "../../../services/admin";
import { fetchAreas } from "../../../services/looksup";
import {
  fetchAreaSaudi,
  fetchCitiesSaudi,
  fetchHotelsSaudi,
} from "../../../services/UmrahRequests";
import Locale from "../../../translations";

export default function Filter({ filter, setfilter, submit }) {
  const { translate } = Locale;
  const [cities, setCities] = useState();
  const [areas, setAreas] = useState();
  const [hotels, setHotels] = useState();

  useEffect(async () => {
    const res = await fetchCities(966);
    const formatted = res.map((city) => {
      return { value: city?.id, label: city?.name };
    });
    setCities(formatted);
  }, []);

  useEffect(async () => {
    if (filter.city_id?.value) {
      const res = await areasListAdmin(filter.city_id?.value);

      const formatted = res?.data.map((area) => {
        return { value: area?.id, label: area?.name };
      });
      setAreas(formatted);
    }
  }, [filter.city_id]);

  useEffect(async () => {
    if (filter.area_id?.value) {
      const res = await fetchHotelsSaudiAdmin(filter.area_id?.value);
      const formatted = res.map((hotel) => {
        return { value: hotel.id, label: hotel.name };
      });
      setHotels(formatted);
    }
  }, [filter.area_id]);

  return (
    <div className="row p-3 bg-white no-gutters list_header bg-white comany-medical-filter payment-filter ">
      <div className="col-12 col-md  mx-2">
        {" "}
        <TextField
          hasLabel={false}
          type={"text"}
          onFocus={false}
          value={filter.company_name}
          placeholder={translate.companyNamePlaceholder}
          onChange={(e) => {
            setfilter({
              ...filter,
              company_name: e.target.value,
            });
          }}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>

      <div className="col-12 col-md    ">
        <SelectField
          hasLabel={false}
          onFocus={false}
          value={filter.city_id}
          placeholder={translate.cityPlaceholder}
          options={cities}
          onChange={(e) => {
            setfilter({
              ...filter,
              city_id: e,
            });
          }}
        />
      </div>

      <div className="col-12 col-md  mx-md-1  ">
        <SelectField
          hasLabel={false}
          onFocus={false}
          options={areas}
          label={translate.area}
          value={filter.area_id}
          placeholder={translate.areaPlaceholder}
          onChange={(e) => {
            setfilter({
              ...filter,
              area_id: e,
            });
          }}
        />
      </div>

      <div className="col-12 col-md mx-md-1  ">
        <TextField
          hasLabel={false}
          value={filter.hotel_id}
          placeholder={translate.hotelPlaceholder}
          onChange={(e) => {
            setfilter({
              ...filter,
              hotel_id: e.target.value,
            });
          }}
        >
                  <i className="fas fa-search"></i>
        </TextField>
      </div>

      <div className="col-12 col-md mx-md-1 ">
        <DatePicker
          hasLabel={false}
          placeholder={translate.dateOut}
          date={filter?.date}
          name="date"
          onChangeDate={(e) => {
            setfilter({
              ...filter,
              date: e,
            });
          }}
        />
      </div>

      <div className="mx-1 mt-1  col-12 col-md d-flex ">
        <button
          className="btn btn-primary mt-2 mt-md-0  text_filed px-5 py-2 font-weight-bold "
          onClick={submit}
        >
          {translate.search}
        </button>
        <button
          className="btn text-muted font-weight-bold  "
          onClick={() => {
            setfilter({
              city_id: null,
              area_id: null,
              hotel_id: "",
              date: "",
              company_name: "",
            });
            submit();
          }}
        >
          {" "}
          <i className="fas fa-retweet"></i>
          {translate.reset}
        </button>
      </div>
    </div>
  );
}
