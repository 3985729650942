import React from "react";
import Pagination from "../../../../components/shared/Pagination";
import Locale from "../../../../translations";
import TravelerCheched from "./travelerChecked";


export default function ListTravelersCheched({allData,filter,setfilter,meta,goTo}) {
  const { translate } = Locale;
  // call api for progress

  // call api for checked
  const AllTravelers =
  allData.length > 0
    ? allData.map((data, index) => (
        <TravelerCheched
          allData={data}
          index={index}
        />
      ))
    : [];

    // const goTo = async (page) => {
    //   setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    // };
  return (
    < >
       <table className="table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
              
            <th scope="col">{translate.orderNumber}</th>
            <th scope="col">{translate.typeCheck}</th>
            <th scope="col">{translate.client}</th>
            <th scope="col">{translate.companyName}</th>
            <th scope="col">{translate.examinationMethod}</th>
            <th scope="col">{translate.date} {translate.check}</th>
            <th scope="rowgroup">{translate.DataOperation}</th>
            <th scope="col">{translate.Cost}</th>
            <th scope="col">{translate.ResultTime}</th>
            <th scope="col">{translate.companyPercentage}</th>
            <th scope="col">{translate.safaPercentage}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
        {AllTravelers?.length > 0 ? (
												AllTravelers
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
          
        </tbody>
      </table>
      {/*       <Pagination /> */}
      <Pagination info={meta} goTo={goTo} items={10} />    
    </>
  );
}
