import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { useHistory, useParams } from "react-router";
import { listTravellersById } from "../../../../services/yarvel";
import { useCompanyState } from "../../../../context/global";
export default function View( { modalShow1 ,setModalShow1}) {
  const { translate } = Locale;
  let history = useHistory();
  const { currentLanguage } = useCompanyState();
  //listTravellersById
  const [employee, setEmployee] = useState();
  let { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      const Employees = await listTravellersById(id);
      setEmployee(Employees?.data?.data);
    }
    fetchData();
  }, []);
//const modalShow1= modalShow1?.location?.state
//console.log("modalShow120",!!modalShow?.location?.state);

  return (
    <div className="container p-0 order-list-view">
      {/* <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold">{translate.PCR}</h3>
        <button
          className="btn btn-primary  font-weight-bold "
          onClick={() => {
            //setModalShow(true)
            history.push({pathname:"/CustomersList/orders",state: { modalShow1: true,setModalShow1:true } });
            
          }}
        >
          <i class="fas fa-print" style={{ fontSize: "16px" }}></i>{" "}
          {translate.SupplyingRunningOrder}{" "}
        </button>
      </header> */}

      <div className="bg-white px-5 py-4 instructions-all">
        {/* *************************************************************************/}
       
<div className="instructions border my-4">
   <h5 className="font-weight-bold text_blue-dark  px-2">
          {" "}
          {translate.orderData}
        </h5>
        <div className=" pb-3 p-2 row">
      
       
          <p className="col-2 h5   font-weight-bold">{translate.check}</p>
          <p className="col-4 h5 ">{employee?.test_type}</p>
          <p className="col-2 h5   font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 ">{employee?.reservation_no}</p>
          <p className="col-2 h5   font-weight-bold">
            {translate.typeCheck}
          </p>
          <p className="col-4 h5 ">{employee?.reservation_type}</p>

          <p className="col-2  h5  font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 ">
          {employee?.request_date?.slice(0, 10)} | {" "}
          {employee?.request_date?.slice(11, 16)}
          </p>

          <p className="col-2  h5  font-weight-bold">
            {translate.serviceDegree}
          </p>
          <p className="col-4 h5 ">{employee?.grade}</p>

          <p className="col-2 h5  font-weight-bold">
            {/* {translate.ResultTimeResult} */}
            {translate.resultWithin}
          </p>
          <p className="col-4 h5 ">
            {employee?.time} {translate.hour}
          </p>
        </div>
        </div>
        {/* *************************************************************************/}
      
        {((currentLanguage=="ar"&&employee?.test_type !== "تحليل مخدرات") || (currentLanguage=="en"&&employee?.test_type !== "Drugs Examination"))?(
       <>
       <div className="instructions border">
        <h5 className="font-weight-bold px-2  text_blue-dark mt-2">
          {" "}
          {translate.TripData} 
        </h5>

        <div className="  p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 ">
            {employee?.trip?.destination?.names[currentLanguage]}
          </p>

          <p className="col-2  h5  font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 ">
          {employee?.trip?.departure_date?.slice(0, 10)} | {employee?.trip?.departure_date?.slice(11, 16)}
          </p>          <p className="col-2  h5  font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 ">
            {" "}
            {employee?.trip?.transporter.names[currentLanguage] }  {/**[currentLanguage] */}
          </p>
         
          <p className="col-2 h5  font-weight-bold">
            {translate.arrivalDate}
          </p>
          <p className="col-4 h5 "> {employee?.trip?.arrival_date?.slice(0, 10)} | {employee?.trip?.arrival_date?.slice(11, 16)}</p>




        </div>
        </div>
        </>):""}
        {/* *************************************************************************/}
        <div className="instructions border my-4">
        <h5 className="font-weight-bold p-2 text_blue-dark ">
          {" "}
          {translate.CustomerData}
        </h5>

        <div className=" p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.nameArabic}
          </p>
          <p className="col-4 h5 ">{employee?.traveller_name_ar}</p>
          <p className="col-2 h5  font-weight-bold">
            {translate.nationalId}
          </p>
          <p className="col-4 h5 "> {employee?.national_id} </p>
          <p className="col-2  h5  font-weight-bold">
            {translate.EnglishName}
          </p>
          <p className="col-4 h5 ">{employee?.traveller_name_en}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-4 h5 ">{employee?.passport_number}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.Nationality}
          </p>
          <p className="col-4 h5 ">{employee?.nationality}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 ">{employee?.phone}</p>
        </div>
        </div>
        {/* *************************************************************************/}
        {/* <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.ExaminationData}
        </h5> */}

        {/* <div className="instructions py-3 p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.examinationStatus}
          </p>
          <p className="col-4 h5 ">{employee?.data?.test_type}</p>
          <p className="col-2 h5  font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-4 h5 ">{employee?.data?.grade}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 ">{employee?.data?.reservation_type}</p>
        </div> */}

        {/* *************************************************************************/}
        {/* <h5 className="font-weight-bold py-3 text_blue-dark mt-2">
          {" "}
          {translate.Cost}
        </h5>

        <div className="instructions py-3 p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.TotalCost}
          </p>
          <p className="col-4 h5 ">
            {employee?.data?.amount} {translate.pound}
          </p>
          <p className="col-2 h5  font-weight-bold">{translate.Paid}</p>
          <p className="col-4 h5 ">
            {employee?.data?.amount} {translate.pound}
          </p>
        </div> */}
<div className="instructions border my-4">
        <h5 className="font-weight-bold p-2 text_blue-dark ">
          {" "}
         {translate.ExaminationDateAddress} 
         
        </h5>

        <div className=" p-2 rounded-lg row">
          <p className="col-2 h5   font-weight-bold">
            {currentLanguage=="en" ? ( `${translate.check} ${translate.date}` )  : ( `${translate.date} ${translate.check}` ) }

            
          {/* {translate.check} {translate.date}  */}
          </p>
          <p className="col-4 h5 ">{/**{employee?.check_date?.slice(11, 16)}  |  */} {employee?.check_date?.slice(0, 10)}</p>
          <p className="col-2 h5   font-weight-bold">
            {translate.ResultTime}
          </p>
          <p className="col-4 h5 ">
              {employee?.check_date?.slice(11, 16)}       {/**- {employee?.check_date_end?.slice(11, 16)} */}
          </p>

          <p className="col-2 h5   font-weight-bold">
            {translate.governorate}
          </p>
          <p className="col-4 h5 ">{employee?.city_id}</p>
          <p className="col-2 h5   font-weight-bold">{translate.city}</p>
          <p className="col-4 h5 ">{employee?.area_id}</p>

          <p className="col-2 h5   font-weight-bold">{translate.StName}</p>
          <p className="col-4 h5 ">{employee?.street}</p>
          <p className="col-2 h5   font-weight-bold">
            {translate.BuildingNumber}
          </p>
          <p className="col-4 h5 ">{employee?.building}</p>

          <p className="col-2 h5   font-weight-bold">{translate.Floor}</p>
          <p className="col-4 h5 ">{employee?.floor}</p>
          <p className="col-2 h5  font-weight-bold">{translate.ApartmentNumber}</p>
          <p className="col-4 h5 ">{employee?.flat}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 ">{employee?.phone}</p>
          <p className="col-2  h5  font-weight-bold">{translate.email}</p>
          <p className="col-4 h5 ">{employee?.email}</p>

          {/* <p className="col-2  h5 py-3 font-weight-bold">{translate.Cost}</p>
          <p className="col-4 h5 py-3">
            {employee?.data?.amount}
            {translate.pound}
          </p> */}
          {/* <p className="col-2  h5 py-3 font-weight-bold">
            {translate.paymentMethods}
          </p>
          <p className="col-4 h5 py-3">{employee?.data?.comission_type}</p> */}
        </div>
        </div>
      </div>

      <button
        className=" btn btn-secondary px-5 my-4 font-weight-bold"
        onClick={() => {
          history.push("/CustomersList/orders");
        }}
      >
        {" "}
        {translate.back}
      </button>
    </div>
  );
}
