


import logoImg from "../../../assets/images/Image1.png";

import React, { useState } from "react";
import { store } from "react-notifications-component";
import TextField from "../../../components/shared/textField";
import { forgetPassword } from "../../../services/yarvel";
export default function ResetPasswordAdmin() {
//let history = useHistory();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const sendEmail = async () => {
    const res = await forgetPassword({ email: user.email });
    //debugger
    console.log("res",res)
     if ( res?.status === 200 || res?.status === 201) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      
    } 
  };
  return (
    <div className="d-flex  justify-content-center align-items-center mt-5" style={{height:"100vh"}}>
      
      <figure className="logo w-25">
        <img className="w-100" src={logoImg} alt="logo" />
      </figure>
      <div className="d-flex flex-column ml-5 w-25 ">
        <div className="mt-4">
          <TextField
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          >
            <i className="fas fa-user text-primary"></i>
          </TextField>
        </div>
        <button className="btn btn-primary mt-4" onClick={sendEmail}>
          تاكيد البريد الالكتروني
        </button>
      </div>
    </div>
  );
}