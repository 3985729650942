import React from "react";
import { useLabsState } from "../context/global";

export default function ShowForPermission(props) {
	let LabsState = useLabsState();
	console.log("props.permission", props.permission);
    

if (LabsState?.permissions && LabsState?.permissions?.length > 0 && LabsState?.permissions!="[]" && LabsState.userType!= "company-super-admin" && LabsState.userType!="super-admin" ) {
    let type = props.type ? props.type : "or";
    if (Array.isArray(props.permission) && type === "or") {
      /* this case for or conditions ex :  list admins and list groups   permission could have one of them */
      return props.permission?.some((per) =>
        LabsState.permissions?.includes(per)
      ) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;
      /* *************************************** */
    } else if (Array.isArray(props.permission) && type === "and") {
      return props.permission?.every((per) =>
        LabsState.permissions?.includes(per)
      ) ? (
        <React.Fragment>{props?.children}</React.Fragment>
      ) : null;
      /* *************************************** */
    } else {
      return LabsState.permissions?.includes(props.permission) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;
	    }
  } else if ( LabsState.userType ==  "super-admin"  || LabsState.userType ==  "company-super-admin" ) {
    return <React.Fragment>{props.children}</React.Fragment> ;
  }
return null
}
	

