import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { NavLink, useParams } from "react-router-dom";
import { store } from "react-notifications-component";

import {
  RetrievedUser,
  listGroupsDp,
  editUserAPI,
} from "../../../services/WorkTeamTenant";
import Locale from "../../../translations";
import { permissionsReload } from "../../../services/yarvel";

const Edit = (props) => {


  const { translate, TeamManagement } = Locale;
  const { user_id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [groupsDB, setGroupsDB] = useState([]);
  const [groups, setGroups] = useState(0);
  
  useEffect(() => {
    async function fetchData() {
      const User = await RetrievedUser(user_id);
      console.log("tt**",User.data.data.roles[0].display_name);
      
      formik.setFieldValue("userName", User.data.data.name);
      formik.setFieldValue("email", User.data.data.email);
      formik.setFieldValue("phoneNumber", User.data.data.phone_number);
      
      //formik.setFieldValue("groups", User.data.data.group_id);
      formik.setFieldValue("groups", User.data.data.roles[0].display_name);
      //debugger
      formik.setFieldValue("admin_type", User.data.data.admin_type);
      formik.setFieldValue("company_id", User.data.data.company_id);
      formik.setFieldValue("is_admin", User.data.data.is_admin);
      
      const GroupsDp = await listGroupsDp();
      if (GroupsDp.status === 200) {
        const format =GroupsDp.data?.data?.map((e) => ({
          value: e.id,
          label: e.name,
        }));
        //console.log('format',GroupsDp);
        setGroupsDB(format);
      }
    }
    fetchData();
  }, []);

  const handleChangeGroups = (event) => {
    setGroups(event.target.value.target.value); 
  };
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const editUser = async (payload) => {
    const res = await editUserAPI(user_id, payload);

    if (res.status === 201) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      props.history.push("/team-management/users");
    }

    //console.log("res", res);
    
  };

  const validationSchema = yup.object({
    userName: yup
      .string(translate.userNameError)
      .required(translate.userNameRequired),
    email: yup
      .string(translate.userEmailRequired)
      .email(translate.userEmailError)
      .max(50, translate.userEmailError)
      .required(translate.userEmailRequired),
    phoneNumber: yup
      .number()
      .typeError(translate.phoneNumberError)
      .test("phoneNumber-check", null, (mobile) => {
        if (mobile) {
          let mobilevalue = "+20" + mobile;

          try {
            const phoneUtil =
              require("google-libphonenumber").PhoneNumberUtil.getInstance();
            const number = phoneUtil.parse(mobilevalue.toString(), "EG");

            if (phoneUtil.isValidNumber(number, "EG")) {
              return;
            } else {
              return new yup.ValidationError(
                translate.phoneNumberError,
                null,
                "phoneNumber"
              );
            }
          } catch (e) {
            console.log("Valid Number was thrown: ", e.toString());
          }
        }
      })
      .required(translate.phoneNumberRequired),
    password: yup
      .string(translate.passwordPlaceholder)
      .min(8, translate.passwordErrorMin)
      .max(20, translate.passwordErrorMax)
      .test({
        name: "passwords-valid",
        exclusive: false,
        params: {},
        message: translate.passwordErrorMin,
        test: function (value) {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);

          return (hasUpperCase && hasLowerCase && hasNumber) || typeof(value) === 'undefined' ;          
        },
      })
      ,
    confirmPassword: yup
      .string(translate.password_confirmationPlaceholder)
      .min(8, translate.passwordErrorMin)
      .max(20, translate.passwordErrorMax)
      .test({
        name: "passwords-match",
        exclusive: false,
        params: {},
        message: translate.ShouldMatchPassword,
        test: function (value) {
          return value === this.parent.password;
        },
      }),
    groups: yup.string().required(translate.groupsRequired).nullable(),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      groups: groups,
      admin_type: "",
      company_id: "",
      is_admin: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      // let payload = {
      //   name: values.userName,
      //   email: values.email,
      //   phone_number: values.phoneNumber,
      //   password: values.password,
      //   password_confirmation: values.confirmPassword,
      //   group_id: values.groups,
      //   admin_type: values.admin_type,
      //   company_id: values.company_id,
      //   is_admin: values.is_admin,
      // };


 console.log("values.password",values.password);
let payload = {};
      if(values.password.length === 0){
        payload = {
          name: values.userName,
          email: values.email,
          phone_number: values.phoneNumber,
          group_id: values.groups,
          admin_type: values.admin_type,
          is_admin: values.is_admin,
          company_id: values.company_id,

        };
      }else {
        payload = {
          
          name: values.userName,
          email: values.email,
          phone_number: values.phoneNumber,
          password: values.password,
          password_confirmation: values.confirmPassword,
          group_id: values.groups,
          admin_type: values.admin_type,
          company_id: values.company_id,
          is_admin: values.is_admin,
        };
      }


      console.log("payload",payload);

      editUser(payload);
      
    },
    
  });
  
/******************************************** */
  const [currentUser, setCurrentUser] = useState()//

  useEffect(() => {
    async function fetchLookups() {
      const res = await permissionsReload();
      setCurrentUser(res?.data?.email);
    }
    fetchLookups();

  }, []);

  return (
    <section>
      <div className="container p-0 my-4 ">
        <header className=" list_header border py-3 px-3">
          <div className="d-flex justify-content-between  ">
            <h3 className="text-muted font-weight-bold">
              {translate.editUser}
            </h3>
          </div>
        </header>
        {formik.values.email!= currentUser?(
        <form onSubmit={formik.handleSubmit}>
          <div className=" controls">
            <div className="row my-3">
              <div className="col-3 mr-3">
                <TextField
                  className={`WorkTeamInput ${
                    formik.touched.userName && formik.errors.userName
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  id="userName"
                  name="userName"
                  label={translate.userName}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                  placeholder={translate.enterUserName}
                />
              </div>
              <div className="col-3 mr-3">
                <TextField
                  className={`WorkTeamInput ${
                    formik.touched.email && formik.errors.email
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  id="email"
                  name="email"
                  label={translate.userEmail}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder={TeamManagement.userEmailPlaceholder}
                />
              </div>
              <div className="col-3 mr-3">
                <TextField
                  className={`WorkTeamInput ${
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  id="phoneNumber"
                  name="phoneNumber"
                  label={translate.phoneNumber}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  placeholder={TeamManagement.phoneNumberPlaceholder}
                />
              </div>
            </div>

            <div className="row my-3">
              <div className="col-3 mr-3">
                <FormControl
                  className={`WorkTeamInput ${
                    formik.touched.password && formik.errors.password
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  variant="filled"
                >
                  <InputLabel shrink={true} htmlFor="filled-adornment-password">
                    {translate.password}
                  </InputLabel>
                  <FilledInput
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    placeholder={TeamManagement.passwordPlaceholder}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {console.log("password",formik.values)}
                  {formik.touched.password &&
                  Boolean(formik.errors.password) ? (
                    <FormHelperText
                      className={"PasswordInputError"}
                      id="component-error-text"
                    >
                      {formik.errors.password}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className="col-3 mr-3">
                <FormControl
                  className={`WorkTeamInput ${
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  variant="filled"
                >
                  <InputLabel shrink={true} htmlFor="filled-adornment-password">
                    {translate.password_confirmation}
                  </InputLabel>
                  <FilledInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    placeholder={TeamManagement.repeatPasswordPlaceholder}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword) ? (
                    <FormHelperText
                      className={"PasswordInputError"}
                      id="component-error-text"
                    >
                      {formik.errors.confirmPassword}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className="col-3 mr-3">
                <FormControl
                  className={`WorkTeamInput ${
                    formik.touched.groups && formik.errors.groups
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  variant="filled"
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    shrink={true}
                  >
                    {translate.groups}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="groups"
                    value={formik.values.groups}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      let event = {
                        target: { name: "groups", value: selectedOption },
                      };
                      handleChangeGroups(event);
                      formik.handleChange(selectedOption);
                    }}
                  >
                    
                    {groupsDB?.length > 0
                      ? groupsDB?.map((group) => (
                          <MenuItem key={group.value} value={group.value}>
                            {group.label}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {formik.touched.groups && Boolean(formik.errors.groups) ? (
                    <FormHelperText
                      className={"GroupsInputError"}
                      id="component-error-text"
                    >
                      {formik.errors.groups}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
            </div>
          </div>

          <div className=" btns d-flex justify-content-between align align-items-end max-height">
            <NavLink
              exact={true}
              to={`/admin/team-management/users`}
              activeClassName="is-active"
            >
              <button className="btn btn-secondary px-5 py-2 ">
                {translate.back}
              </button>
            </NavLink>
            <button className="btn btn-success px-5 py-2 " type="submit">
              {translate.edit}
            </button>
          </div>

        </form>):(
           <form onSubmit={formik.handleSubmit}>
           <div className=" controls">
             <div className="row my-3">
               <div className="col-3 mr-3">
                 <TextField
                    disabled
                   className={`WorkTeamInput ${
                     formik.touched.userName && formik.errors.userName
                       ? "WorkTeamInputError"
                       : ""
                   }`}
                   id="userName"
                   name="userName"
                   label={translate.userName}
                   variant="filled"
                   InputLabelProps={{
                     shrink: true,
                   }}
                   value={formik.values.userName}
                   onChange={formik.handleChange}
                   error={
                     formik.touched.userName && Boolean(formik.errors.userName)
                   }
                   helperText={formik.touched.userName && formik.errors.userName}
                   placeholder={translate.enterUserName}
                 />
               </div>
               <div className="col-3 mr-3">
                 <TextField
                 disabled
                   className={`WorkTeamInput ${
                     formik.touched.email && formik.errors.email
                       ? "WorkTeamInputError"
                       : ""
                   }`}
                   id="email"
                   name="email"
                   label={translate.userEmail}
                   variant="filled"
                   InputLabelProps={{
                     shrink: true,
                   }}
                   value={formik.values.email}
                   onChange={formik.handleChange}
                   error={formik.touched.email && Boolean(formik.errors.email)}
                   helperText={formik.touched.email && formik.errors.email}
                   placeholder={TeamManagement.userEmailPlaceholder}
                 />
               </div>
               <div className="col-3 mr-3">
                 <TextField
                 disabled
                   className={`WorkTeamInput ${
                     formik.touched.phoneNumber && formik.errors.phoneNumber
                       ? "WorkTeamInputError"
                       : ""
                   }`}
                   id="phoneNumber"
                   name="phoneNumber"
                   label={translate.phoneNumber}
                   variant="filled"
                   InputLabelProps={{
                     shrink: true,
                   }}
                   value={formik.values.phoneNumber}
                   onChange={formik.handleChange}
                   error={
                     formik.touched.phoneNumber &&
                     Boolean(formik.errors.phoneNumber)
                   }
                   helperText={
                     formik.touched.phoneNumber && formik.errors.phoneNumber
                   }
                   placeholder={TeamManagement.phoneNumberPlaceholder}
                 />
               </div>
             </div>
 
             <div className="row my-3">
               <div className="col-3 mr-3">
                 <FormControl
                   className={`WorkTeamInput ${
                     formik.touched.password && formik.errors.password
                       ? "WorkTeamInputError"
                       : ""
                   }`}
                   variant="filled"
                 >
                   <InputLabel shrink={true} htmlFor="filled-adornment-password">
                     {translate.password}
                   </InputLabel>
                   <FilledInput
                     id="password"
                     name="password"
                     type={showPassword ? "text" : "password"}
                     value={formik.values.password}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.password && Boolean(formik.errors.password)
                     }
                     placeholder={TeamManagement.passwordPlaceholder}
                     endAdornment={
                       <InputAdornment position="end">
                         <IconButton
                           aria-label="toggle password visibility"
                           onClick={handleClickShowPassword}
                           onMouseDown={handleMouseDownPassword}
                           edge="end"
                         >
                           {showPassword ? <Visibility /> : <VisibilityOff />}
                         </IconButton>
                       </InputAdornment>
                     }
                   />
                   {console.log("password",formik.values)}
                   {formik.touched.password &&
                   Boolean(formik.errors.password) ? (
                     <FormHelperText
                       className={"PasswordInputError"}
                       id="component-error-text"
                     >
                       {formik.errors.password}
                     </FormHelperText>
                   ) : null}
                 </FormControl>
               </div>
               <div className="col-3 mr-3">
                 <FormControl
                   className={`WorkTeamInput ${
                     formik.touched.confirmPassword &&
                     formik.errors.confirmPassword
                       ? "WorkTeamInputError"
                       : ""
                   }`}
                   variant="filled"
                 >
                   <InputLabel shrink={true} htmlFor="filled-adornment-password">
                     {translate.password_confirmation}
                   </InputLabel>
                   <FilledInput
                     id="confirmPassword"
                     name="confirmPassword"
                     type={showConfirmPassword ? "text" : "password"}
                     value={formik.values.confirmPassword}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.confirmPassword &&
                       Boolean(formik.errors.confirmPassword)
                     }
                     placeholder={TeamManagement.repeatPasswordPlaceholder}
                     endAdornment={
                       <InputAdornment position="end">
                         <IconButton
                           aria-label="toggle password visibility"
                           onClick={handleClickShowConfirmPassword}
                           onMouseDown={handleMouseDownPassword}
                           edge="end"
                         >
                           {showConfirmPassword ? (
                             <Visibility />
                           ) : (
                             <VisibilityOff />
                           )}
                         </IconButton>
                       </InputAdornment>
                     }
                   />
                   {formik.touched.confirmPassword &&
                   Boolean(formik.errors.confirmPassword) ? (
                     <FormHelperText
                       className={"PasswordInputError"}
                       id="component-error-text"
                     >
                       {formik.errors.confirmPassword}
                     </FormHelperText>
                   ) : null}
                 </FormControl>
               </div>
               <div className="col-3 mr-3">
                 <FormControl
                 disabled
                   className={`WorkTeamInput ${
                     formik.touched.groups && formik.errors.groups
                       ? "WorkTeamInputError"
                       : ""
                   }`}
                   variant="filled"
                 >
                   <InputLabel
                     id="demo-simple-select-filled-label"
                     shrink={true}
                   >
                     {translate.groups}
                   </InputLabel>
                   { console.log("formik.values.groups",formik.values.groups) }
                   <Select
                     labelId="demo-simple-select-filled-label"
                     id="demo-simple-select-filled"
                     name="groups"
                     value={formik.values.groups}
                     onChange={(selectedOption) => {
                       console.log(selectedOption);
                       let event = {
                         target: { name: "groups", value: selectedOption },
                       };
                       handleChangeGroups(event);
                       formik.handleChange(selectedOption);
                     }}
                   >
                     
                     {groupsDB?.length > 0
                       ? groupsDB?.map((group) => (
                           <MenuItem key={group.value} value={group.value}>
                             {group.label}
                           </MenuItem>
                         ))
                       : null}
                   </Select>
                   {formik.touched.groups && Boolean(formik.errors.groups) ? (
                     <FormHelperText
                       className={"GroupsInputError"}
                       id="component-error-text"
                     >
                       {formik.errors.groups}
                     </FormHelperText>
                   ) : null}
                 </FormControl>
               </div>
             </div>
           </div>
 
           <div className=" btns d-flex justify-content-between align align-items-end max-height">
             <NavLink
               exact={true}
               to={`/team-management/users`}
               activeClassName="is-active"
             >
               <button className="btn btn-secondary px-5 py-2 ">
                 {translate.back}
               </button>
             </NavLink>
             <button className="btn btn-success px-5 py-2" type="submit">
               {translate.edit}
             </button>
           </div>
 
         </form>
        ) }



      </div>
    </section>
  );
};

export default Edit;


