import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Locale from "../../../translations";

export default function Request({ pilgram, toggle }) {
  const trip = { ...pilgram?.package };
  const { translate } = Locale;
  return (
    <tr>
      <td>{pilgram?.package?.company_name?.["ar"]}</td>
      <td>{pilgram?.traveller_name}</td>
      <td>{pilgram?.passport_number}</td>
      <td>{pilgram?.tenant_name}</td>
      <td>{pilgram?.visa_number}</td>
      <td>{trip?.package_name}</td>
      <td>{moment(pilgram?.return_date).format("DD/MM/YYYY")}</td>
      <td>{trip?.hotel?.name}</td>
      <td>{moment(trip?.return_date).format("DD/MM/YYYY")}</td>
      <td>{trip?.id}</td>
      <td>
        <button
          type="button"
          className="btn text-primary font-weight-bold"
          onClick={() => {
            toggle(pilgram?.package?.id);
          }}
        >
          {translate.supplyTrip}
        </button>
        <Link
          className="btn text-primary font-weight-bold"
          to={`/admin/Umrah/Medical/Lated/view/${pilgram?.package?.id}`}
        >
          {translate.view}
        </Link>
      </td>
    </tr>
  );
}
