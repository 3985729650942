import moment from 'moment';
import React from 'react'
import { useHistory, useParams } from 'react-router';
import ShowForPermission from '../../../../helpers/showForPermission';
import Locale from "../../../../translations";


function TravelerCheched({allData}) {
    let history = useHistory();
    const { translate } = Locale;
    let {id}=useParams()

 
    return (
      <>
      <tr className="border-green">
        <td>{allData.reservation_no}</td>

        <td>{allData.test_type}</td>
        <td> {allData?.traveller_name} </td>
        <td> {allData?.company_name} </td>
        <td>{allData?.reservation_type}</td>
        <td>
          <div className="d-flex flex-column">
            <span> {moment(allData?.check_date)?.format('yyyy-MM-DD')} </span>
            <span> {moment(allData?.check_date).format('hh:mm')}</span>
          </div>
        </td>
        <td>
          <div className="d-flex flex-column">
            <span>{(allData?.operationOrder_created_at)?.slice(0,10)}   </span>
            <span>{(allData?.operationOrder_created_at)?.slice(11,16)}</span>
          </div>
        </td>
        <td >{allData.amount} {translate.Pound}</td>
        <td className="text-success">{allData?.time_taken} {translate.hour}</td>
        <td className="text-orange">{allData?.comission} {translate.Pound}</td>
        <td className="text-orange">{allData?.safa_comission} {translate.Pound}</td>
        <td>
        <ShowForPermission permission={"view-reservation"}>
          <button className="text-primary mr-3" onClick={() => {
            history.push(`/admin/ordersList/checked/view/${id}/order/${allData.id}`);
          }}><i class="fas fa-chevron-left"></i></button>
          </ShowForPermission>
        </td>
      </tr>





 
</>
    );
}

export default TravelerCheched;
