import React, { useEffect, useState } from "react";
//import Locale from "../../../translations";
import { useHistory, useParams } from 'react-router';
import { viewCompany } from "../../../../services/admin";
import Locale from "../../../../translations";
import '../style.scss';

export default function PaymentCost({paymentNumbers}) {
  const { translate } = Locale;
  let history = useHistory();

//listTravellersById
const [company, setCompany] = useState([]);
let { id } = useParams();
//  useEffect(() => {
//   async function fetchData() {
//     const res = await viewCompany(id);
//     setCompany(res?.data?.data);
//   }
//   fetchData();
// }, []);

let dollarUSLocale = Intl.NumberFormat('en-US');

  return (
    <>
    <div className="container-fluid p-0 " >
      <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline my-4">
        <h3 className="text-muted font-weight-bold">
       {translate.MedicalServicesCompanyName}     </h3>
      </header>

      <div className="payment-cost px-5 py-4" >
        {/* *************************************************************************/}
        

        <div className="boxs d-flex justify-content-between align-items-center">
          <div className="box mx-3">
            <div className="layout text-center">
              <h3 className=" font-weight-bold"> {translate.TotalOrders} </h3>
              <h4 className="text-primary font-weight-bold" style={{fontSize:"32px"}}>{paymentNumbers?.revs_number}</h4>
              <p className=" font-weight-bold">{translate.RequestMedicalService}</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">{translate.totalCost}</h3>
              <h4 className="text-primary font-weight-bold" style={{fontSize:"32px"}}>{dollarUSLocale.format(paymentNumbers?.revs_total_coast)}</h4>
              <p className=" font-weight-bold">{translate.egyPound}</p>
            </div>
          </div>

          <div className="box mx-3">
            <div className="layout text-center">
              <h3 className=" font-weight-bold"> {translate.deservedAmount} </h3>
              <h4 className="text-primary font-weight-bold" style={{fontSize:"32px"}}>{dollarUSLocale.format(paymentNumbers?.revs_due_amount)}</h4>
              <p className=" font-weight-bold"> {translate.egyPound}</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold"> {translate.safaCommission}  </h3>
              <h4 className="text-primary font-weight-bold" style={{fontSize:"32px"}}>{dollarUSLocale.format(paymentNumbers?.revs_safa_commission)}</h4>
              <p className=" font-weight-bold">{translate.egyPound} </p>
            </div>
          </div>

          <div className="box mx-3">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">{translate.received}</h3>
              <h4 className="text-success font-weight-bold" style={{fontSize:"32px"}}>{dollarUSLocale.format(paymentNumbers?.revs_paid_amount)}
               </h4>
              <p className=" font-weight-bold">{translate.egyPound}</p>
            </div>
          </div>

          <div className="box">
            <div className="layout text-center">
              <h3 className=" font-weight-bold">{translate.remainder}   </h3>
              <h4 className="text-danger font-weight-bold" style={{fontSize:"32px"}} >{dollarUSLocale.format(paymentNumbers?.revs_remaining_amount)}</h4>
              <p className=" font-weight-bold"> {translate.egyPound}</p>
            </div>
          </div>

        </div>

        
      </div>




      
    </div>


   </>
  );
}
