
import React from "react";
import Traveler from "./traveler";
import Locale from "../../../../translations";
import Pagination from "../../../../components/shared/Pagination";

export default function ListTravelers({allData ,filter,setfilter,meta,goTo}) {
  const { translate } = Locale;
 
  const AllTravelers =
  
  allData?.length > 0
    ? allData.map((data, index) => (
        <Traveler
         traveler={data}
          index={index}
          //fetchData={fetchData} 
         
        />
      ))
    : [];

    // const goTo = async (page) => {
    //   setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
    //   console.log("filterfilterfilterfilterfilter",filter)
    // };

   
  return (
    <>
       <table className="table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col">{translate.orderNumber}</th>
            <th scope="col">{translate.typeCheck}</th>
            <th scope="col">{translate.client}</th>
            <th scope="col">{translate.companyName}</th>
            <th scope="col">{translate.examinationMethod}</th>
            <th scope="col">{translate.DateRequest}</th>
            <th scope="col">{translate.timeRequest}</th>
            <th scope="col">{translate.Cost}</th>
            <th scope="col">{translate.companyPercentage}</th>
            <th scope="col">{translate.safaPercentage}</th>
            <th scope="col"></th>

          </tr>
        </thead>
        <tbody>
        {AllTravelers.length > 0 ? (
												AllTravelers
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
        </tbody>
      </table>
      
      <Pagination info={meta} goTo={goTo} items={10} />  
        
    </>
  );
}
