import React, { useEffect, useState } from "react";
import {
  saveReservationResult,
  viewPendingTravelers,
  viewTravelers,
} from "../../../services/UmrahRequests";
import { useParams } from "react-router";
import Locale from "../../../translations";
import ViewTraveller from "./ViewTraveller";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import SelectField from "../../../components/shared/SelectField";
import { useCompanyState } from "../../../context/global";
import { store } from "react-notifications-component";
import { viewSaudiPackages } from "../../../services/admin";

export default function ViewTravellersLated() {
  const { id, status } = useParams();
  const { currentLanguage } = useCompanyState();
  const [saving, setSaving] = useState(false);
  const { translate } = Locale;
  const [isOpen, setIsOpen] = useState(false);

  const [result, setResult] = useState({
    file: null,
    status: null,
    id: null,
  });

  const optionsTest = [
    { value: "positive", label: translate.positive },
    { value: "negative", label: translate.negative },
  ];

  const toggle = (id = null) => {
    setIsOpen(!isOpen);
    setResult({
      ...result,
      id: id,
    });
  };

  const [trip, settrip] = useState();
  const [travellers, setTravellers] = useState();

  const saveResult = async () => {
    const formData = new FormData();
    console.log(result);
    formData.append("file", result.file);
    formData.append("status", result.status.value);

    const res = await saveReservationResult(result.id, formData);
    toggle();
    setResult({ file: null, status: null, id: null });

    if (res?.message) {
      store.addNotification({
        title: "info!",
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    setSaving(!saving);
  };

  useEffect(async () => {
    /*  let res;
    if (status) {
      res = await viewPendingTravelers(id);
      setTravellers(res.reservations);
    } else {
      res = await viewTravelers(id);
      setTravellers(res.travellers);
    } */

    const res = await viewSaudiPackages(id);
     setTravellers(res?.travellers);
    settrip(res);
  }, [saving]);

  const AllClients =
    travellers &&
    travellers.length > 0 &&
    travellers.map((data, index) => (
      <ViewTraveller data={data} key={index} index={index} toggle={toggle} />
    ));

  return (
    <>
      <div className="container container bg-white py-3 mt-2">
        <div className="py-2 p-3  border instructions rounded mt-3">
          <h5 className="font-weight-bold text_blue-dark mt-2">
            {translate.TripData} / {translate.hotelData}
          </h5>
          <div className="row">
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.TripNumber}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">{trip?.id}</p>

            <p className="col-6  col-md-2  font-mobile h5 font-weight-bold">
              {translate.TripNames}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5 ">
              {trip?.package_name}
            </p>
            {/*       <p className="col-6  col-md-2  font-mobile h5  font-weight-bold">
              {translate.returnDate}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5">
              {" "}
              {trip?.return_date}
            </p> */}
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.hotelName}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {" "}
              {trip?.hotel?.name}
            </p>
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.address}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.address}
            </p>
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.city}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.city}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.area}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.area}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.dateIn}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.entry_date}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.dateOut}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.exit_date}
            </p>
          </div>
        </div>

        <div className="py-2 p-3  border instructions rounded mt-3">
          <h5 className="font-weight-bold text_blue-dark mt-2">
            {translate.SupervisorData}
          </h5>
          <div className="row">
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.SupervisorName}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {trip?.SaSupervisor?.name}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.PhoneEG}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {trip?.SaSupervisor?.egypt_phone}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.PhoneSaudi}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {trip?.SaSupervisor?.saudi_phone}
            </p>
          </div>
        </div>

        {AllClients}

        {status === "checked" ? (
          <Link
            className="btn btn-secondary px-4 mt-5"
            to={"/Umrah/Medical/Requests/checked"}
          >
            {translate.back}
          </Link>
        ) : status === "pending" ? (
          <Link
            className="btn btn-secondary px-4 mt-5"
            to={"/Umrah/Medical/Requests/pending"}
          >
            {translate.back}
          </Link>
        ) : (
          <Link
            className="btn btn-secondary px-4 mt-5"
            to="/admin/pilgrims/lated"
          >
            {translate.back}
          </Link>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setResult({ file: null, status: null, id: null });
        }}
        className="lan "
      >
        <div className="lan d-flex justify-content-center align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary">{translate.addResult}</p>
          {/*      <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={() => {
              toggle({
                employee_id: null,
                check_date: null,
                check_time: null,
                display_date: new Date(),
              });
            }}
          ></i> */}
        </div>
        <ModalBody className=" d-flex flex-column align-items-center py-4">
          <div
            className="mb-4 w-50 "
            style={{
              textAlign: currentLanguage === "ar" ? "right" : "left",
            }}
          >
            <SelectField
              hasLabel={true}
              onFocus={false}
              placeholder={translate.examinationStatus}
              label={translate.status}
              value={result.status}
              options={optionsTest}
              onChange={(e) => {
                setResult({
                  ...result,
                  status: e,
                });
              }}
            />
          </div>
          <div className="border w-50 mt-3 p-3 rounded">
            <input
              required
              accept="application/pdf, .pdf"
              type="file"
              id="file"
              onChange={(e) => {
                setResult({ ...result, file: e.target.files[0] });
              }}
            />
          </div>
        </ModalBody>
        <div className="pb-5 border-top-0  text-center    p-0 ">
          <button
            className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
            onClick={saveResult}
          >
            {translate.addResult}
          </button>
        </div>
      </Modal>
    </>
  );
}
