import React, { useEffect, useState } from "react";
import SelectField from "../../../components/shared/SelectField";
import { store } from "react-notifications-component";

import Locale from "../../../translations";
import Filter from "./Filter";
import Request from "./Request";
import moment from "moment";
import {
  addCompaniesPackages,
  exportExeclLatedPilgramsAdmin,
  getLatedAdmin,
  listCompanies,
} from "../../../services/admin";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import {
  createOperationOrder,
  listEmployees,
} from "../../../services/UmrahRequests";
import { useCompanyState } from "../../../context/global";

export default function LatedAdmin() {
  const { translate } = Locale;
  const [sorted, setSorted] = useState(false)
  const [listRequests, setListRequests] = useState(
    []
  );
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const { currentLanguage } = useCompanyState();

  const [searching, setSearching] = useState(false);
  const [meta, setMeta] = useState();
  const [companies, setCompanies] = useState();
  const [operations, setOperations] = useState({
    company_id: null,
  });
  const [filter, setFilter] = useState({
    visa_number: "",
    passport_number: "",
    return_date: "",
    company_name : "",
  });

  const toggle = (id = null) => {
    setIsOpen(!isOpen);
    setOperations({ ...operations, package_id: id });
  };

  const allRequests =
    listRequests && listRequests.length > 0
      ? listRequests.map((item, index) => {
          return <Request pilgram={item} key={index} toggle={toggle} />;
        })
      : null;

  const sumbit = () => {
    setSearching(!searching);
  };

  useEffect(async () => {
    const res = await getLatedAdmin({
      ...filter,
      return_date: filter.return_date
        ? moment(filter.return_date).format("YYYY-MM-DD")
        : null,
    });
    if (res) {
      setListRequests(res.data);
    }
  }, [searching]);

  //Excel Function
  const openExcel = async () => {
    const res = await exportExeclLatedPilgramsAdmin({
      ...filter,
      return_date: filter.return_date
        ? moment(filter.return_date).format("YYYY-MM-DD")
        : null,
      Authorization: token,
    });
    if (res) {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };
  const sortByDueDate = () => {
    let x
    if (sorted) {
     x = listRequests.sort((a, b) => new Date(a.return_date).getTime() - new Date(b.return_date).getTime())
    }else{
      x = listRequests.sort((a, b) => new Date(b.return_date).getTime() - new Date(a.return_date).getTime())
    }
    setSorted(!sorted)
    setListRequests(x)

  };
  // trip supply
  useEffect(async () => {
    const res = await listCompanies();

    const formatted = res?.companies?.map((employee) => {
      return { value: employee.id, label: employee.name };
    });
    setCompanies(formatted);
  }, []);

  const createOperation = async () => {
    const res = await addCompaniesPackages({
      company_id: operations.company_id.value,
      packages_ids: [operations.package_id],
    });

    toggle();
    setSearching((prev) => !prev);

    if (res?.message == "تم التعديل بنجاح") {
      store.addNotification({
        title: "info!",
        message: "تم التعديل بنجاح",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };

  return (
    <>
      {" "}
      <div className="container p-0 my-4 orders-list lan">
        <header className="list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">
            {translate.pilgrimsLated}
          </h3>
          <button className="btn btn-success" onClick={openExcel}>
            {translate.export_excel}
            <i class="fas fa-file-excel  mx-2 "></i>
          </button>
        </header>
        <Filter filter={filter} setFilter={setFilter} sumbit={sumbit} />

        <table className="table-responsive-lg table table-travelers">
          <thead className="bg_table ">
            <tr className="table_head">
              <th scope="col"> اسم الشركه </th>
              <th scope="col"> {translate.pilgrimName} </th>
              <th scope="col">{translate.passportNumber}</th>
              <th scope="col">{translate.labName}</th>
              <th scope="col">{translate.visa_number}</th>

              <th scope="col">{translate.TripNames}</th>
              <th scope="col" role="button" onClick={()=>sortByDueDate()}>{translate.newReturnDate}
           
                <span className="new-sort" >
                {sorted ? <i class="fas fa-sort-amount-down	" aria-hidden="true"></i> : <i class="fas fa-sort-amount-up	" aria-hidden="true"></i>}    
                   

                  </span>
              </th>
              <th scope="col">{translate.hotelName}</th>
              <th scope="col">{translate.tripReturnDate}</th>
              <th scope="col">{translate.TripNumber}</th>
              <th scope="col">{translate.processes}</th>
            </tr>
          </thead>
          <tbody>
            {allRequests?.length > 0 ? (
              allRequests
            ) : (
              <tr>
                <td colSpan="10" className="text-center my-3">
                  <div className="product-build__product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{translate.noResult}</h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* <Pagination info={meta} goTo={goTo} items={10} /> */}
      </div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">{translate.runOrder}</p>
          <i className="far fa-times-circle fa-lg text-secondary " onClick={toggle}></i>
        </div>

        <ModalBody className="lan  d-flex flex-column justify-content-center align-items-center ">
          <div
            className="mb-4 w-50"
            style={{
              textAlign: currentLanguage === "ar" ? "right" : "left",
            }}
          >
            <SelectField
              hasLabel={true}
              onFocus={false}
              placeholder={translate.companyName}
              label={translate.companyName}
              options={companies}
              value={operations.company_id}
              onChange={(e) => {
                setOperations({ ...operations, company_id: e });
              }}
            />
          </div>
        </ModalBody>
        <div className="pb-5 border-top-0  text-center    p-0 ">
          <button
            className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
            onClick={createOperation}
          >
            توريد
          </button>
        </div>
      </Modal>
    </>
  );
}
