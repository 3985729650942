import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { allReceipts } from "../../../../services/admin";
import Locale from "../../../../translations";
import ListInvoices from "./ListInvoices";

export default function Invoices() {
  const { id } = useParams();
  let history = useHistory();
  const { translate } = Locale;

  const [Invoices, setInvoices] = useState();
  const [chanahing, setcChanahing] = useState(false);

  useEffect(async () => {
    const res = await allReceipts(id);
    setInvoices(res.data.data);
  }, [chanahing]);

  return (
    <div className="container mt-5">
      <header className=" list_header border p-3 d-flex justify-content-between align-items-baseline">
        <h3 className="text-muted font-weight-bold">{translate.Invoices}</h3>
      </header>

      <ListInvoices Invoices={Invoices} setcChanahing={setcChanahing} />
    </div>
  );
}
