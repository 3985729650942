import Locale from "../../../translations";
import Request from "./Request";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useCompanyState } from "../../../context/global";
import SelectField from "../../../components/shared/SelectField";
import TextAreaField from "../../../components/shared/TextAreaField";
import TimeDatePicker from "../../../components/shared/TimeDatePicker";
import { createOperationOrder } from "../../../services/UmrahRequests";
import moment from "moment";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ListRequests({ listRequests, setSearching, id }) {
  let myFutureDate = new Date();
  myFutureDate.setDate(myFutureDate.getDate() + 5);
  const { status } = useParams();

  const { translate } = Locale;
  const [isOpen, setIsOpen] = useState(false);
  const { currentLanguage } = useCompanyState();

  const toggle = (operationData = null) => {
    setIsOpen(!isOpen);

    setOperation({
      ...operation,
      ...operationData,
      package_id: operationData?.id?.toString(),
      id: null,
    });
  };

  const [employees, setEmployees] = useState();

  const [operation, setOperation] = useState({
    employee_id: null,
    check_date: null,
    check_time: null,
    display_date: new Date(),
  });

  const allRequests =
    listRequests?.length > 0 &&
    listRequests?.map((request, index) => {
      return (
        <Request id={id} request={request} toggle={toggle} index={index} />
      );
    });

  const createOperation = async () => {
    const res = await createOperationOrder({
      ...operation,
      employee_id: operation.employee_id.value,
      display_date: null,
    });

    toggle();
    setSearching((prev) => !prev);
    if (res?.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };

  return (
    <>
      <table className="table-responsive-lg table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col"> {translate.TripName} </th>
            <th scope="col">{translate.companyName}</th>
            <th scope="col">{translate.passengerNumber}</th>
            {status != "checked" ? (
              <th scope="col">{translate.LatedPilgrams}</th>
            ) : null}

            <th scope="col">{translate.hotelName}</th>
            <th scope="col">{translate.dateIn}</th>
            <th scope="col">{translate.dateOut}</th>

            <th scope="col">{translate.city}</th>
            <th scope="col">{translate.SupervisorName}</th>

            {status != "orders" ? (
              <th scope="col">{translate.DateRequest}</th>
            ) : null}

            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {allRequests?.length > 0 ? (
            allRequests
          ) : (
            <tr>
              <td colSpan="10" className="text-center my-3">
                <div className="product-build__product-no-data">
                  <i className="fas fa-info-circle fa-lg"></i>{" "}
                  <h4>{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
