import React, { useState } from "react";
import TextField from "../../../../components/shared/textField";
import Locale from "../../../../translations";

export default function PilgarmsCount({ counts, sumbitReceipt }) {
  const { translate } = Locale;

  const [amount, setAmount] = useState(0);
  const [count, setCount] = useState(0);
  return (
    <div className=" row m-0 bg-white  px-3  py-3 lan">
      <div className=" col-4 instructions rounded p-2 mx-1 d-flex justify-content-between align-items-center ">
        <p className="  m-0 ">
          <i class="fas fa-users mx-2 text-primary"></i>
          {translate.Total} {translate.pilgrimNumbers} ( {translate.Notpaid} ) :{" "}
          <span className="text-danger font-weight-bold">
            {counts.pilgrams}
          </span>
        </p>
      </div>

      <div className="col-5 instructions rounded p-2 mx-1  d-flex justify-content-between align-items-center">
        <p className="  m-0">
          <i class="fas fa-money-bill-wave mx-2 text-primary "></i>
          {translate.totalAmount} :{" "}
          <span className="text-success font-weight-bold">{amount}</span>{" "}
          {translate.Sar}
        </p>

        <div className="w-40">
          <TextField
            min="0"
            hasLabel={false}
            type={"number"}
            placeholder={translate.exchangeFactor}
            onChange={(e) => {
              if (e.target.value >= 0) {
                setAmount(e.target.value * counts.totalPrice);
              }
              setCount(e.target.value);
            }}
            color={count < 0 ? "danger" : ""}
          ></TextField>
        </div>
      </div>
      <div className="p-2">
        <button
          disabled={count <=0 || amount <=0}
          className="btn btn-success font-weight-bold mx-2  px-3"
          onClick={() => {
            sumbitReceipt(count);
          }}
        >
          {translate.Issuanceinvoice}
        </button>
      </div>
    </div>
  );
}
