import React from "react";
import { useHistory } from "react-router";
import { Table } from "reactstrap";

import Locale from "../../../../translations";
import UsersFilter from "./usersFilter";
import User from "./User";

export default function UsersAdmin() {
  const { translate } = Locale;
  /** */
  let history = useHistory();

  const users = [
    {
      id: 1,
      name: "company_user",
      email: "companyUser010@email",
      phone_number: "1038745834",
      status: "active",
    },
    {
      id: 2,
      name: "company_user",
      email: "companyUser010@email",
      phone_number: "1038745834",
      status: "pending",
    },
    {
      id: 3,
      name: "company_user",
      email: "companyUser010@email",
      phone_number: "1038745834",
      status: "active",
    },
  ];

  const AllUsers =
    users && users.length > 0
      ? users.map((user, index) => <User user={user} index={index} />)
      : "";

  /** */

  return (
    <div className="container p-0">
      <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">{translate.users}</h3>
          
            <button
              type="button"
              className="btn btn-success px-4 py-2 font-weight-bold "
              onClick={() => {
                history.push(`/admin/ComanyMedical/add-user`);
              }}
             
            >
              <i className="fas fa-plus-circle mr-2"></i> {translate.addUser}
            </button>
          
        </div>
      <UsersFilter />
      <div className="  p-0">
        <Table>
          <thead>
            <tr className="text-grey">
              <th> {translate.userName}</th>
              <th>{translate.userEmail}</th>
              <th>{translate.mobileNumber}</th>

              <th>{translate.status}</th>
              <th>{translate.tools}</th>
            </tr>
          </thead>
          <tbody className="table-stripe ">{AllUsers}</tbody>
        </Table>

        <div></div>
      </div>
    </div>
  );
}
