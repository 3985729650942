import axios from "axios";
import { cleanEmpty } from "./general";

const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");
const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
//export const SafarnaURL = `https://${domain}/api`;
const CheckURL = `https://${subdomain}.${domain}/api/tenant`;
/* export const CheckURL = `http://${domain}/api/tenant`;
 */
const lookupsUrl = process.env.REACT_APP_API_URL + "/api/lookups";





export const fetchCitiesSaudi = async () => {
  return await axios
    .get(CheckURL + `/lookups/cities?country_id=966`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchAreaSaudi = async (id) => {
  return await axios
    .get(CheckURL + `/lookups/areas?city_id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchHotelsSaudi = async (id) => {
  return await axios
    .get(CheckURL + `/lookups/hotels?area_id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const listTravelers = async (params, status) => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers${status ? "/" + status : ""}`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const listTravelersURl = async (Url,data) => {
  return await axios
    .get(Url,{
      params: cleanEmpty(data),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const viewTravelers = async (id) => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers/view/${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const viewPendingTravelers = async (id , params) => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers/${id}`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const listEmployees = async () => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers/employees`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const createOperationOrder = async (data) => {
  return await axios
    .post(CheckURL + `/labs/saudi-travellers/order`, data)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const saveReservationResult = async (id, data) => {
  return await axios
    .post(CheckURL + `/labs/saudi-travellers/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err.response);
};



export const exportExecl = async (params, status) => {

  return await axios
    .get(
      CheckURL +
        `/labs/saudi-travellers/export${status ? "/" + status : ""}`,
      {
        responseType:'blob',
        params: cleanEmpty(params),
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const exportExeclView = async (params, id) => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers/export/${id}`, {
      responseType: "blob",
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};


export const ChangeExaminationDate = async (data, id) => {
  return await axios
    .put(CheckURL + `/labs/saudi-travellers/change-time/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err.response);
};



export const getLatedPilgrams = async (params, status) => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers/delayed`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};


export const exportExeclLatedPilgrams = async (params) => {
  return await axios
    .get(CheckURL + `/labs/saudi-travellers/export/delayed`, {
      responseType: "blob",
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};


export const exportExeclOrders = async (params, status) => {
  return await axios
    .get(
      CheckURL +
        `/labs/saudi-travellers/export/${status ?  status + "/"  : ""}travellers`,
      {
        responseType: "blob",
        params: cleanEmpty(params),
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};
