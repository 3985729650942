import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ShowForPermission from "../../../helpers/showForPermission";
import { permissionsReload } from "../../../services/admin";
import Locale from "../../../translations";



export default function User(props) {
  const { user } = props;
  //const { Labs } = Locale;
  const { translate } = Locale;

  console.log(props);

const [currentUser, setCurrentUser] = useState()//"testuser@ff.com"

  useEffect(() => {
    async function fetchLookups() {
      const res = await permissionsReload();
      setCurrentUser(res?.data?.email);
    }
    fetchLookups();

  }, []);


console.log("currentUser",currentUser);
  return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.phone_number}</td>
        <td>{user.group}</td>
        <td className={user.status === "active" ? "text-green" : "text-danger"}>
          {" "}
          {user.status == "inactive"
            ? `${translate.deactive}`
            : `${translate.active}`}
        </td>
        <td>
          <div className="actions d-flex justify-content-around">
            {/* <span className="text-primary"> */}
            <ShowForPermission
              permission={["edit-admin", "view-admin"]}
              type={"and"}
            >
              <NavLink
                exact={true}
                to={`/admin/team-management/users/edit/` + user.id}
                activeClassName="is-active"
                className="p-2 text-primary  btn-link "
              >
                <i className="fas fa-edit"></i>
                {translate.edit}
              </NavLink>
            </ShowForPermission>


{user.email != currentUser?(
  <>
{user.status === "active" ? (
  <ShowForPermission permission={"activate-admin"}>
    <button
      type="button"
      onClick={() => props.changeStatusUser(user.id, "deactivate")}
      className="btn btn-link text-danger stopping "//mr-3
      style={{ width: "70px" }}
    >
      {/* <div className="square-stop-container">
        <span className="square-stop"></span>
      </div> */}
      <i className="fas fa-stop-circle"></i>  {translate.deactive}
    </button>
  </ShowForPermission>
) : (
  <ShowForPermission permission={"activate-admin"}>
    <button
      type="button"
      onClick={() => props.changeStatusUser(user.id, "activate")}
      className="btn btn-link text-success "//mr-3
     
    >
      <i class="far fa-play-circle"></i> {translate.active}

    </button>
  </ShowForPermission>
)}
<ShowForPermission permission={"delete-admin"}>
  <button
    className="btn btn-danger "//mr-3
    onClick={() => props.deleteUserHandler(user.id)}
  >
    {translate.remove}
  </button>
</ShowForPermission>

</>
):""}
            






{/* </span> */}

{/* <button onClick={() => props.togglePassword(user.id)}>
  {" "}
  <span className="mx-3 text-primary btn-link">
    <i className="fas fa-lock"></i> {translate.changePassword}
  </span>{" "}
</button> */}



          </div>
        </td>
      </tr>
      {/* 
			<tr>
				<td>{user.name}</td>
				<td>{user.email}</td>
				<td> {user.group}</td>
				<td>
					{user.status == "active" ? (
						<i className="far fa-check-circle btn fa-2x text-success"></i>
					) : (
						<i class="far fa-times-circle btn fa-2x text-danger"></i>
					)}
				</td>

				<td className="w-10">
					
						<button
							type="button"
							onClick={() => props.toggleEdit(user.id)}
							className="btn btn-link text-gray"
						>
							<i className="far fa-edit"></i> {Labs.edit}
						</button>
				
				</td>
				<td className="w-10">
					{user.status == "active" ? (
					
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "deactivate")}
								className="btn btn-link text-danger"
							>
								<i className="fas fa-stop"></i>
								{Labs.deactive}
							</button>
					) : (
						
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "activate")}
								className="btn btn-link text-success"
							>
								<i class="far fa-play-circle"></i>
								{Labs.active}
							</button>
						
					)}
				</td>
				<td className="w-10">
					
						<button
							type="button"
							onClick={() => props.togglePassword(user.id)}
							className="text-gray btn btn-link "
						>
							<i className="fas fa-unlock-alt"></i>
							{Labs.changePassword}
						</button>
					
				</td>
				<td className="w-10">
				 <button type="button" className="btn btn-danger">
						حذف
					</button> 
				</td>
			</tr>
			*/}
    </>
  );
}
