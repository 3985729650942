
import React,{useEffect, useState} from "react";
import { companiesDrop } from "../../../services/admin";
import Locale from "../../../translations";
import AddService from "./addService";

export default function Services({company , setCompany}){
    const { translate } = Locale;
    const [checkTypes, setCheckTypes] = useState([]);
    const [reservationTypes, setReservationTypes] = useState([]);
    const [checkGradeTypes, setCheckGradeTypes] = useState([]);
    const [comission, setComission] = useState([])
    const deleteFn =(index)=>{
      company.services.splice(index,1)
      setCompany({...company,services: company.services})
      
      {/*********SetCompany******* */}
    }
  /**api */
  //companiesDrop
  useEffect(() => {
    async function fetchData() {
      const res = await companiesDrop();
      let formatted = res?.data?.checkTypes.map((data) => ({
        value: data?.id,
        label: data?.name,
      }));
      setCheckTypes(formatted);

      let formatted1 = res?.data?.reservationTypes.map((data) => ({
        value: data?.id,
        label: data?.name,
      }));
      setReservationTypes(formatted1);
      let formatted2 = res?.data?.checkGradeTypes.map((data) => ({
        value: data?.id,
        label: data?.name,
      }));
      setCheckGradeTypes(formatted2);

      //commissionOptions

      // let formatted3 = res?.data?.comission_type.map((data) => ({
      //   value: data?.id,
      //   label: data?.name,
      // }));
      // setComission(formatted3);
    }
    fetchData();

  }, []);

    return (
        <>
          <div className="container py-5  bg-white">
          {company?.services && company?.services.length > 0 ? company?.services.map((res,index)=>
          {
          return <AddService key={index+"asdasd"} index={index} checkTypes={checkTypes} checkGradeTypes={checkGradeTypes} reservationTypes={reservationTypes} comission={comission} data={res} deleteFn={deleteFn} company={company} setCompany={setCompany} />}
          ) : null}

              <div className="text-center"> 
             
                <button className="btn text-success btn-lg " onClick={() => setCompany({...company,services:[...company.services,{}]}) }><i class="fas fa-plus-circle"></i> {translate.add} {translate.service}</button>
              
              </div>

              
        </div>
      </>
    );
}