import React, { useEffect, useState } from "react";
import { exportExeclLatedPilgrams, getLatedPilgrams } from "../../../services/UmrahRequests";
import Locale from "../../../translations";
import Filter from "./Filter";
import Request from "./Request";
import moment from "moment";

export default function PilgrimsLated() {
  const { translate } = Locale;
  const [listRequests, setListRequests] = useState([]);
  const token = localStorage.getItem("token");

  const [searching, setSearching] = useState(false);
  const [meta, setMeta] = useState();

  const [filter, setFilter] = useState({
    visa_number: "",
    passport_number: "",
    return_date: "",
  });

  const allRequests =
    listRequests && listRequests.length > 0
      ? listRequests.map((item, index) => {
          return <Request pilgram={item} key={index} />;
        })
      : null;

  const sumbit = () => {
    setSearching(!searching);
  };

 
  useEffect(async () => {
    const res = await getLatedPilgrams({
      ...filter,
      return_date: filter.return_date
        ? moment(filter.return_date).format("YYYY-MM-DD")
        : null,
    });
    if (res) {
      setListRequests(res.data);
    }
  }, [searching]);

  //Excel Function
  const openExcel = async () => {
    const res = await exportExeclLatedPilgrams({
      ...filter,
      return_date: filter.return_date
        ? moment(filter.return_date).format("YYYY-MM-DD")
        : null,
      Authorization: token,
    });
    if (res) {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <>
      {" "}
      <div className="container p-0 my-4 orders-list lan">
        <header className="list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">
            {translate.pilgrimsLated}
          </h3>
          <button className="btn btn-success" onClick={openExcel}>
            {translate.export_excel}
            <i class="fas fa-file-excel  mx-2 "></i>
          </button>
        </header>
        <Filter filter={filter} setFilter={setFilter} sumbit={sumbit} />

        <table className="table-responsive-lg table table-travelers">
          <thead className="bg_table ">
            <tr className="table_head">
              <th scope="col"> {translate.pilgrimName} </th>
              <th scope="col">{translate.passportNumber}</th>
              <th scope="col">{translate.BorderNumber}</th>
              <th scope="col">{translate.visa_number}</th>

              <th scope="col">{translate.TripNames}</th>
              <th scope="col">
                {translate.newReturnDate}
     
              
              </th>
              <th scope="col">{translate.hotelName}</th>
              <th scope="col">{translate.tripReturnDate}</th>
              <th scope="col">{translate.TripNumber}</th>
            </tr>
          </thead>
          <tbody>
            {allRequests?.length > 0 ? (
              allRequests
            ) : (
              <tr>
                <td colSpan="10" className="text-center my-3">
                  <div className="product-build__product-no-data">
                    <i className="fas fa-info-circle fa-lg"></i>{" "}
                    <h4>{translate.noResult}</h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* <Pagination info={meta} goTo={goTo} items={10} /> */}
      </div>
    </>
  );
}
