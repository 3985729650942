
import React, { useEffect, useState } from "react";
//import { createOperationOrder, listEmployees, listTravellers } from "../../../services/yarvel";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
//import SelectField from "../../components/shared/SelectField";
import Traveler from "./traveler";
import { useCompanyState } from "../../../../context/global";
import { useHistory } from "react-router";
import SelectField from "../../../../components/shared/SelectField";
import { createOperationOrder, listEmployees, listTravellers } from "../../../../services/yarvel";
import Locale from "../../../../translations";
import Pagination from "../../../../components/shared/Pagination";

export default function ListTravelers({allData,filter,setfilter,meta,goTo}) {
  const { translate } = Locale;
  // call api for progress
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectedValue, setSelectedValue] = useState(" ");
  const [travelers, setTravelers] = useState([]);
  const [optionsEmployee, setOptionsEmployee] = useState(["taha","taha"]);
  const { currentLanguage } = useCompanyState();
  const [employee, setEmployee] = useState([]);
  let CompanyState = useCompanyState();
  const [SeletedId, setSeletedId] = useState({});

	const fetchData = async () => {
		const Travelers = await listTravellers();
		setTravelers(Travelers.data?.data);
	};

  const [toggleEmployee, setToggleEmployee] = useState(false)
  const userType =
  localStorage.getItem("userType");
console.log("CompanyState.permissions", CompanyState.permissions);
	useEffect(() => {
		async function fetchData() {
			const Travelers = await listTravellers();
			setTravelers(Travelers?.data?.data); 
      
      //if(CompanyState?.user_type == "company-super-admin"){      {/**    .permissions=='[]' */}
        const Employees = await listEmployees();
        setEmployee(Employees?.data);
        let formatted = Employees?.data?.map((emp) => ({
          value: emp?.id,
          label: emp?.name,
        }));
        setOptionsEmployee(formatted);
     // }
    }
    if(userType == "company-super-admin"){
    fetchData();
    }
	}, []);

  console.log("CompanyState?.user_type",userType);


  let history = useHistory();
  const createOperationOrderClick = async (traveler,selectedValue) => {
    
     let data={"reservation_id":traveler,"employee_id":selectedValue}
     const res = await createOperationOrder( data );
     
     if (res.status=='200') {
      
        fetchData();
        setModalShow(false)
        setSeletedId(null)
        history.push(`/CustomersList/view-operation/${traveler}`);
        ///CheckMe/CustomersList/view-operation/:id
     }
    
   }

   const toggleModalShow = (id) => {
    setSeletedId(id)
    setModalShow(!modalShow);
  
  };

  const handleChange = e => {
    setSelectedValue(e.value);
  }


  const AllTravelers =
  
  allData?.data?.length > 0
    ? allData.data.map((traveler, index) => (
        <Traveler
          traveler={traveler}
          index={index}
          fetchData={fetchData} 
          toggleModalShow={toggleModalShow}

          setToggleEmployee={setToggleEmployee}
          toggleEmployee={toggleEmployee}

          modalShow1={modalShow1}
          setModalShow1={setModalShow1}
          
        />
      ))
    : [];

  // call api for checked
  // const goTo = async (page) => {
  //   setfilter({ ...filter,  page:page }); //page.length < 3 ? Number(page):page
  //   console.log("filterfilterfilterfilterfilter",filter)
  // };
  return (
    <>
       <table className="table-responsive-lg table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
            <th>  {translate.orderNumber} </th>
            <th scope="col">{translate.ExaminationType}</th>
            <th scope="col">{translate.traveler}</th>
            <th scope="col">{translate.examinationMethod}</th>
           
            {/* <th scope="col">{translate.number}</th> */}
            
            <th scope="col">{translate.DateRequest}</th>
            <th scope="col">{translate.timeRequest}</th>
            <th scope="col">{translate.tools}</th>
          </tr>
        </thead>
        <tbody>
        {AllTravelers.length > 0 ? (
												AllTravelers
											) : (
												<tr>
													<td colSpan="10" className="text-center my-3">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{translate.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
        </tbody>
      </table>
      <Pagination info={meta} goTo={goTo} items={10} />  

      {/* Modal */}
      <Modal className="model-order" isOpen={modalShow || modalShow1==true} toggle={()=>{toggleModalShow(null)}} >
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">{translate.runOrder}</p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={()=>{
              toggleModalShow(null)
            }}
          ></i>
        </div>
        <ModalBody className="lan  mx-5 my-4 p-0  ">
          <div
            className="mt-4"
            style={{
              textAlign: currentLanguage === "ar" ? "right" : "left",
            }}
          >
            <SelectField
              hasLabel={true}
              onFocus={false}
              //value={translate.employeeName}
              label={translate.employeeName}
              options={optionsEmployee}
              value={optionsEmployee?.find(obj => obj.value === selectedValue)}

              onChange={handleChange} // assign onChange function

            />
{console.log("optionsEmployee",selectedValue)}
          </div>
        </ModalBody>
        <ModalFooter className="pb-4 border-top-0  mx-5 p-0">
          <button
            className="rounded btn-success w-100 lan text-bold h5 py-2 m-0 "
            onClick={()=>{
              createOperationOrderClick(SeletedId,selectedValue)
            }}
          >
            {translate.printRunOrder}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
