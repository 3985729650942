import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Pagination from "../../../components/shared/Pagination";
import { readNotification } from "../../../services/admin";
import Locale from "../../../translations";

export default function NotificationContent({ notifyData, index,meta ,setMeta }) {
  let location = useHistory();
  const { translate } = Locale;

  /********************************************* */
  const [data, setData] = useState();

  const id = notifyData.id;

  const readNotify = async (id1) => {
    const res = await readNotification(id1);
    setData(res);
   
  };

  /******************************************** */
  
  
  return (
    <div className=" w-75 mx-auto mb-1 mt-1 ">
      <div
        className={`d-flex align-items-start  px-3 py-2 ${
          notifyData?.read_at != null ? "read" : "not-read"
        }`} //
        style={{ border: "1px solid #e4e4e4ba" }}
      >
        <div className="notify-content w-100 py-2">
          <h3 className="notify-header  text-primary">{notifyData?.title}</h3>
          <div className="notify-body d-flex justify-content-between">
            <p>{notifyData?.body}</p>
            <Link
              to={notifyData?.url}
              className="btn-link text-center text-grey"
              onClick={() => {
                readNotify(id);
                setTimeout(() => {
                  window.location.reload();
                }, 750);
                
              }}
            >
              <i
                className={`${
                  notifyData?.read_at != null ? "fa fa-eye" : "fas fa-eye-slash"
                } `}
                style={{ fontSize: "18px" }}
              ></i>
            </Link>
          </div>
        </div>
      </div>
         
    </div>
    
  );
}
