import React from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { acceptReceipt } from "../../../../services/admin";
import Locale from "../../../../translations";
import Invoice from "./Invoice";

export default function ListInvoices({ Invoices, setcChanahing }) {
  const { translate } = Locale;
  const { id } = useParams();

  const changeStatus = async (invoiceId) => {
    const res = acceptReceipt(id, invoiceId);
    setcChanahing((prev) => !prev);
      /*  store.addNotification({
         title: "info!",
         message: res.data.message,
         type: "success",
         insert: "top",
         container: "top-right",
         animationIn: ["animated", "fadeIn"],
         animationOut: ["animated", "fadeOut"],
         dismiss: {
           duration: 3000,
           onScreen: true,
           pauseOnHover: true,
         },
       }); */
  };

  const AllInvoices =
    Invoices &&
    Invoices?.length > 0 &&
    Invoices.map((invoice, index) => {
      return (
        <Invoice invoice={invoice} key={index} changeStatus={changeStatus} />
      );
    });

  return (
    <div className="">
      <table className="table table-responsive-sm">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col">{translate.serialNumber}</th>
            <th scope="col">{translate.totalAmount}</th>
            <th scope="col">{translate.pilgrimNumbers}</th>
            <th scope="col">{translate.dateReceipt}</th>
            <th scope="col">{translate.status}</th>

            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {AllInvoices && AllInvoices?.length > 0 ? (
            AllInvoices
          ) : (
            <tr>
              <td colSpan="11">
                <div className=" text-center">
                  <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                  <h4 className=" text-muted">{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
