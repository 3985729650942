import React from "react";
import { useParams } from "react-router-dom";
import Locale from "../../../translations";
import moment from "moment";

export default function ViewTraveller({ data, index, toggle }) {
  const { translate } = Locale;
  const { id, status } = useParams();
  const traveller = status ? data?.traveller : data;

  return (
    <div className="py-2 p-3  border instructions rounded mt-3">
      <div className="d-flex justify-content-between">
        <h5 className="font-weight-bold text_blue-dark mt-2">
          {translate.CustomerData} {index + 1}
        </h5>

        {status && data?.attachment === null ? (
          <button
            type="button"
            class="btn btn-success"
            onClick={() => {
              toggle(data.id);
            }}
          >
            {translate.addResult}
          </button>
        ) : status && data?.attachment != null ? (
          <a type="button" class="btn btn-primary" href={data.attachment}>
            {translate.reportResult}
          </a>
        ) : null}
      </div>

      <div className="row">
        {status ? (
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.name}
          </p>
        ) : (
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.nameArabic}
          </p>
        )}
        {status ? (
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {" "}
            {traveller?.traveller_name}
          </p>
        ) : (
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.first_name?.ar} {traveller?.father_name?.ar}{" "}
            {traveller?.grandpa_name?.ar} {traveller?.last_name?.ar}
          </p>
        )}
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.nationalId}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">{traveller?.nin}</p>
        {status ? null : (
          <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
            {translate.EnglishName}
          </p>
        )}
        {status ? null : (
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.first_name?.en} {traveller?.father_name?.en}{" "}
            {traveller?.grandpa_name?.en} {traveller?.last_name?.en}
          </p>
        )}
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.passportNumber}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {traveller?.passport_number}
        </p>
        {/*         /**************************** */}
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.visa_number}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {traveller?.visa_number}
        </p>
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.border_no}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {traveller?.border_no}
        </p>
        {/*    /**************************** */}
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.Nationality}
        </p>
        {status ? (
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.nationality.ar}
          </p>
        ) : (
          <p className="col-6  col-md-4  font-mobile  h5    ">
            {traveller?.country}
          </p>
        )}
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.birthDay}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {traveller?.date_of_birth}
        </p>
        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
          {translate.gender}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5    ">
          {traveller?.gender}
        </p>

        <p className="col-6  col-md-2  font-mobile h5   font-weight-bold text-primary">
          {translate.newReturnDate}{" "}
        </p>
        <p className="col-6  col-md-4  font-mobile  h5   text-danger ">
          {
            
            traveller?.return_date ? 
            moment(traveller?.return_date).format("DD/MM/YYYY") : translate.noResult}
          {}
        </p>
      </div>
    </div>
  );
}
