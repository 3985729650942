 import React, { lazy } from 'react'
import { Redirect, Route, useLocation } from "react-router-dom";
import { useCompanyDispatch, useCompanyState } from '../context/global';
import HeaderAdmin from './layout/HeaderAdmin';


 

export default function AdminRouter({ component: Component, ...props }) {
	const { havePermission } = useCompanyState();
	const dispatch = useCompanyDispatch();

	const location = useLocation();
	let CompanyState  = useCompanyState();

	
	if (CompanyState.isAuth) {
		if (havePermission ) {   //|| location.pathname !== "/team-management/groups"
			console.log("havePermission",havePermission);
			return (
			  <Route
				{...props}
				render={(matchProps) => (
				  <>
					<HeaderAdmin />
					<Component {...matchProps} />
				  </>
				)}
			  />
			);
		  } else {
			dispatch({ type: "RedirectToHome", payload: true });
			return <Redirect to="/admin/dashboard/DashboardCompanies" />;
		  }

		// return (
		// 	<Route
		// 		{...props}
		// 		render={(matchProps) => (
        //   <>
        //     <HeaderAdmin />
		// 				<Component {...matchProps} />
        //   </>
		// 		)}
		// 	/>
		// );
	}else{
			return <Redirect to="/admin/login" />;
		} 
	
	
}
 