import React from "react";
import DatePicker from "../../../components/shared/DatePicker";
import TextField from "../../../components/shared/textField";
import Locale from "../../../translations";

export default function Filter({ filter, setFilter, sumbit }) {
  const { translate } = Locale;

  return (
    <div className="bg-white row m-0 py-3">
      <div className="col">
        <TextField
          hasLabel={false}
          placeholder={translate.passportNumber}
          value={filter.passport_number}
          onChange={(e) => {
            setFilter({
              ...filter,
              passport_number: e.target.value,
            });
          }}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>

      <div className="col">
        <TextField
          hasLabel={false}
          placeholder={translate.visa_number}
          value={filter.visa_number}
          onChange={(e) => {
            setFilter({
              ...filter,
              visa_number: e.target.value,
            });
          }}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>

      <div className="col">
        <DatePicker
          hasLabel={false}
          placeholder={translate.newReturnDate}
          date={filter.return_date}
          onChangeDate={(e) => {
            setFilter({ ...filter, return_date: e });
          }}
        />
      </div>

      <div className="d-flex  align-items-center mt-2 mt-lg-0 col">
        <button
          className="btn btn-primary font-weight-bold mx-2  px-5 "
          onClick={() => {
            sumbit();
          }}
        >
          {translate.search}
        </button>

        <button
          className="btn text-muted font-weight-bold  "
          onClick={() => {
            setFilter({
              visa_number: "",
              passport_number: "",
              return_date: "",
            });
                    sumbit();
          }}
  
        >
          {" "}
          <i className="fas fa-retweet"></i>
          {translate.reset}
        </button>
      </div>
    </div>
  );
}
