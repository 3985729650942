import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { allSettlement, creatReceipt } from "../../../../services/admin";
import Locale from "../../../../translations";
import Filter from "./Filter";
import ListPayments from "./ListPayments";
import PilgarmsCount from "./PilgarmsCount";

export default function SaudiPayment() {
  const { id } = useParams();
  let history = useHistory();

  const { translate } = Locale;
  const [filter, setfilter] = useState({
    return_from_date: "",
    return_to_date: "",
    departure_from_date: "",
    departure_to_date: "",
  });
 

  const [payments, setPayments] = useState([]);
  const [counts, setCounts] = useState({
    pilgrams: 0,
    totalPrice: 0,
  });

  const sumbit = async () => {
    const res = await allSettlement(
      {
        return_to_date: filter?.return_to_date
          ? moment(filter.return_to_date).format("YYYY-MM-DD")
          : "",
        return_from_date: filter?.return_from_date
          ? moment(filter.return_from_date).format("YYYY-MM-DD")
          : "",
        departure_to_date: filter?.departure_to_date
          ? moment(filter.departure_to_date).format("YYYY-MM-DD")
          : "",
        departure_from_date: filter?.departure_from_date
          ? moment(filter.departure_from_date).format("YYYY-MM-DD")
          : "",
      },
      id
    );

    setPayments(res?.data?.packages);
    setCounts({
      pilgrams: res?.data?.unpaidPilgrims,
      totalPrice: res?.data?.totalPrice,
    });
  };

  const sumbitReceipt = async (count) => {
    let packages_ids = [];
    
    packages_ids = payments.filter((item) => item.payment_status === null);
    packages_ids = packages_ids.map((res)=>res.id)
    const res = await creatReceipt(
      { packages_ids, exchange_factor: count },
      id
    );
    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: res?.data?.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });

      history.push(`/admin/saudi/Invoices/${id}`);
    }
  };

  return (
    <div className="container lan">
      <div className=" mt-3">
        {" "}
        <header className=" list_header border p-3 d-flex justify-content-between align-items-baseline">
          <h3 className="text-muted font-weight-bold">
            {translate.Settlement}
          </h3>
        </header>
        <Filter
          filter={filter}
          setfilter={setfilter}
          sumbit={sumbit}
          setPayments={setPayments}
        />
        {payments?.length > 0 ? (
          <PilgarmsCount counts={counts} sumbitReceipt={sumbitReceipt} />
        ) : null}
        <ListPayments payments={payments} />
      </div>
    </div>
  );
}
