import React from "react";
import Locale from "../../../translations";

export default function Activity({data}) {
	const { translate } = Locale;

	
	//console.log("key=>",data)
	const logs=data[1];
	//console.log("c",c)

	const Activities = logs.map((activity) => (
		<div className="main pt-1 d-flex" key={activity.id}>
		
				
				<div className="time font-weight-bold">{(activity.login_at).slice(11,16)}</div>
				<div className="px-2">
				{activity?.login_successful ? (
					<span className="tes">
						<i className="far fa-circle bg-white text-primary"></i>
					</span>
				):(<span className="tes">
				<i className="far fa-circle bg-white text-danger"></i>
			</span>)}

				</div>
				<div>
					{activity?.login_successful?(<h2 className="text-primary font-weight-bold signin-head" >
						{translate.signIn}
					</h2>):(<h5 className="text-danger font-weight-bold">
						{translate.signIn}
					</h5>)}
					

					<p>{activity.location.country} {activity.location.city}</p>
					<p >{(activity.user_agent).length<20?(activity.user_agent):(activity.user_agent).slice(0,40)}|{activity.ip_address}</p>
					{activity.date ? (
						<div className="date">
							<p className="mb-0">{activity.date}</p>
						</div>
					) : null}
				</div>
				


		</div>
	));

	return (
		<>
			
				<div className="date">
					<p>{data[0]}</p>
				</div>

				{Activities}
			
		</>
	);
}
