import React, { useEffect, useState } from "react";
import Locale from "../../../translations";

export default function Activity({ data }) {
  const { translate } = Locale;

  //console.log("key=>",data)
  //const logs=data[1];
  //console.log("c",c)
  var today = new Date();
 
const [time, setTime] = useState()

useEffect(() => {
    var time = today.getHours() + ":" + today.getMinutes() ;
	var dateTime = time;

	setTime( dateTime)

}, [time])

const logs=data[1];
const day=today.toLocaleDateString(undefined, {month:'short'}) + ' ' + today.toLocaleDateString(undefined, {day:'numeric'}) + ', ' + today.toLocaleDateString(undefined, {year:'numeric'})

console.log("day",day)
const subLogs=data[1].slice(0,5)
{console.log("logs",subLogs)}
	const Activities = subLogs.map((activity) => (   /**subLogs ====logs */
		
		<div className="main pt-1 d-flex" key={activity.id}>
		
				
				<div className="time font-weight-bold">{+((activity.login_at).slice(11,13))+2 }{(activity.login_at).slice(13,16)}</div>{/**{(activity.login_at).slice(11,16)} (time)   {+((activity.login_at).slice(11,13))+2 }{(activity.login_at).slice(13,16)} */}   
				<div className="px-2">
				{activity?.login_successful  ? (
					<span className="tes">
						<i className="far fa-circle bg-white text-primary"></i>
					</span>
				):  ( <span className="tes">
				<i className="far fa-circle bg-white text-danger"></i>
			</span>)}

				</div>
				

				<div>
					{activity?.login_successful ?(<h2 className=" text-primary font-weight-bold " >
						{translate.signIn}
					</h2>): (<h5 className=" text-danger font-weight-bold">
						{translate.signIn}
					</h5>)}
					

					<p>{activity.location.country} {activity.location.city}</p>
					{/* <p >{(activity.user_agent).length<20?(activity.user_agent):(activity.user_agent).slice(0,40)}|{activity.ip_address}</p> */}
					<p >{activity.ip_address}</p>
					<p >{(activity.user_agent).length<20?(activity.user_agent):(activity.user_agent).slice(0,40)}</p>
					{activity.date ? (
						<div className="date">
							<p className="mb-0">{activity.date}</p>
						</div>
					) : null}
				</div>
				


		</div>
	));

  return <>
  {console.log("day=",day,"======>data[0]",data[0] ,"---",day==data[0])}
  {console.log("data[0]*",data[0])}

   { day==data[0]?( 
  <div className="date">
  <p>{data[0]}</p>
 
</div>
   ):<p></p>}  

  {day ==data[0]?(  
<>{Activities}</>

    ):null}   


</>;
}
