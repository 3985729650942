import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL;
const http = process.env.REACT_APP_HTTP;
const [protocol, domain] = baseURL.split("//");
const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
//debugger
//const URL = `http://${subdomain}.${domain}/api`;

export const CheckURL = http+`://${subdomain}.${domain}/api/tenant/companies`;
// debuggerr

//*****************************Admin Login************************
/* Team Managment  */
export const ListUser = async () => {
    return await axios
      .get(`${CheckURL}/users`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  export const ListUserFilter = async (filter) => {
    return await axios
      .get(`${CheckURL}/users`,{
        params: cleanEmpty(filter)
        })
      .then((res) => res)
      .catch((err) => err.response);
  };

  
  export const RetrievedUser = async (id) => {
    return await axios
      .get(`${CheckURL}/users/${id}`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const ListGroups = async () => {
    return await axios
      .get(`${CheckURL}/groups`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  export const ListGroupsFilter = async (filter) => {
    return await axios
      .get(`${CheckURL}/groups`,{
        params: cleanEmpty(filter)
        })
      .then((res) => res)
      .catch((err) => err.response);
  };
  export const changeStatus = async (id, status) => {
    return await axios
      .patch(`${CheckURL}/users/${id}/${status}`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  export const changePasswordAPI = async (id, data) => {
    return await axios
      .put(`${CheckURL}/users/auth/change-password`, data)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const editUserAPI = async (id, data) => {
    return await axios
      .put(`${CheckURL}/users/${id}`, data)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const listGroupsDp = async () => {
    return await axios
      .get(`${CheckURL}/groups`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const listPermissionsDB = async () => {
    return await axios
      .get(`${CheckURL}/groups/permissions`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const addUserAPI = async (data) => {
    return await axios
      .post(`${CheckURL}/users`, data)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const addGroupAPI = async (data) => {
    return await axios
      .post(`${CheckURL}/groups`, data)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const RetrievedGroup = async (id) => {
    return await axios
      .get(`${CheckURL}/groups/${id}`)
      .then((res) => res)
      .catch((err) => err.response);
  };
  
  export const editGroupAPI = async (id, data) => {
    return await axios
      .put(`${CheckURL}/groups/${id}`, data)
      .then((res) => res)
      .catch((err) => err.response);
  };
export const deleteUser = async (id) => {
  return await axios
    .delete(`${CheckURL}/users/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
  };