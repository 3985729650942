import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../../translations";

export default function User(props) {
	const { user } = props;
	//const { Labs } = Locale;
	const { translate } = Locale;


	return (
		<>



<tr>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.phone_number}</td>
          
          <td className={user.status=== 'active'? "text-green":"text-warning"} > {user.status}</td>
          <td>
            <div className="actions">
              <span className="text-primary">

              <NavLink
                exact={true}
                to={`/admin/ComanyMedical/edit-user/${user.id}`}
                activeClassName="is-active"
                className="p-2 text-primary mr-3 btn-link "
              >
              <i className="fas fa-edit"></i>   { translate.edit}   

              </NavLink>
                
                
					{user.status === "active" ? (
						
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "deactivate")}
								className="btn btn-link text-danger"
							>
								<i className="fas fa-stop"></i>	{translate.deactive}
							</button>
						
					) : (
						
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "activate")}
								className="btn btn-link text-success"
							>
								<i class="far fa-play-circle"></i> {translate.active}
							</button>
						
					)}
				</span>

				

            <button onClick={() => props.togglePassword(user.id)}>  <span className="mx-3 text-primary btn-link"><i className="fas fa-lock"></i> {translate.changePassword}</span> </button>


              <button className="btn btn-danger mx-3">{translate.remove}</button>
            </div>
          </td>
        </tr>
		
		</>
	);
}
