import Locale from "../../../translations";
import { NavLink, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import ListRequests from "./ListRequests";
import { listTravelers } from "../../../services/UmrahRequests";
import moment from "moment";
import Pagination from "../../../components/shared/Pagination";
import { exportExeclUmrahPackages, listPackagesU } from "../../../services/admin";

export default function PackagesRequests() {
  const { translate } = Locale;
  const [listRequests, setListRequests] = useState();
  const [searching, setSearching] = useState(false);
  const [meta, setMeta] = useState();
  const token = localStorage.getItem("token");

  const { status } = useParams();

  const [filter, setfilter] = useState({
    city_id: null,
    area_id: null,
    hotel_id: null,
    date: "",
    company_name: "",
  });

  useEffect(async () => {
    const res = await listPackagesU({
      city_id: filter?.city_id?.value,
      area_id: filter?.area_id?.value,
      hotel_id: filter?.hotel_id,
      date: filter?.date ? moment(filter.date).format("DD-MM-YYYY") : "",
      company_name: filter?.company_name,
      page: filter?.page,
    });

    setListRequests(res.data);
    setMeta(res.meta);
  }, [searching, filter]);

  const goTo = async (page) => {
    setfilter({ ...filter, page: page });
  };

  const submit = () => {
    setSearching(!searching);
  };

  //Excel Function
  const openExcel = async () => {
    const res = await exportExeclUmrahPackages({
      ...filter,
      city_id: filter?.city_id?.value ? filter?.city_id?.value : null,
      area_id: filter?.area_id?.value ? filter?.area_id?.value : null,
      date: filter.date ? moment(filter.date).format("YYYY-MM-DD") : null,
      Authorization: token,
    });
    if (res) {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="container p-0 my-4 orders-list lan">
      <header className="list_header border py-2 px-2 d-flex justify-content-between align-items-baseline">
        <h3 className="text-muted font-weight-bold">
          {translate.ComanyMedical}
        </h3>
        <button className="btn btn-success" onClick={openExcel}>
          {translate.export_excel}
          <i class="fas fa-file-excel  mx-2 "></i>
        </button>
      </header>

      <Filter submit={submit} filter={filter} setfilter={setfilter} />

      {listRequests && (
        <ListRequests listRequests={listRequests} setSearching={setSearching} />
      )}

      <Pagination info={meta} goTo={goTo} items={10} />
    </div>
  );
}
