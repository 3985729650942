import React, { useEffect, useState } from "react";
import Locale from "../../../../translations";
import { useHistory, useParams } from "react-router";
import { listTravellersById } from "../../../../services/yarvel";
import { viewIcomingOrder } from "../../../../services/admin";
import { useCompanyState } from "../../../../context/global";
export default function ViewProgressOrders() {
  const { translate } = Locale;
  let history = useHistory();
  const { currentLanguage } = useCompanyState();

  let { id } = useParams();
  let { index } = useParams();

  console.log("id", id, "index", index);
  const [data, setData] = useState("");
  useEffect(() => {
    async function fetchData() {
      const res = await viewIcomingOrder(id, index);
      setData(res.data?.data);
    }
    fetchData();
  }, []);
  console.log("data=>", data);

  return (
    <div className="container p-0">
      {/* <header className=" list_header border py-3 px-2 d-flex justify-content-between align-items-baseline mt-4">
        <h3 className="text-muted font-weight-bold"> {translate.pcr}</h3>
      </header> */}

      <div className="bg-white px-5 py-4">
        {/* *************************************************************************/}
        <div className="instructions border my-4">

        <h5 className="font-weight-bold p-2 text_blue-dark ">
          {" "}
          {translate.orderData}{" "}
        </h5>

        <div className=" p-2 rounded-lg row">
          <p className="col-2 h5   font-weight-bold">{translate.check}</p>
          <p className="col-4 h5 ">{data?.test_type}</p>

          <p className="col-2 h5  font-weight-bold">
            {translate.orderNumber}
          </p>
          <p className="col-4 h5 ">{data?.reservation_no}</p>

          <p className="col-2  h5  font-weight-bold">
            {translate.serviceType}
          </p>
          <p className="col-4 h5 ">{data?.reservation_type}</p>

          <p className="col-2  h5  font-weight-bold">
            {translate.timeRequest}
          </p>
          <p className="col-4 h5 ">
            {" "}
            {data?.request_date?.slice(11, 16)} |{" "}
            {data?.request_date?.slice(0, 10)}{" "}
          </p>

          <p className="col-2  h5  font-weight-bold">
            {translate.serviceDegree}
          </p>
          <p className="col-4 h5 ">{data?.grade}</p>

          <p className="col-2  h5  font-weight-bold">
            {translate.DataOperation}
          </p>
          <p className="col-4 h5 ">
          {data?.operationOrder_created_at?.slice(11, 16)} |{" "}
            {data?.operationOrder_created_at?.slice(0, 10)}{" "}
          </p>

          {/*  */}

          <p className="col-2  h5  font-weight-bold">
            {translate.resultWithin}
          </p>
          <p className="col-4 h5 ">{data?.time} {translate.hour}</p>

          <p className="col-2  h5  font-weight-bold">
            {translate.OperationOfficer}
          </p>
          <p className="col-4 h5 ">
            {data?.operationOrder_employee}
          </p>

          {/*  */}
          <p className="col-2  h5  font-weight-bold">
            {translate.companyName}
          </p>
          <p className="col-4 h5 ">{data?.company_name}</p>
        </div>
</div>


 {/* *************************************************************************/}
      
 {((currentLanguage=="ar"&&data?.test_type !== "تحليل مخدرات") || (currentLanguage=="en"&&data?.test_type !== "Drugs Examination"))?(
       <>
       <div className="instructions border">
        <h5 className="font-weight-bold px-2 py-1 text_blue-dark mt-2">
          {" "}
          {translate.TripData} 
        </h5>

        <div className="  p-2 row">
          <p className="col-2 h5   font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 ">
            {/* {data?.trip?.destination?.names[currentLanguage]} */}
            {data?.trip?.destination?.names[currentLanguage]}

          </p>
          

          <p className="col-2  h5  font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 ">
            {(data?.trip?.departure_date).slice(0,10)} | {(data?.trip?.departure_date).slice(11,16)}
          </p>



          <p className="col-2  h5  font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 ">
            {" "}
            {data?.trip?.transporter?.names[currentLanguage] }  {/**[currentLanguage] */}
          </p>
          <p className="col-2 h5  font-weight-bold">
            {translate.arrivalDate}
          </p>
          <p className="col-4 h5 ">{(data?.trip?.arrival_date).slice(0,10)} | {(data?.trip?.arrival_date).slice(11,16)}</p>
         
        </div>
        </div>
        </>):""}
        {/* *************************************************************************/}
   



        {/* *************************************************************************/}
        {/* <h5 className="font-weight-bold  text_blue-dark mt-2">
          {" "}
          {translate.TripData}
        </h5>

        <div className="instructions  p-2 rounded-lg row">
          <p className="col-2 h5  py-1 font-weight-bold">PNR</p>
          <p className="col-4 h5 py-1">{data?.trip?.pnr}</p>

          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.DepartureAirport}
          </p>
          <p className="col-4 h5 py-1">{data?.trip?.destination?.names?.ar}</p>

          <p className="col-2 h5  py-1 font-weight-bold">
            {translate.destnation}
          </p>
          <p className="col-4 h5 py-1">{data?.trip?.destination?.ar}</p>
          <p className="col-2 h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">
            {" "}
            {data?.trip?.departure_date?.slice(11, 16)} |{" "}
            {data?.trip?.departure_date?.slice(0, 10)}
          </p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.Transporter}
          </p>
          <p className="col-4 h5 py-1"> {data?.trip?.transporter?.names?.ar}</p>
          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.ArrivalAirport}
          </p>
          <p className="col-4 h5 py-1">{data?.trip?.arrival_port?.names?.ar}</p>

          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.TripNumber}
          </p>
          <p className="col-4 h5 py-1">{data?.trip?.trip_num}</p>

          <p className="col-2  h5 py-1 font-weight-bold">
            {translate.departureDate}
          </p>
          <p className="col-4 h5 py-1">
            {data?.trip?.departure_date?.slice(11, 16)} |{" "}
            {data?.trip?.departure_date?.slice(0, 10)}
          </p>
        </div> */}

        {/* *************************************************************************/}
       {/* *************************************************************************/}
       <div className="instructions border my-4">

       <h5 className="font-weight-bold p-2 text_blue-dark ">
          {" "}
          {translate.CustomerData}
        </h5>

        <div className="p-2 rounded-lg row">
          <p className="col-2 h5   font-weight-bold">
            {translate.nameArabic}
          </p>
          <p className="col-4 h5 ">{data?.traveller_name_ar}</p>

          <p className="col-2 h5  font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 "> {data?.phone} </p>

          <p className="col-2  h5  font-weight-bold">
            {translate.EnglishName}
          </p>
          <p className="col-4 h5 ">{data?.traveller_name_en}</p>

          <p className="col-2  h5  font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-4 h5 ">{data?.passport_number}</p>
          

          <p className="col-2  h5  font-weight-bold">
            {translate.ExpiryDate}
          </p>
          <p className="col-4 h5 ">{data?.expiration_date} </p>

          {/* <p className="col-2  h5  font-weight-bold">{translate.country}</p>
          <p className="col-4 h5 py-3">{data?.country_id}</p>

         

          <p className="col-2  h5 py-3 font-weight-bold">{translate.city}</p>
          <p className="col-4 h5 py-3">{data?.city_id}</p>

          <p className="col-2  h5 py-3 font-weight-bold">
            {translate.DateRequest}
          </p>
          <p className="col-4 h5 py-3">{data?.request_date?.slice(0,7)} </p>

          <p className="col-2  h5 py-3 font-weight-bold">{translate.area}</p>
          <p className="col-4 h5 py-3">{data?.area_id}</p>

          <p className="col-2  h5 py-3 font-weight-bold">
            {translate.nationalId}
          </p>
          <p className="col-4 h5 py-3">{data?.national_id}</p>

          <p className="col-2  h5 py-3 font-weight-bold">{translate.address}</p>
          <p className="col-4 h5 py-3">
            {data?.street}-{data?.city_id}
          </p> */}

          <p className="col-2  h5  font-weight-bold">
            {translate.Nationality}
          </p>
          <p className="col-4 h5 ">{data?.nationality}</p>
        </div>
</div>
        {/* *************************************************************************/}
        <div className="instructions border my-4">

        <h5 className="font-weight-bold p-2 text_blue-dark ">
          {" "}
          {translate.ExaminationData}
        </h5>

        <div className="p-2 rounded-lg row">
          <p className="col-2 h5   font-weight-bold">
          {translate.check} {translate.date} 
          </p>
          <p className="col-4 h5 ">
            {data?.check_date?.slice(0, 10)}
          </p>
          <p className="col-2 h5   font-weight-bold">
            {translate.ResultTime}
          </p>
          <p className="col-4 h5 ">
            {data?.check_date?.slice(11, 16)} 
          </p>

          <p className="col-2 h5   font-weight-bold">
            {translate.governorate}
          </p>
          <p className="col-4 h5 ">{data?.city_id}</p>
          <p className="col-2 h5   font-weight-bold">{translate.city}</p>
          <p className="col-4 h5 ">{data?.city_id}</p>

          <p className="col-2 h5   font-weight-bold">{translate.StName}</p>
          <p className="col-4 h5 ">{data?.street}</p>
          <p className="col-2 h5   font-weight-bold">
            {translate.BuildingNumber}
          </p>
          <p className="col-4 h5 ">{data?.building}</p>

          <p className="col-2 h5   font-weight-bold">{translate.Floor}</p>
          <p className="col-4 h5 ">{data?.floor}</p>
          <p className="col-2 h5  font-weight-bold">{translate.ApartmentNumber} </p>
          <p className="col-4 h5 ">{data?.flat}</p>
          <p className="col-2  h5  font-weight-bold">
            {translate.phoneNumber}
          </p>
          <p className="col-4 h5 ">{data?.phone}</p>
          <p className="col-2  h5  font-weight-bold">{translate.email}</p>
          <p className="col-4 h5 ">{data?.email}</p>

          <p className="col-2  h5  font-weight-bold">{translate.Cost}</p>
          <p className="col-4 h5 ">
            {data?.amount}
            {translate.pound}
          </p>
          <p className="col-2  h5  font-weight-bold">
            {translate.paymentMethods}
          </p>
          <p className="col-4 h5 ">{data?.comission_type}</p>
        </div>
        </div>

        {/* *************************************************************************/}
      </div>

      <button
        className=" btn btn-secondary px-5 mt-2"
        onClick={() => {
          history.push(`/admin/ordersList/progress/${id}`);
        }}
      >
        {" "}
        {translate.back}
      </button>
    </div>
  );
}
