import moment from "moment";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Request({ pilgram }) {
  const trip = { ...pilgram?.package };

  return (
    <tr>
      <td>{pilgram?.traveller_name}</td>
      <td>{pilgram?.passport_number}</td>
      <td>{pilgram?.border_no}</td>
      <td>{pilgram?.visa_number}</td>
      <td>{trip?.package_name}</td>
      <td>{moment(pilgram?.return_date).format("DD/MM/YYYY")}</td>
      <td>{trip?.hotel?.name}</td>
      <td>{moment(trip?.return_date).format("DD/MM/YYYY")}</td>

      <td>
        <Link
          to={`/Umrah/Medical/Requests/view/${trip.id}`}
          className="text-primary"
        >
          {trip?.id}
        </Link>
      </td>
    </tr>
  );
}
